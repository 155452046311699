import React from 'react'
import cn from 'classnames'

/**
 * @param {React.SVGProps<SVGSVGElement>} props
 */
export default function IconMenuHBars(props) {
  return (
    <svg
      {...props}
      className={cn('as-media-icon', props.className)}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="currentColor"
    >
      <rect y="11" width="24" height="2" rx="1" />
      <rect y="4" width="24" height="2" rx="1" />
      <rect y="18" width="24" height="2" rx="1" />
    </svg>
  )
}
