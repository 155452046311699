import { createContext, useCallback, useContext, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { merge } from 'lodash'
import { enqueueSnackbar } from 'notistack'
import { getId } from '../../../util/utilFunctions'
import { getUser as qGetUser } from '../lib/queries'
import TopLoader from '../components/overlays/top-loader'

const AuthCtx = createContext()

export const AuthProvider = ({ children }) => {
  const isAuthLoading = useSelector(state => state.loginReducer.isUserLoading)
  const currentUser = useSelector(state => state.loginReducer.currentUser)

  const [isUserLoading, setIsUserLoading] = useState(false)
  const [user, setUser] = useState(null)

  useEffect(() => {
    setIsUserLoading(isAuthLoading)
  }, [isAuthLoading])

  useEffect(() => {
    const getUser = async () => {
      setIsUserLoading(true)

      try {
        const res = await qGetUser(getId(currentUser))
        setUser(user => merge({}, user, res))
      } catch (err) {
        enqueueSnackbar(err)
      }

      setIsUserLoading(false)
    }

    getId(currentUser) ? getUser() : setUser(null)
  }, [currentUser])

  return (
    <AuthCtx.Provider value={{ isLoggedIn: !!getId(user), user }}>
      {children}
      {isUserLoading && <TopLoader />}
    </AuthCtx.Provider>
  )
}

export const useAuth = () => useContext(AuthCtx)
