import { SMDbProvider } from './smdb-ctx'
import { BodyScrollProvider } from './body-scroll'
import { AuthProvider } from './auth-ctx'
import { VisiblePostProvider } from './visible-post-ctx'
import { MutedProvider } from './muted-ctx'
import { OnlineProvider } from './online-ctx'

export default function CtxProvider({ children }) {
  return (
    <SMDbProvider>
      <BodyScrollProvider>
        <AuthProvider>
          <VisiblePostProvider>
            <MutedProvider>
              <OnlineProvider>{children}</OnlineProvider>
            </MutedProvider>
          </VisiblePostProvider>
        </AuthProvider>
      </BodyScrollProvider>
    </SMDbProvider>
  )
}
