import { useCallback, useEffect, useRef, useState } from 'react'
import { enqueueSnackbar } from 'notistack'
import cn from 'classnames'
import IconClose from '../../assets/icons/close'
import { commentPost as qCommentPost } from '../../lib/queries'
import { useSMDb } from '../../contexts/smdb-ctx'
import EmojiBtn from '../common/emoji-btn'
import styles from './add-comment.module.scss'

export default function AddComment({ postId, parentComment, setParentComment }) {
  const { merge: smdbMerge } = useSMDb()

  const [content, setContent] = useState('')
  const [isCommentLoading, setIsCommentLoading] = useState(false)

  const textareaRef = useRef(null)

  const adjustHeight = useCallback(() => {
    const textarea = textareaRef.current

    if (textarea) {
      textarea.style.height = 'auto'
      textarea.style.height = `${textarea.scrollHeight > 150 ? 150 : textarea.scrollHeight}px`
    }
  }, [])

  const addEmoji = useCallback(
    emoji => {
      const textarea = textareaRef.current
      const cursorPosition = textarea.selectionStart

      const newContent = content.slice(0, cursorPosition) + emoji + content.slice(cursorPosition)
      setContent(newContent)

      textarea.focus()

      setTimeout(() => {
        textarea.selectionStart = textarea.selectionEnd = cursorPosition + emoji.length
      }, 0)
    },
    [content]
  )

  const commentPost = useCallback(async () => {
    if (!content) return

    setContent('')
    setParentComment(null)
    setIsCommentLoading(true)

    try {
      const res = await qCommentPost(postId, content, parentComment?._id)
      smdbMerge(postId, res)
    } catch (er) {
      enqueueSnackbar(er)
    }

    setIsCommentLoading(false)
  }, [postId, content, parentComment, smdbMerge])

  useEffect(() => {
    adjustHeight()
  }, [])

  useEffect(() => {
    if (!content) {
      textareaRef.current.style.height = 'auto'
    }
  }, [content])

  return (
    <div className={cn(styles.postAddCommentWrp, parentComment && styles.withParentComment)}>
      {parentComment && (
        <div className={styles.parentCommentWrp}>
          <div className={styles.parentCommentInfoWrp}>
            Replying to <span>@{parentComment.user.firstName}</span>
          </div>
          <button className={styles.parentCommentRemBtn} onClick={() => setParentComment(null)}>
            <IconClose />
          </button>
        </div>
      )}

      <div className={styles.postAddCommentInpWrp}>
        <textarea
          ref={textareaRef}
          rows={1}
          placeholder="Comment here..."
          className={styles.postAddCommentInp}
          value={content}
          onChange={e => {
            adjustHeight()
            setContent(e.target.value)
          }}
          disabled={isCommentLoading}
        />
      </div>
      <div className={styles.postAddCommentActBtnsWrp}>
        <EmojiBtn className={styles.postAddCommentEmojiBtn} addEmoji={addEmoji} />

        <button className={styles.postAddCommentSubmitBtn} onClick={commentPost} disabled={isCommentLoading}>
          Comment
        </button>
      </div>
    </div>
  )
}
