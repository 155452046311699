import { useEffect } from 'react'
import ReactDOM from 'react-dom'
import { useBodyScroll } from '../../contexts/body-scroll'
import SpinnerWithText from '../ui/spinner-with-text'
import compStyles from '../../styles/components.module.scss'
import styles from './top-loader.module.scss'

export default function TopLoader() {
  const { enable: enableBodyScroll, disable: disableBodyScroll } = useBodyScroll()

  useEffect(() => {
    disableBodyScroll()
    return enableBodyScroll
  }, [enableBodyScroll, disableBodyScroll])

  return ReactDOM.createPortal(
    <div className={compStyles.overlay}>
      <div className={compStyles.container}>
        <SpinnerWithText className={styles.topLoader}>Getting you to the AdamSea Community...</SpinnerWithText>
      </div>
    </div>,
    document.querySelector('.__as_sm')
  )
}
