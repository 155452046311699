import React from 'react'
import cn from 'classnames'

/**
 * @param {React.SVGProps<SVGSVGElement>} props
 */
export default function IconBan(props) {
  return (
    <svg
      {...props}
      className={cn('as-media-icon', props.className)}
      viewBox="0 0 60 60"
      width="24"
      height="24"
      fill="currentColor"
    >
      <path d="m30 0c-16.542 0-30 13.458-30 30s13.458 30 30 30 30-13.458 30-30-13.458-30-30-30zm0 58c-15.439 0-28-12.561-28-28s12.561-28 28-28 28 12.561 28 28-12.561 28-28 28z" />
      <path d="m30 4c-6.166 0-12.15 2.209-16.85 6.219-.212.181-.339.442-.35.722-.011.278.095.549.292.746l35.22 35.221c.188.188.442.293.707.293h.039c.279-.011.54-.138.722-.35 4.011-4.699 6.22-10.683 6.22-16.85 0-14.337-11.663-26-26-26zm6 25c0 .553.447 1 1 1s1-.447 1-1v-8.5c0-.827.673-1.5 1.5-1.5.397 0 .784.159 1.063.438.277.277.437.664.437 1.062v16.266l-8-8v-12.266c0-.827.673-1.5 1.5-1.5.397 0 .784.159 1.063.438.277.277.437.664.437 1.062zm-5-2.234-3-3v-10.266c0-.827.673-1.5 1.5-1.5.397 0 .784.159 1.063.438.277.277.437.664.437 1.062zm-5-5-3-3v-2.266c0-.827.673-1.5 1.5-1.5.397 0 .784.159 1.063.438.277.277.437.664.437 1.062zm22.944 22.945-5.944-5.945v-18.266c0-.925-.373-1.828-1.022-2.476-1.025-1.029-2.737-1.277-3.978-.687v-.838c0-.925-.373-1.828-1.022-2.476-1.027-1.03-2.741-1.275-3.981-.685-.04-.868-.407-1.705-1.019-2.315-.649-.651-1.553-1.024-2.478-1.024-1.876 0-3.412 1.483-3.496 3.34-.467-.221-.981-.34-1.504-.34-1.93 0-3.5 1.57-3.5 3.5v.266l-5.711-5.711c4.199-3.267 9.382-5.055 14.711-5.055 13.233 0 24 10.767 24 24 0 5.33-1.788 10.512-5.056 14.711z" />
      <path d="m11.687 13.093c-.197-.196-.48-.32-.746-.292-.279.011-.54.138-.722.35-4.011 4.699-6.22 10.683-6.22 16.85 0 14.337 11.663 26 26 26 6.167 0 12.15-2.209 16.85-6.22.212-.182.339-.442.35-.722.011-.278-.095-.549-.292-.746zm10.582 21.98c.432-.12.73-.515.73-.963v-6.877l17.029 17.029c-1.289 2.284-3.716 3.738-6.378 3.738h-5.08c-3.057 0-5.811-1.693-7.188-4.422l-7.198-14.214c-.371-.705-.176-1.565.462-2.043.728-.539 1.835-.319 2.298.377l4.2 6.931c.232.382.689.569 1.125.444zm7.73 18.927c-13.233 0-24-10.767-24-24 0-5.33 1.788-10.512 5.056-14.711l9.944 9.944v5.298l-2.366-3.904c-.676-1.019-1.806-1.627-3.023-1.627-.781 0-1.527.248-2.159.719-1.433 1.071-1.868 2.996-1.044 4.563l7.19 14.199c1.719 3.404 5.157 5.519 8.973 5.519h5.08c3.2 0 6.145-1.64 7.845-4.271l3.216 3.216c-4.199 3.268-9.381 5.056-14.711 5.056z" />
    </svg>
  )
}
