import { useState } from 'react'
import { Picker } from 'emoji-mart'
import OutsideClickHandler from 'react-outside-click-handler'
import cn from 'classnames'
import IconEmoji from '../../assets/icons/emoji'
import styles from './emoji-btn.module.scss'

export default function EmojiBtn({ className, pickerWrpClassname, addEmoji }) {
  const [isEmojiOpen, setIsEmojiOpen] = useState(false)

  const handleSelect = (e, addEmoji) => {
    let emoji = e.native

    if (!emoji) {
      const sym = e.unified.split('-')
      const codesArray = sym.map(el => '0x' + el)
      emoji = String.fromCodePoint(...codesArray)
    }

    addEmoji?.(emoji)
  }

  return (
    <OutsideClickHandler onOutsideClick={() => setIsEmojiOpen(false)}>
      <div className={styles.emojiWrp}>
        <button className={className} onClick={() => setIsEmojiOpen(open => !open)}>
          <IconEmoji />
        </button>
        {isEmojiOpen && (
          <div className={cn(styles.emojiPickerWrp, pickerWrpClassname)}>
            <Picker onSelect={e => handleSelect(e, addEmoji)} />
          </div>
        )}
      </div>
    </OutsideClickHandler>
  )
}
