import { useEffect } from 'react'
import ReactDOM from 'react-dom'
import { useBodyScroll } from '../../contexts/body-scroll'
import OrigLoginModal from '../../../../components/modal/loginModal'
import compStyles from '../../styles/components.module.scss'

export default function LoginModal({ close }) {
  const { enable: enableBodyScroll, disable: disableBodyScroll } = useBodyScroll()

  useEffect(() => {
    disableBodyScroll()
    return enableBodyScroll
  }, [enableBodyScroll, disableBodyScroll])

  return ReactDOM.createPortal(
    <div className={compStyles.overlay}>
      <OrigLoginModal open close={close} />
    </div>,
    document.body
  )
}
