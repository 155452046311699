import { useCallback, useEffect, useRef, useState } from 'react'
import { enqueueSnackbar } from 'notistack'
import cn from 'classnames'
import * as Icons from '../assets/icons'
import { getPosts as qGetPosts } from '../lib/queries'
import { useSMDb } from '../contexts/smdb-ctx'
import Posts from '../components/common/posts'
import styles from './saved-posts.module.scss'

const PostType = {
  Saved: 'Saved',
  Liked: 'Liked',
  Commented: 'Commented',
}

export default function SavedPosts() {
  const { merge: smdbMerge } = useSMDb()

  const [postType, setPostType] = useState(PostType.Saved)
  const [isPostsLoading, setIsPostsLoading] = useState(false)
  const [posts, setPosts] = useState([])

  const paginationRef = useRef({})

  const getPosts = useCallback(
    async pagination => {
      setIsPostsLoading(true)

      try {
        const res = await qGetPosts(pagination, { type: postType })
        setPosts(res.items)
        paginationRef.current = { page: res.page, token: res.token }
      } catch (err) {
        enqueueSnackbar(err)
      }

      setIsPostsLoading(false)
    },
    [postType]
  )

  useEffect(() => {
    setPosts([])
    getPosts({})
  }, [getPosts])

  useEffect(() => {
    for (const post of posts) {
      const { likesCount, liked, topLikes, commentsCount, topComments, saved } = post
      smdbMerge(post._id, { likesCount, liked, topLikes, commentsCount, topComments, saved })

      const { followersCount, followingsCount, isFollowing, postsCount } = post.user
      smdbMerge(post.user._id, { followersCount, followingsCount, isFollowing, postsCount })
    }
  }, [posts, smdbMerge])

  return (
    <div className={styles.savedPostsCont}>
      <div className={styles.rightSec}></div>

      <div className={styles.savedPostsSec}>
        <div className={styles.postTypeTabsWrp}>
          <button
            className={cn(styles.postTypeTabBtn, { [styles.postTypeTabBtnActive]: postType === PostType.Saved })}
            onClick={() => setPostType(PostType.Saved)}
          >
            {postType === PostType.Saved ? (
              <Icons.SaveSolid className={styles.postTypeTabBtnIcon} />
            ) : (
              <Icons.Save className={styles.postTypeTabBtnIcon} />
            )}
            Saved
          </button>

          <button
            className={cn(styles.postTypeTabBtn, { [styles.postTypeTabBtnActive]: postType === PostType.Liked })}
            onClick={() => setPostType(PostType.Liked)}
          >
            {postType === PostType.Liked ? (
              <Icons.HeartSolid className={styles.postTypeTabBtnIcon} />
            ) : (
              <Icons.Heart className={styles.postTypeTabBtnIcon} />
            )}
            Liked
          </button>

          <button
            className={cn(styles.postTypeTabBtn, { [styles.postTypeTabBtnActive]: postType === PostType.Commented })}
            onClick={() => setPostType(PostType.Commented)}
          >
            {postType === PostType.Commented ? (
              <Icons.FiComemntSolid className={styles.postTypeTabBtnIcon} />
            ) : (
              <Icons.FiComemnt className={styles.postTypeTabBtnIcon} />
            )}
            Commented
          </button>
        </div>

        <div className={styles.savedPostsWrp}>
          <Posts items={posts} loading={isPostsLoading} />
        </div>
      </div>

      <div className={styles.rightSec}></div>
    </div>
  )
}
