import React from 'react'
import { connect, Provider } from 'react-redux'
import { HelmetProvider } from 'react-helmet-async'
import { AppRoutes } from './routes/routes'
import { setSessionId, getLocalStorageItem } from './helpers/storageHelper'
import { getUserById, getUserRoles, getSiteConfigurations } from './redux/actions'
import { ApolloProvider } from '@apollo/react-hooks'
import { graphqlClient } from './helpers/graphqlClient'
import configureStore from './redux/store'
import 'react-tooltip/dist/react-tooltip.css'
import 'react-slideshow-image/dist/styles.css'
import './index.css'
import './font.scss'
import '../src/styles/mapAutoComplete.scss'
import 'bootstrap/dist/css/bootstrap.min.css'
import './assets/css/fontAwsome.css'
import 'emoji-mart/css/emoji-mart.css'
import HelmetMetaData from './components/helmetComponent/HelmetMeta'
import { getSeoPages } from './redux/actions/seoActions'
import { Tooltip } from 'react-tooltip'
import { listenForTokenChanges, socket } from './socket'
import { socketEvent } from './util/enums/socketEnums'
import { setUnreadCount, setUserList } from './redux/actions/chatAction'
import { polyfillCountryFlagEmojis } from 'country-flag-emoji-polyfill'
import { envConfig } from './config'
// import Topscroll from './components/gotopscroll/Topscroll'

class App extends React.Component {
  componentDidMount() {
    const saved = getLocalStorageItem('sessionId')

    if (!saved) {
      setSessionId()
    }

    if (!socket.connected) {
      listenForTokenChanges()
    }

    const { getUserById, getUserRoles, getSiteConfigurations } = this.props

    getLocalStorageItem('userId') && getUserById(getLocalStorageItem('userId'))
    getUserRoles()
    getSiteConfigurations()

    socket.on(socketEvent.Connect, () => {
      console.log('socket connect')
    })
  }

  componentWillUnmount() {
    socket.off(socketEvent.Disconnect, () => {
      console.log('socket disconnect')
    })
  }

  render() {
    let SEOData = {
      title: this.props.appMeta?.title,
      image: this.props.appMeta?.image,
      description: this.props.appMeta?.description,
      keywords: this.props.appMeta?.keywords?.join?.(',') || '',
      canonical:
        (envConfig.WEB_ORIGIN || '').replace(/\/?$/, '/') + (this.props.appMeta?.canonicalLink || '').replace(/^\//, ''),
    }
    polyfillCountryFlagEmojis()

    return (
      <ApolloProvider client={graphqlClient}>
        <HelmetProvider>
          <HelmetMetaData
            title={SEOData.title}
            image={SEOData.image}
            description={SEOData.description}
            keywords={SEOData.keywords}
            canonical={SEOData.canonical}
          ></HelmetMetaData>
          <AppRoutes />
        </HelmetProvider>

        <Tooltip id="app-common-tooltip" className="common-tooltip" />
        <Tooltip id="app-common-tooltip-xl" className="common-tooltip common-tooltip-xl" />

        {/* <Topscroll /> */}
      </ApolloProvider>
    )
  }
}

const mapDispatchToProps = dispatch => ({
  getUserById: data => dispatch(getUserById(data)),
  getUserRoles: data => dispatch(getUserRoles(data)),
  getSiteConfigurations: () => dispatch(getSiteConfigurations()),
})

export default connect(null, mapDispatchToProps)(App)
