export const Routes = {
  Home: '/boat-media',
  Search: '/boat-media/search',
  Saved: '/boat-media/saved',
  Notifications: '/boat-media/notifications',
  Post: (postId = null) => `/boat-media/post/${postId ?? ':postId'}`,
  User: (userId = null) => `/boat-media/user/${userId ?? ':userId'}`,
  Market: '/',
  Dashboard: '/dashboard',
  ChangePassword: '/change-password',
  Logout: '/logout',
}

export const PostReportReasons = {
  SpamOrMisleading: 'Spam or Misleading Content',
  NudityOrSexual: 'Nudity or Sexual Activity',
  HateOrDiscrimation: 'Hate Speech or Discrimination',
  Copyright: 'Copyright Infringment',
  Harrassment: 'Harrassment or Bullying',
  Other: 'Other',
}
