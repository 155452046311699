import { useCallback, useEffect, useRef, useState } from 'react'
import ReactDOM from 'react-dom'
import { Link } from 'react-router-dom'
import OutsideClickHandler from 'react-outside-click-handler'
import { enqueueSnackbar } from 'notistack'
import { Routes } from '../../lib/consts'
import { getLikes as qGetLikes } from '../../lib/queries'
import { useBodyScroll } from '../../contexts/body-scroll'
import SpinnerWithText from '../ui/spinner-with-text'
import compStyles from '../../styles/components.module.scss'
import styles from './likes-modal.module.scss'

export default function LikesModal({ postId, close }) {
  const { enable: enableBodyScroll, disable: disableBodyScroll } = useBodyScroll()

  const [likesLoading, setLikesLoading] = useState(false)
  const [likes, setLikes] = useState([])

  const paginationRef = useRef({})

  const getLikes = useCallback(
    async pagination => {
      setLikesLoading(true)

      try {
        const res = await qGetLikes(postId, pagination)
        setLikes(res.items)
        paginationRef.current = { page: res.page }
      } catch (err) {
        enqueueSnackbar(err)
      }

      setLikesLoading(false)
    },
    [postId]
  )

  useEffect(() => {
    setLikes([])
    getLikes({})
  }, [getLikes])

  useEffect(() => {
    disableBodyScroll()
    return enableBodyScroll
  }, [enableBodyScroll, disableBodyScroll])

  return ReactDOM.createPortal(
    <div className={compStyles.overlay}>
      <OutsideClickHandler onOutsideClick={close}>
        <div className={styles.modal}>
          <div className={styles.modalHead}>
            <div className={styles.modalTitle}>Likes</div>
            <button className={styles.modalCloseBtn} onClick={close}>
              &times;
            </button>
          </div>

          <div className={styles.modalBody}>
            <div className={styles.likesWrp}>
              {likes.map(l => (
                <div className={styles.likeWrp}>
                  <Link to={Routes.User(l.user._id)} className={styles.likeInnerWrp}>
                    <span className={styles.userAvatarWrp}>
                      <img src={l.user.image?.url || require('../../assets/avatar-sample.png')} className={styles.userAvatar} />
                    </span>
                    <span className={styles.userName}>
                      {l.user.firstName} {l.user.lastName}
                    </span>
                  </Link>
                </div>
              ))}

              {likesLoading && <SpinnerWithText>Loading...</SpinnerWithText>}
            </div>
          </div>
        </div>
      </OutsideClickHandler>
    </div>,
    document.querySelector('.__as_sm')
  )
}
