import React, { useState, useContext, useEffect } from 'react'
import OutsideClickHandler from 'react-outside-click-handler'
import { Grid, Button, Typography, Box, List, ListItem } from '@material-ui/core'
import { Link } from 'react-router-dom'
import UserContext from '../../UserContext'
import { userRoles } from '../../util/enums/enums'
import './RegisterUser.scss'
import '../../../src/styles/loginDropdownResponsive.scss'
import { BackgroungImage } from '../styleComponent/styleComponent'
import { getCountryDetails } from '../../redux/actions'
import { connect } from 'react-redux'
import { clearLocalStorage, getLocalStorageItem, removeZohoChatWidget, resetZohoChatCookies } from '../../helpers/storageHelper'
import { CommonTooltip } from '../CommonTooltip'
import useZohoSalesIQ from '../../hooks/useZohoSalesIQ'
import { isEmpty } from 'lodash'

function RegisterUser(props) {
  const isAuth = getLocalStorageItem('isAuthenticated')
  const {removeChatWidget, endChatSession} = useZohoSalesIQ()

  function isShowPopUp(isOpen) {
    setIsOpen(isOpen)
    props.setSticky(isOpen)
  }
  const escFunction = event => {
    if (event.keyCode === 27) {
      isShowPopUp(false)
    }
  }
  useEffect(() => {
    props.country && props.getCountryDetails(props.country)
    document.addEventListener('keydown', escFunction, false)

    return () => {
      document.removeEventListener('keydown', escFunction, false)
    }
  }, [])
  const [isOpen, setIsOpen] = React.useState(false)

  const { roles, history, currentUser ,logout } = useContext(UserContext)

  const { siteConfigurations } = props

  const registerHandler = roleName => {
    if (roles && roles.length) {
      const role = roles.find(item => item.aliasName === roleName)
      if (role) {
        history.push({
          pathname: `/register/${roleName}`,
          state: role,
        })
      }
    }
  }

  const loginHandler =  () => {
    const widgetDiv = document.getElementsByClassName('zsiq_flt_rel')
    if (widgetDiv.length) {
      removeZohoChatWidget()
    }
    history && history.push('/login')
    window.location.reload() //invalidate chat widget
  }

  const {
    BOAT_OWNER,
    BOAT_MANUFACTURER,
    BROKER_AND_DEALER,
    MEMBER,
    SURVEYOR,
    YACHT_SHIPPER,
    RENT_AND_CHARTER,
    SERVICE_AND_MAINTENANCE,
    MARINA_AND_STORAGE,
    AGENT,
  } = userRoles
  const isAuthenticated = getLocalStorageItem('isAuthenticated')
  return (
    <OutsideClickHandler
      onOutsideClick={() => {
        isShowPopUp(false)
      }}
    >
      {!isAuth && (
        <Button className="font-weight-400 font-login-signup-header-link">
          {/* <i className="adam-user-5 pr-5"></i> */}
          <span onClick={loginHandler} className="login-signup-header-link" style={{color: '#000000'}}>
            Log in
          </span>
          {!isEmpty(props.countryDetails) && (!props.countryDetails.boatOwerStatus ||
            !props.countryDetails.salesEngineStatus ||
            !props.countryDetails.brokerAndDealerStatus ||
            !props.countryDetails.boatManufacturerStatus ||
            !props.countryDetails.serviceStatus ||
            !props.countryDetails.marinaStorageStatus ||
            !props.countryDetails.rentStatus ||
            !props.countryDetails.sellerStatus ||
            !props.countryDetails.ShipperStatus ||
            !props.countryDetails.mamberStatus ||
            !props.countryDetails.bookNowStatus ||
            !props.countryDetails.surveyorStatus ||
            !props.countryDetails.agentStatus) && (
            <>
              <span>&nbsp;|&nbsp;</span>
              <span
                onClick={() => history.push('/register')}
                className="login-signup-header-link"
                style={{color: '#000000 !important'}}
              >
                Sign up
              </span>
            </>
          )}
        </Button>
      )}

      {isOpen && (
        <div className="login-dropdown-cont">
          <div
            className="login-dropdown login-register-div login-small-screen-dropdown"
            // onMouseLeave={e => {
            //   isShowPopUp(false)
            // }}
          >
            <div className="header-dropdown-main-div">
              {/* welcome image */}
              <div className="header-dropdown-left-div">
                <div className="welcome-login h-100">
                  <BackgroungImage img={siteConfigurations?.siteWelcomeBanner?.url} className="wc-image h-100" />

                  {/* <div className="wc-caption header-dropdown-welcome-section login-dropdown-padding-top">
                    <Box
                      fontSize={18}
                      className="small-screen-font-14 font-weight-500 login-dropdown-welcome-text"
                    >
                      Welcome!
                    </Box>
                    <Typography variant="caption" className="description-text">
                      <div>
                        <span className="header-dropdown-div small-screen-font-9 color-black login-dropdown-welcome-desc-text">
                          {siteConfigurations?.siteWelcomeContent}
                        </span>
                      </div>
                    </Typography>
                    <div className="button-text">
                      <Button
                        className="popup-login small-screen-font-10 login-dropdown-button"
                        size="small"
                        variant="outlined"
                        onClick={loginHandler}
                      >
                        Login
                      </Button>
                    </div>
                  </div> */}
                </div>
              </div>

              {/* list text */}
              <div className="d-flex flex-column justify-content-center header-dropdown-right-div pl-4 pr-4">
                <Grid container className="header-dropdown-listing-div">
                  <Grid item xs={12}>
                    <Box
                      fontWeight="bold"
                      fontSize={16}
                      className="d-flex registerListing-font color-black login-register-dropdown"
                      style={{ marginTop: 24 }}
                    >
                      New to AdamSea? Create an account to get started today
                    </Box>
                  </Grid>

                  {!isEmpty(props.countryDetails) && (!props.countryDetails.boatOwerStatus ||
                    !props.countryDetails.brokerAndDealerStatus ||
                    !props.countryDetails.boatManufacturerStatus ||
                    !props.countryDetails.mamberStatus ||
                    !props.countryDetails.agentStatus ||
                    !props.countryDetails.ShipperStatus ||
                    !props.countryDetails.surveyorStatus) && (
                    <Grid item xs={12} md={4} className="p-15 login-dropdown-padding-top login--dropdown--right--div">
                      <Grid container className="register-listing-text cursor-pointer">
                        <Grid item sm={12}>
                          <Grid container spacing={3}>
                            {(!props.countryDetails.boatOwerStatus ||
                              !props.countryDetails.brokerAndDealerStatus ||
                              !props.countryDetails.boatManufacturerStatus) && (
                              <Grid item xs={6} sm={4} md={6} className="register-user-header-drop-down">
                                <Box
                                  fontSize={14}
                                  letterSpacing={0.5}
                                  fontWeight={500}
                                  className="register-type small-screen-font-9 login-register-dropdown-links login-dropdown-title-div header-signup-dropdown-titles signup--user--title color-black"
                                >
                                  Seller
                                </Box>
                                <List className="listing-menu">
                                  {!props.countryDetails.boatOwerStatus && (
                                    <ListItem
                                      className="pl-0 registerListing-innerType register-markets-page-links  login-register-dropdown-links color-black link-hover-text signup--user--role"
                                      onClick={() => registerHandler(BOAT_OWNER)}
                                    >
                                      <CommonTooltip component={ctProps => (
                                        <div className='small-screen-font-12' data-tooltip-id={ctProps.id} data-tooltip-content="If you have one or multiple boats for sale, with AdamSea, you can sell them or add them to an auction.">
                                          Boat Owner
                                        </div>
                                      )} className="header-signup-tooltip text-center" />
                                    </ListItem>
                                  )}
                                  {!props.countryDetails.brokerAndDealerStatus && (
                                    <ListItem
                                      className="pl-0 registerListing-innerType register-markets-page-links small-screen-font-12 login-register-dropdown-links color-black link-hover-text  signup--user--role"
                                      onClick={() => registerHandler(BROKER_AND_DEALER)}
                                    >
                                      <CommonTooltip component={ctProps => (
                                        <div className='small-screen-font-12' data-tooltip-id={ctProps.id} data-tooltip-content="If you are a boat dealer selling in quantities or a boat broker, add all your inventory on AdamSea to make more profit.">
                                          Broker and Dealer
                                        </div>
                                      )} className="header-signup-tooltip text-center" />
                                    </ListItem>
                                  )}
                                  {!props.countryDetails.boatManufacturerStatus && (
                                    <ListItem
                                      className="pl-0 registerListing-innerType register-markets-page-links small-screen-font-12 login-register-dropdown-links color-black link-hover-text signup--user--role"
                                      onClick={() => registerHandler(BOAT_MANUFACTURER)}
                                    >
                                      <CommonTooltip component={ctProps => (
                                        <div className='small-screen-font-12' data-tooltip-id={ctProps.id} data-tooltip-content="List all your boat models, sell in bulk, assign your dealership, manage your stock, and increase your sales.">
                                          Boat Manufacturer
                                        </div>
                                      )} className="header-signup-tooltip text-center" />
                                    </ListItem>
                                  )}
                                </List>
                              </Grid>
                            )}
                            {!props.countryDetails.mamberStatus && (
                              <Grid item xs={6} sm={4} md={6} className="register-user-header-drop-down">
                                <Box
                                  fontSize={14}
                                  letterSpacing={0.5}
                                  fontWeight={500}
                                  className="register-type small-screen-font-9 login-register-dropdown-links color-black login-dropdown-title-div header-signup-dropdown-titles signup--user--title"
                                >
                                  Community
                                </Box>
                                <List className="listing-menu">
                                  {!props.countryDetails.mamberStatus && (
                                    <ListItem
                                      className="pl-0 registerListing-innerType register-markets-page-links small-screen-font-10 login-register-dropdown-links color-black link-hover-text signup--user--role"
                                      onClick={() => registerHandler(MEMBER)}
                                    >
                                      <CommonTooltip component={ctProps => (
                                        <div className='small-screen-font-12' data-tooltip-id={ctProps.id} data-tooltip-content="Join AdamSea social media, use instant messages, build your connections and groups, add articles and boat shows, and enjoy renting and buying.">
                                          Member
                                        </div>
                                      )} className="header-signup-tooltip text-center" />
                                    </ListItem>
                                  )}
                                </List>
                              </Grid>
                            )}
                            {!props.countryDetails.agentStatus && (
                              <Grid item xs={6} sm={4} md={6} className="register-user-header-drop-down">
                                <Box
                                  fontSize={14}
                                  letterSpacing={0.5}
                                  fontWeight={500}
                                  className="register-type small-screen-font-9 login-register-dropdown-links color-black login-dropdown-title-div header-signup-dropdown-titles signup--user--title"
                                >
                                  Agent
                                </Box>
                                <List className="listing-menu">
                                  {!props.countryDetails.agentStatus && (
                                    <ListItem
                                      className="pl-0 registerListing-innerType register-markets-page-links small-screen-font-10 login-register-dropdown-links color-black link-hover-text signup--user--role"
                                      onClick={() => registerHandler(AGENT)}
                                    >
                                      <CommonTooltip component={ctProps => (
                                        <div className='small-screen-font-12' data-tooltip-id={ctProps.id} data-tooltip-content="Connect with buyers, sellers, shippers, and facilitate the sales process.">
                                          AdamSea Agent
                                        </div>
                                      )} className="header-signup-tooltip text-center" />
                                    </ListItem>
                                  )}
                                </List>
                              </Grid>
                            )}
                            {(!props.countryDetails.ShipperStatus || !props.countryDetails.surveyorStatus) && (
                              <Grid item xs={6} sm={4} md={6} className="register-user-header-drop-down">
                                <Box
                                  fontSize={14}
                                  letterSpacing={0.5}
                                  fontWeight={500}
                                  style={{width: 'max-content'}}
                                  className="register-type small-screen-font-9 login-register-dropdown-links color-black login-dropdown-title-div header-signup-dropdown-titles signup--user--title"
                                >
                                  Other Category
                                </Box>
                                <List className="listing-menu">
                                  {!props.countryDetails.ShipperStatus && (
                                    <ListItem
                                      className="pl-0 registerListing-innerType register-markets-page-links small-screen-font-10 login-register-dropdown-links color-black link-hover-text signup--user--role"
                                      onClick={() => registerHandler(YACHT_SHIPPER)}
                                    >
                                      <CommonTooltip component={ctProps => (
                                        <div className='small-screen-font-12' data-tooltip-id={ctProps.id} data-tooltip-content="Ship every boat sold out online on AdamSea and increase your profit.">
                                          Boat Shipper
                                        </div>
                                      )} className="header-signup-tooltip text-center" />
                                    </ListItem>
                                  )}
                                  {!props.countryDetails.surveyorStatus && (
                                    <ListItem
                                      className="pl-0 registerListing-innerType register-markets-page-links small-screen-font-10 login-register-dropdown-links color-black link-hover-text signup--user--role"
                                      onClick={() => registerHandler(SURVEYOR)}
                                    >
                                      <CommonTooltip component={ctProps => (
                                        <div className='small-screen-font-12' data-tooltip-id={ctProps.id} data-tooltip-content="Open your workshop online, add all your branches in one account, connect with buyers, and get more boats looking for a survey.">
                                          Marine Surveyor
                                        </div>
                                      )} className="header-signup-tooltip text-center" />
                                    </ListItem>
                                  )}
                                </List>
                              </Grid>
                            )}
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  )}
                  <Grid item xs={12} md={8} className="p-15 login-dropdown-padding-top">
                    <Grid container spacing={3} className="header-dropdown-market-section">
                      {!isEmpty(props.countryDetails) && !props.countryDetails.serviceStatus && (
                        <Grid item xs={4} className="pt-0">
                          <ListItem
                            onClick={() => registerHandler(SERVICE_AND_MAINTENANCE)}
                            className="header-dropdown-market-img"
                          >
                            <CommonTooltip component={ctProps => (
                              <div data-tooltip-id={ctProps.id} data-tooltip-content="Open your workshop online, list all your marine services, reach out to more boat owners, increase your profit, and enjoy AdamSea social media.">
                                <div className="adamseaMarket-img cursor-pointer">
                                  <img
                                    className="border-radius small-screen-dropdown-sections-img image-opacity"
                                    src={siteConfigurations?.serviceMaintenanceBanner?.url}
                                    alt="adamsea"
                                  />
                                </div>
                                <div className="adamseaMarket-name d-flex mt-3 justify-content-center">
                                    <span className="small-screen-font-16 cursor-pointer login-register-dropdown-links link-hover-text color-black">
                                      Boat Service
                                    </span>
                                </div>
                              </div>
                            )} className="header-signup-tooltip text-center" />
                          </ListItem>
                        </Grid>
                      )}
                      {!isEmpty(props.countryDetails) && !props.countryDetails.marinaStorageStatus && (
                        <Grid item xs={4} className="pt-0">
                          <ListItem onClick={() => registerHandler(MARINA_AND_STORAGE)} className="header-dropdown-market-img">
                            <CommonTooltip component={ctProps => (
                              <div data-tooltip-id={ctProps.id} data-tooltip-content="If you are operating a marina or storage, list your facility or multiple ones in one account, add boat shows, connect with boat and yacht owners, and enjoy AdamSea social media.">
                                <div className="adamseaMarket-img cursor-pointer">
                                  <img
                                    className="border-radius small-screen-dropdown-sections-img image-opacity"
                                    src={siteConfigurations?.marinaBanner?.url}
                                    alt="adamsea"
                                  />
                                </div>
                                <div className="adamseaMarket-name d-flex mt-3 justify-content-center">
                                    <span className="small-screen-font-16 cursor-pointer login-register-dropdown-links link-hover-text color-black">
                                      Marina & Storage
                                    </span>
                                </div>
                              </div>
                            )} className="header-signup-tooltip text-center" />
                          </ListItem>
                        </Grid>
                      )}
                      {!isEmpty(props.countryDetails) && !props.countryDetails.rentStatus && (
                        <Grid item xs={4} className="pt-0">
                          {/* <Link to="/rent"> */}
                          <ListItem onClick={e => registerHandler(RENT_AND_CHARTER)} className="header-dropdown-market-img">
                            <CommonTooltip component={ctProps => (
                              <div data-tooltip-id={ctProps.id} data-tooltip-content="Rent out your boat on AdamSea and sell your ticket online to earn extra income.">
                                <div className="adamseaMarket-img cursor-pointer">
                                  <img
                                    className="border-radius small-screen-dropdown-sections-img image-opacity"
                                    src={siteConfigurations?.rentBanner?.url}
                                    alt="adamsea"
                                  />
                                </div>
                                <div className="adamseaMarket-name d-flex mt-3 justify-content-center">
                                    <span className="small-screen-font-16 cursor-pointer login-register-dropdown-links link-hover-text color-black">
                                      Rent & Charter
                                    </span>
                                </div>
                              </div>
                            )} className="header-signup-tooltip text-center" />
                          </ListItem>
                        </Grid>
                      )}
                    </Grid>
                  </Grid>
                </Grid>
              </div>
            </div>
          </div>
          <div style={{ height: '100%', }} onTouchMove={() => setIsOpen(false)}></div>
        </div>
      )}
    </OutsideClickHandler>
  )
}

const mapStateToProps = state => ({
  countryDetails: state.dashboardReducer.countryDetails,
})

const mapDispatchToProps = dispatch => ({
  getCountryDetails: data => dispatch(getCountryDetails(data)),
})

export default connect(mapStateToProps, mapDispatchToProps)(RegisterUser)
