/* eslint-disable react-hooks/exhaustive-deps */
import '../../components/home/icon.scss'
import './userProfile.scss'
import QuoteLogo from '/public/image/quot.png'
import React, { useContext, useState, useEffect } from 'react'
import moment from 'moment'
import { Link } from 'react-router-dom'
import { Container, Row, Col, Spinner } from 'react-bootstrap'
import { connect } from 'react-redux'
import { Formik, Form, ErrorMessage } from 'formik'
import * as Yup from 'yup'
import { Grid, TextField } from '@material-ui/core'
import { KeyboardArrowRight } from '@material-ui/icons'
import StarIcon from '@material-ui/icons/Star'
import StarBorderIcon from '@material-ui/icons/StarBorder'
import Rating from 'react-rating'
import MonetizationOnOutlinedIcon from '@material-ui/icons/MonetizationOnOutlined'
import { Layout } from '../../components'
import UserContext from '../../UserContext'
import InnerRattingProfile from '../staticComponent/innerRattingProfile'
import {
  displayDefaultReview,
  nameFormatter,
  displayDefaultValue,
  lowerHypenCase,
  displayDefaultNumericValue,
} from '../../helpers/string'
import { UserProfileListings } from '../gridComponents/userProfileListings'
import {
  dimension,
  defaultProfileIcon,
  ratingEnums,
  pagination,
  officeLocationAccessibleRole,
  userRoles,
  manufacturerStatus,
  userRoleTypes,
} from '../../util/enums/enums'
import { getOnlineUsers, reviewType } from '../../util/utilFunctions'
import ErrorFocus from '../ws/ErrorFocus'
import { getUserById, getItemsByUser, subscribeToUser, clear_subscribeToUser } from '../../redux/actions'

import { cityCountryNameFormatter, getAddress, getUserListingLabel } from '../../helpers/jsxHelper'
import { getReviewByModuleId } from '../../redux/actions/ReviewAction'
import { CommonModal } from '../modal/commonModal'
import UserProfileAllReviews from '../gridComponents/userProfileAllReviews'
import { Loader } from '../loader/loader'
import { UserPersonalInfo } from './userPersonalInfo'
import { UserDocumentInfo } from './userDocumentInfo'
import { ReviewSection } from './reviewSection'
import { isMobile } from 'react-device-detect'
import ReadMore from '../helper/truncate'
import { ButtonComponent } from '../form/Button'
import { getUserAllArticle } from '../../redux/actions/articlesAction'
import { UserArticales } from '../user/UserArticales'
import { ShowAllLink } from '../helper/showAllLink'
import { getGatheringPointMarker, getSurveyorMarkersWithOffice } from '../../helpers/boatHelper'
import GoogleMarker from '../../components/map/marker'
import { ShareWithChatAndUser } from '../share/ShareWithChatAndUser'
import { dealerRequestsManufacturer } from '../../redux/actions/dealersAction'
import { stringValidation } from '../../helpers/yupHelper'
import { CurrencyContextConsumer } from '../../CurrencyContext'
import { getConvertedPrice } from '../../helpers/currencyConverterHelper'
import ReviewRating from '../reviewRating/ReviewRating'
import { getLocalStorageItem } from '../../helpers/storageHelper'
import LoginModal from '../modal/loginModal'
import classNames from 'classnames'
import { ChatContext } from '../../contexts/ChatContext'
import { SET_ONLINE_USERS } from '../../redux/actionTypes'
import { MdVerifiedUser } from 'react-icons/md'
import VerificationModal from '../../containers/verificationModal'
import { StyledSubscribeBtn } from '../styleComponent/styleComponent'
import { FaRegBell } from 'react-icons/fa'

const UserProfile = props => {
  const { match, createReview } = useContext(UserContext)

  const [ratingError, setRatingError] = useState(false)

  const [visible, setVisible] = useState(false)

  const [open, setOpen] = useState(false)

  const closeModal = () => {
    setOpen(!open)
  }

  const closeReviewModal = () => {
    setVisible(!visible)
  }

  const [review] = useState({
    rating: 0,
    reviews: '',
    reviewWithTypes: [],
    communication: 0,
    recommended: 0,
    organization: 0,
    goodValue: 0,
    goodQuality: 0,
    service: 0,
    servicesQuality: 0,
    surveyQuality: 0,
    valueForMoney: 0,
    safety: 0,
  })

  const {
    currentUser,
    userProfileDetail,
    reviews,
    relatedItems,
    relatedTotal,
    totalArticles,
    articles,
    dealerRequestsManufacturer,
    isLoading,
    dealerRequestsIds,
    boat,
    subscribeToUser,
    subscribeLoader,
    history,
    match: { params },
    setOnlineUsers,
  } = props

  const { currentUserChat } = useContext(ChatContext)

  useEffect(() => {
    const {
      params: { id },
    } = match
    const { getUserById, getReviewByModuleId, getItemsByUser, getUserAllArticle } = props

    id && getUserById(id, true)
    id && getReviewByModuleId({ moduleId: id })
    id && getItemsByUser({ userId: id, page: pagination.PAGE_COUNT })
    id &&
      getUserAllArticle({
        userId: id,
        page: pagination.PAGE_COUNT,
        limit: pagination.MARKET_PAGE_LIMIT,
      })
  }, [params?.id])

  useEffect(() => {
    const {
      params: { id },
    } = match
    const { getReviewByModuleId, isReview } = props

    isReview && id && getReviewByModuleId({ moduleId: id })
  }, [props.isReview])

  useEffect(() => {
    if (props.subscribeError) {
      props.clear_subscribeToUser()
    }
  }, [props.subscribeError])

  const surveyorMainBranch =
    relatedItems?.surveyorBranches?.length && relatedItems?.surveyorBranches.find(item => item.isMainBranch)

  const renderTypeWiseRatings = (values, setFieldValue) => {
    const { userProfileDetail } = props
    const ratingTypes = userProfileDetail && userProfileDetail.role && reviewType(userProfileDetail.role)

    return (
      <div className="ratingQuality width-100 d-flex justify-content-around flex-column">
        {ratingTypes &&
          ratingTypes.length > 0 &&
          ratingTypes.map(item => (
            <div className="d-flex">
              <span className="review-quality profile-add-review">{item.name}</span>
              <Rating
                className="rating-clr  profile-add-rating"
                initialRating={0}
                onClick={value => {
                  setFieldValue(item.key, value)
                }}
                emptySymbol={<StarBorderIcon />}
                placeholderSymbol={<StarIcon />}
                fullSymbol={<StarIcon />}
                placeholderRating={values[item.key]}
              />
            </div>
          ))}
      </div>
    )
  }

  const subscribeHandler = async () => {
    const userId = getLocalStorageItem('userId')
    const {
      params: { id },
    } = match

    if (!userId) {
      handlerLoginModal()
      return
    } else if (id) {
      await subscribeToUser({
        id: id,
      })
    }
  }

  const [loginModal, setLoginModal] = useState(false)

  const handlerLoginModal = () => setLoginModal(!loginModal)

  if (userProfileDetail && userProfileDetail.id) {
    var {
      id,
      firstName,
      lastName,
      createdAt,
      address: [{ city, country }],
      role,
      documentVerification,
      image,
      companyLogo,
      companyName,
      shortDescription,
      language,
      profession,
      isVerified,
      provider,
      dealerRequests,
      subscribers,
    } = userProfileDetail
  }

  useEffect(() => {
    id && getOnlineUsers([id], setOnlineUsers)
  }, [id])

  const { officeAddress } = getAddress(userProfileDetail?.address)
  const isUserRequested = dealerRequestsIds?.length > 0 && dealerRequestsIds.includes(currentUser?.id)
  const requestedDealer = dealerRequests?.length > 0 && dealerRequests.find(item => item.dealer?.id === currentUser?.id)

  const loggedInUserId = getLocalStorageItem('userId')
  const isSubscribe = subscribers?.map(user => (typeof user === 'object' ? user?.id : user)).includes(loggedInUserId)

  return (
    <Layout className="user-profile-layout not--scroll-auto adam-user-profile">
      <LoginModal open={loginModal} close={handlerLoginModal} />

      {userProfileDetail && !userProfileDetail.id ? (
        <Loader isPageLoader />
      ) : (
        <Container className="user--profile--layout--Container user--profile--spacing pr-0 pl-0">
          <Row className="margin-zero user-profile-top-space">
          <Col sm={5} className="pl-0">
              <div className="card--user--info--profile">
                <Row className="margin-zero">
                  <Col md={6} sm={12} className="user-info-profile pr-4">
                    <div className="user--profile--info--desc">
                      {documentVerification && (
                        <UserDocumentInfo
                          id={id}
                          documentVerification={documentVerification}
                          firstName={firstName}
                          role={role}
                          provider={provider}
                        />
                      )}
                    </div>
                  </Col>
                  <Col sm={12} md={6} className="userprofile--user--img--div">
                    <div className="user-profile-online-section--with-icon">
                      <div className="user-profile-online-section">
                        <div className="user-profile-online-section--img">
                          <img
                            loading="lazy"
                            className="rounded-circle"
                            src={image?.url || defaultProfileIcon}
                            alt="placeholder"
                          />
                          {isVerified && (
                            <span className="verify-icon-img">
                              <img src={require('../../assets/images/verif.svg')} alt="verified user" />
                            </span>
                          )}
                          <div className="user-online">
                            <div
                              className={classNames('online-div-user', {
                                disconnect: !props.onlineUsers[id],
                              })}
                            ></div>
                          </div>
                        </div>
                        <div className="user--profile--share--icon">
                          <ShareWithChatAndUser
                            name={`${firstName} ${lastName}`}
                            imageUrl={image?.url}
                            userId={id}
                            className="user--profile-share"
                          />
                        </div>
                        {!currentUser?.id && (
                          <div style={{ marginBottom: 20 }}>
                            <button
                              type="button"
                              className="btn btn-black rounded-full btn-black-stay-touch"
                              style={{ fontSize: 14, padding: '6px 12px' }}
                              onClick={handlerLoginModal}
                            >
                              Stay in touch
                            </button>
                            <LoginModal open={loginModal} close={handlerLoginModal} />
                          </div>
                        )}
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>
            </Col>

            <Col sm={7} className="rent-profile-venue">
              <div className="user-profile-desc-div h-100 mr-5" style={{ paddingLeft: 50 }}>
                <h1 className="font-weight-400 user-color-profile profile-user-name color-black text-capitalize">
                  {nameFormatter([firstName, lastName])}
                </h1>
                <h5 className="font-weight-400 mt-2 rentInner-userTextH4 dark-silver font-16 user--profile--join--date">
                  Since {moment(createdAt).format('MMMM YYYY')}
                </h5>
                {shortDescription ? (
                  <>
                    <img loading="lazy" src={QuoteLogo} className="icon-30 mr-2 mb-4 mt-4 icon---30" alt="AdamSea" />
                    <div className="font-weight-400 rentInner-userTextH4 color-black font-16 user--inner--user--des">
                      {/* <strong style={{ fontSize: '1.1em' }}>Bio</strong> */}
                      <ReadMore className="text-justify ">{shortDescription}</ReadMore>
                    </div>
                  </>
                ) : (
                  <h5 className="font-weight-400 rentInner-userTextH4 dark-silver font-16 p-5 text-center">No Description</h5>
                )}
                <hr className="ml-0 mr-0 userprofile--hr--spacing" width="50%" />
              </div>
            </Col>

            <Col sm={5} className="pt-4 pl-0">
              <div className="card--user--info--profile card--user--info--profile-no-shadow">
                <Row className="margin-zero h-100 align-items-center">
                  <Col sm={12} md={companyName ? 8 : 12} className="user-info-profile user-info-profile-no-border pr-4">
                    <div className="user--profile--info--desc h-100">
                      <UserPersonalInfo
                        role={role}
                        isVerified={isVerified}
                        reviews={reviews}
                        total={relatedTotal}
                        totalArticles={totalArticles}
                        totalBoatShows={relatedItems?.totalBoatShows}
                        horizontal={!companyName}
                      />
                    </div>
                  </Col>
                  {companyName && (
                    <Col sm={12} md={4} className="userprofile--user--img--div">
                      <div className="user-profile-online-section--with-icon">
                        {companyLogo?.url && (
                          <div className="logo-box" style={{ height: 'auto' }}>
                            <img loading="lazy" src={companyLogo.url} alt="Logo" />
                          </div>
                        )}
                        {companyName && <h5 className="company-name text-center text-capitalize font-16">{companyName}</h5>}
                      </div>
                    </Col>
                  )}
                </Row>
              </div>
            </Col>

            <Col sm={7} className="rent-profile-venue" style={{ paddingRight: 40 }}>
              <div style={{ paddingLeft: 50 }}>
                {role && role.role && role.aliasName !== userRoles.MEMBER && (
                  <div className="mb-3 d-flex user--profile--info lives--in--icon align-items-center">
                    <img
                      src={require('../../components/userProfile/image/user_9340263.svg')}
                      className="user--profile--info--img mr-2"
                      alt="Boat shipper"
                    />
                    <h5 className="font-weight-400 rentInner-userTextH4 dark-silver font-16 mb-none ml-2">
                      <span className="under--line dark--bold color-black" style={{ textTransform: 'uppercase' }}>
                        {' '}
                        {role?.role.toLowerCase().includes('maintenance')
                          ? 'Boat Service'
                          : role?.role.toLowerCase().includes('manufacturer')
                          ? 'Manufacturer'
                          : role?.role.toLowerCase().includes('dealer')
                          ? 'Broker & Dealer'
                          : role?.role?.toLowerCase()}
                      </span>
                    </h5>
                  </div>
                )}
                <div className="mb-3 d-flex user--profile--info lives--in--icon align-items-center">
                  <img
                    src={require('../../components/userProfile/image/address.png')}
                    className="user--profile--info--img mr-2"
                    alt="Address"
                  />
                  <h5 className="font-weight-400 rentInner-userTextH4 dark-silver font-16 mb-none ml-2 inner-user-profile-info-text  user--profile--lives--in">
                    <span className="under--line dark--bold color-black">Lives in</span>{' '}
                    {cityCountryNameFormatter(city, country)}
                  </h5>
                </div>
                {language && (
                  <div className="mb-3 d-flex user--profile--info lives--in--icon align-items-center">
                    <img
                      src={require('../../components/userProfile/image/message.png')}
                      className="user--profile--info--img mr-2"
                      alt="Message"
                    />
                    <h5 className="font-weight-400 rentInner-userTextH4 dark-silver font-16 mb-none ml-2">
                      <span className="under--line dark--bold color-black">Speaks</span>
                      {nameFormatter(language, ', ')}
                    </h5>
                  </div>
                )}
                {profession && profession.alias && (
                  <div className="mb-3 d-flex user--profile--info lives--in--icon align-items-center">
                    <img
                      src={require('../../components/userProfile/image/work.png')}
                      className="user--profile--info--img mr-2"
                      alt="work"
                    />
                    <h5 className="font-weight-400 rentInner-userTextH4 dark-silver font-16 mb-none ml-2">
                      <span className="under--line dark--bold color-black"> Work </span> {displayDefaultValue(profession.alias)}
                    </h5>
                  </div>
                )}
                {role.aliasName === userRoles.BOAT_MANUFACTURER &&
                  currentUser?.role?.aliasName === userRoles.BROKER_AND_DEALER &&
                  !requestedDealer?.accepted && (
                    <div className="mt-3 d-flex user--profile--info lives--in--icon">
                      <ButtonComponent
                        onClick={() => dealerRequestsManufacturer({ manufacturerId: id })}
                        disabled={isUserRequested}
                        loader={isLoading}
                        className="h-auto as--view--outline btn--padding btn--font auction--bid--now--button"
                      >
                        {isUserRequested ? manufacturerStatus.requested : manufacturerStatus.request}
                      </ButtonComponent>
                    </div>
                  )}
              </div>
            </Col>
          </Row>
          {/* above all mine */}
          <Row className="margin-zero">
            <Col xs={12}>
              <div>
                {role?.aliasName !== userRoles.YACHT_SHIPPER &&
                  (relatedItems?.total > 0 || relatedItems?.totalBoatShows > 0) &&
                  (role?.aliasName !== userRoles.SURVEYOR ||
                    (role?.aliasName === userRoles.SURVEYOR &&
                      relatedItems?.surveyorBranches?.some(item => !item.isMainBranch))) && (
                    <>
                      <div className="user--profile--listings--section">
                        <h4 className="color-black section-heading mt-0 article--listing--title font-32">
                          {getUserListingLabel(userProfileDetail)}
                        </h4>
                        <UserProfileListings
                          itemsLength={5}
                          data={relatedItems}
                          dimension={dimension.boatMediaVideo}
                          history={history}
                          role={role}
                          total={relatedTotal}
                          totalBoatShows={relatedItems?.totalBoatShows}
                          id={id}
                        />
                      </div>
                    </>
                  )}
                {totalArticles > 0 && (
                  <>
                    <div className="user--profile--articles--section user--profile--listings--section">
                      <div className="d-flex align-items-center justify-content-normal mb-2">
                        <div className="d-flex flex-column">
                          <h4 className="color-black section-heading mt-0 mb-0">
                            {nameFormatter([firstName, lastName])}'s Articles
                          </h4>
                          <p className="d-flex align-items-center mt-2">
                            {displayDefaultNumericValue(subscribers?.length)} Followers
                            {loggedInUserId !== id && (
                              <div className="email-subscribe-form">
                                <StyledSubscribeBtn onClick={subscribeHandler}>
                                  {isSubscribe && <FaRegBell style={{ width: '14px', marginRight: '4px' }} color="white" />}
                                  {
                                    // subscribeLoader ? (
                                    //   <Spinner animation="border" size="sm" />
                                    // ) : (
                                    // `${
                                    isSubscribe ? 'FOLLOWED' : 'FOLLOW'
                                    //   }`
                                    // )
                                  }
                                </StyledSubscribeBtn>
                              </div>
                            )}
                          </p>
                        </div>
                      </div>
                      <div className="zoom-container home-grid-layout-boat">
                        {articles.slice(0, 4).map(artical => {
                          return <UserArticales key={artical.id} artical={artical} />
                        })}
                      </div>
                      <ShowAllLink
                        totalLength={totalArticles}
                        className="boat--grid-show--all"
                        itemsLength={4}
                        data={articles}
                        showAllText={'articles'}
                        url={`/articles/${id}/${lowerHypenCase(nameFormatter([firstName, lastName]))}`}
                      />
                    </div>
                  </>
                )}

                {officeAddress?.address1 &&
                  role?.aliasName &&
                  officeLocationAccessibleRole.includes(role.aliasName) &&
                  ((role?.aliasName === userRoles.SURVEYOR && relatedItems?.surveyorBranches?.length) ||
                    role?.aliasName === userRoles.YACHT_SHIPPER) && (
                    <>
                      <h4 className="color-black section-heading mt-5">Office Location</h4>
                      <div className="boat--inner--map--div boat--user--inner--map--div">
                        <GoogleMarker
                          isRedirectToMap
                          geometricLocation={officeAddress?.geometricLocation}
                          markers={getSurveyorMarkersWithOffice(relatedItems?.surveyorBranches, officeAddress, companyName)}
                        />
                      </div>
                    </>
                  )}

                {reviews && reviews.reviews && role && role?.aliasName !== userRoles.MEMBER && (
                  <div className="rating-section" style={{ marginTop: 80 }}>
                    <ReviewRating
                      forUser
                      userDetails={userProfileDetail}
                      isRent={role?.aliasName === userRoles.RENT_AND_CHARTER}
                      reviews={reviews}
                      currentUser={currentUser}
                      boat={userProfileDetail}
                    />
                  </div>
                )}
              </div>
            </Col>
          </Row>
        </Container>
      )}

      <VerificationModal open={props.subscribeError} content="before you can subscribe to any user" />
    </Layout>
  )
}

const mapStateToProps = state => ({
  currentUser: state.loginReducer.currentUser || {},
  userProfileDetail: state.loginReducer.userProfileDetail || {},
  createSuccess: state.reviewReducer && state.reviewReducer.createSuccess,
  isReview: state.reviewReducer && state.reviewReducer.isReview,
  reviews: state.reviewReducer && state.reviewReducer.reviews,
  relatedItems: state.loginReducer.relatedItems,
  relatedTotal: state.loginReducer.relatedTotal,
  articles: state.articleReducer.articles,
  totalArticles: state.articleReducer.totalArticles,
  isLoading: state.dealersReducer?.isLoading,
  dealerRequestsIds: state.loginReducer?.dealerRequestsIds,
  subscribeLoader: state.loginReducer.subscribeLoader,
  onlineUsers: state.chatReducer.onlineUsers,
  subscribeError: state.articleReducer.subscribeError,
})
const mapDispatchToProps = dispatch => ({
  getUserById: (data, userProfile) => dispatch(getUserById(data, userProfile)),
  getReviewByModuleId: data => dispatch(getReviewByModuleId(data)),
  getItemsByUser: data => dispatch(getItemsByUser(data)),
  getUserAllArticle: data => dispatch(getUserAllArticle(data)),
  dealerRequestsManufacturer: data => dispatch(dealerRequestsManufacturer(data)),
  subscribeToUser: data => dispatch(subscribeToUser(data)),
  setOnlineUsers: users => dispatch({ type: SET_ONLINE_USERS, payload: users }),
  clear_subscribeToUser: () => dispatch(clear_subscribeToUser()),
})

export default connect(mapStateToProps, mapDispatchToProps)(UserProfile)
