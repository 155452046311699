import { useCallback, useEffect, useRef, useState } from 'react'
import { useParams } from 'react-router-dom'
import { enqueueSnackbar } from 'notistack'
import cn from 'classnames'
import * as Icons from '../assets/icons'
import { getPosts as qGetPosts, getUser as qGetUser, getBlogs as qGetBlogs } from '../lib/queries'
import { useSMDb } from '../contexts/smdb-ctx'
import SpinnerWithText from '../components/ui/spinner-with-text'
import ProfileBox from '../components/profile/profile-box'
import Posts from '../components/common/posts'
import Blogs from '../components/common/blogs'
import styles from './profile.module.scss'

const ItemType = {
  Post: 'Post',
  Blog: 'Blog',
}

export default function Profile() {
  const { merge: smdbMerge } = useSMDb()

  const params = useParams()
  const userId = params.userId

  const [isUserLoading, setIsUserLoading] = useState(false)
  const [user, setUser] = useState(null)

  const [itemType, setItemType] = useState(ItemType.Post)

  const [postsLoading, setPostsLoading] = useState(false)
  const [posts, setPosts] = useState([])

  const [blogsLoading, setBlogsLoading] = useState(false)
  const [blogs, setBlogs] = useState([])

  const paginationRef = useRef({})

  const getPosts = useCallback(
    async pagination => {
      setPostsLoading(true)

      try {
        const res = await qGetPosts(pagination, { userId })
        setPosts(res.items)
        paginationRef.current = { page: res.page, token: res.token }
      } catch (err) {
        enqueueSnackbar(err)
      }

      setPostsLoading(false)
    },
    [userId]
  )

  const getBlogs = useCallback(
    async pagination => {
      setBlogsLoading(true)

      try {
        const res = await qGetBlogs(pagination, { userId })
        setBlogs(res.items)
        paginationRef.current = { page: res.page, token: res.token }
      } catch (err) {
        enqueueSnackbar(err)
      }

      setBlogsLoading(false)
    },
    [userId]
  )

  useEffect(() => {
    const getUser = async () => {
      setIsUserLoading(true)

      try {
        const res = await qGetUser(userId)
        setUser(res)
        smdbMerge(userId, res)
      } catch (err) {
        enqueueSnackbar(err)
      }

      setIsUserLoading(false)
    }

    getUser()
  }, [userId, smdbMerge])

  useEffect(() => {
    setPosts([])
    setBlogs([])
    itemType === ItemType.Post ? getPosts({}) : getBlogs({})
  }, [itemType, userId, getPosts])

  useEffect(() => {
    for (const post of posts) {
      const { likesCount, liked, topLikes, commentsCount, topComments, saved } = post
      smdbMerge(post._id, { likesCount, liked, topLikes, commentsCount, topComments, saved })

      const { followersCount, followingsCount, isFollowing, postsCount } = post.user
      smdbMerge(post.user._id, { followersCount, followingsCount, isFollowing, postsCount })
    }
  }, [posts, smdbMerge])

  return (
    <div>
      <div className={styles.profileCont}>
        <div className={styles.profileSec}>
          {isUserLoading ? <SpinnerWithText /> : user && <ProfileBox user={user} showPrim />}
        </div>

        <div className={styles.rightSec}>
          {isUserLoading ? <SpinnerWithText /> : user && <ProfileBox user={user} showSec />}
        </div>
      </div>

      <div className={styles.itemsCont}>
        <div className={styles.postTypeTabsWrp}>
          <button
            className={cn(styles.postTypeTabBtn, { [styles.postTypeTabBtnActive]: itemType === ItemType.Post })}
            onClick={() => setItemType(ItemType.Post)}
          >
            <Icons.Post className={styles.postTypeTabBtnIcon} /> Posts{' '}
            <strong style={{ fontWeight: 700 }}>{user?.postsCount}</strong>
          </button>

          <button
            className={cn(styles.postTypeTabBtn, { [styles.postTypeTabBtnActive]: itemType === ItemType.Blog })}
            onClick={() => setItemType(ItemType.Blog)}
          >
            <Icons.Article className={styles.postTypeTabBtnIcon} /> Blogs{' '}
            <strong style={{ fontWeight: 700 }}>{user?.blogsCount}</strong>
          </button>
        </div>

        <div className={styles.itemsWrp}>
          {itemType === ItemType.Post ? (
            <div className={styles.postsWrp}>
              <div className={styles.postsInnWrp}>
                <Posts items={posts} loading={postsLoading} />
              </div>
            </div>
          ) : (
            <Blogs items={blogs} loading={blogsLoading} />
          )}
        </div>
      </div>
    </div>
  )
}
