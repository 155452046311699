import SpinnerWithText from '../ui/spinner-with-text'
import Blog from '../blog'
import styles from './blogs.module.scss'

export default function Blogs({ items, loading }) {
  return (
    <div>
      <div className={styles.blogsWrp}>
        {items.map(blog => (
          <Blog key={blog._id} blog={blog} />
        ))}
      </div>

      <div className={styles.blogsFooter}>
        {loading ? <SpinnerWithText /> : !items.length && <div style={{ textAlign: 'center' }}>No Blogs Yet</div>}
      </div>
    </div>
  )
}
