import { useEffect } from 'react'
import { Link } from 'react-router-dom'
import cn from 'classnames'
import { getAddress } from '../../../../helpers/jsxHelper'
import { userRoles } from '../../../../util/enums/enums'
import { Routes } from '../../lib/consts'
import * as Icons from '../../assets/icons'
import { useOnline } from '../../contexts/online-ctx'
import ChatBtn from '../user/chat-btn'
import FollowBtn from './follow-btn'
import styles from './user-preview.module.scss'

export default function UserPreview({ post }) {
  const userAddr = getAddress(post.user.address)?.commonAddress

  const { getOnline, isOnline } = useOnline()

  useEffect(() => {
    getOnline(post.user._id)
  }, [post, getOnline])

  return (
    <>
      <div className={styles.userPreview}>
        <div className={styles.head}>
          <div className={styles.primDets}>
            <Link to={Routes.User(post.user._id)} className={styles.avatarWrp}>
              <img
                src={post.user.image?.url || require('../../assets/avatar-sample.png')}
                alt={post.user.firstName}
                className={styles.avatar}
              />
              <span className={cn(styles.statusDot, isOnline(post.user._id) && styles.onlineStatusActive)} />
            </Link>

            <div className={styles.detsWrp}>
              {post.user.isVerified && (
                <div className={styles.verifLabel}>
                  <Icons.Shield className={styles.icon} /> Verified
                </div>
              )}

              <div className={styles.postUserName}>{post.user.firstName}</div>

              {userAddr?.city && userAddr.country && (
                <div className={styles.userAddr}>
                  {userAddr.city}, {userAddr.country}
                </div>
              )}

              <div className={styles.actionBtns}>
                <ChatBtn user={post.user} className={cn(styles.actionBtn, styles.actionChatBtn)} />
                <FollowBtn userId={post.user._id} className={cn(styles.actionBtn, styles.actionFollowBtn)} />
              </div>
            </div>
          </div>

          <div className={styles.countsWrp}>
            <div className={styles.countWrp}>
              <div className={styles.count}>{post.user.followingsCount}</div>
              <div className={styles.countLabel}>Followings</div>
            </div>
            <div className={styles.countWrp}>
              <div className={styles.count}>{post.user.followersCount}</div>
              <div className={styles.countLabel}>Followers</div>
            </div>
            <div className={styles.countWrp}>
              <div className={styles.count}>{post.user.postsCount}</div>
              <div className={styles.countLabel}>Posts</div>
            </div>
          </div>
        </div>

        <div className={styles.body}>
          <div
            className={cn(styles.countsWrp, {
              [styles.countsWrp3Col]: [
                userRoles.BOAT_OWNER,
                userRoles.YACHT_SHIPPER,
                userRoles.SURVEYOR,
                userRoles.SERVICE_AND_MAINTENANCE,
                userRoles.AGENT,
              ].includes(post.user.role?.aliasName),
            })}
          >
            {[
              userRoles.BOAT_OWNER,
              userRoles.BROKER_AND_DEALER,
              userRoles.BOAT_MANUFACTURER,
              userRoles.RENT_AND_CHARTER,
              userRoles.MARINA_AND_STORAGE,
            ].includes(post.user.role?.aliasName) && (
              <div className={styles.countItemWrp}>
                <div className={styles.countWrp}>
                  <Icons.Boat className={styles.countIcon} />
                  <span className={styles.count}>{post.user.adsCount}</span>
                </div>
                <div className={styles.countLabel}>{post.user.adsCountLabel}</div>
              </div>
            )}

            {![userRoles.MEMBER, userRoles.BOAT_OWNER].includes(post.user.role?.aliasName) && (
              <div className={styles.countItemWrp}>
                <div className={styles.countWrp}>
                  <Icons.Review className={styles.countIcon} />
                  <span className={styles.count}>{post.user.reviewsCount}</span>
                </div>
                <div className={styles.countLabel}>Reviews</div>
              </div>
            )}

            <div className={styles.countItemWrp}>
              <div className={styles.countWrp}>
                <Icons.Article className={styles.countIcon} />
                <span className={styles.count}>{post.user.blogsCount}</span>
              </div>
              <div className={styles.countLabel}>Blogs</div>
            </div>

            <div className={styles.countItemWrp}>
              <div className={styles.countWrp}>
                <Icons.BoatShow className={styles.countIcon} />
                <span className={styles.count}>{post.user.boatShowsCount}</span>
              </div>
              <div className={styles.countLabel}>Boat Shows</div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
