import { SnackbarProvider } from 'notistack'
import cn from 'classnames'
import Sidebar from './sidebar'
import compStyles from '../../styles/components.module.scss'
import styles from './layout.module.scss'

export default function Layout({ children }) {
  return (
    <>
      <div className="__as_sm">
        <div className={cn(compStyles.container, styles.container)}>
          <Sidebar className={styles.sidebar} />
          <main className={styles.main}>{children}</main>
        </div>
      </div>

      <SnackbarProvider />
    </>
  )
}
