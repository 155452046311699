import { useCallback, useRef, useState } from 'react'
import { enqueueSnackbar } from 'notistack'
import { getComments as qGetComments } from '../../lib/queries'
import { useSMDb } from '../../contexts/smdb-ctx'

export default function SeeMoreCommentsBtn({ post, ...props }) {
  const { get: smdbGet, set: smdbSet } = useSMDb()

  const [loading, setLoading] = useState(false)

  const paginationRef = useRef({ page: 1, limit: 4 })

  const getComments = useCallback(
    async pagination => {
      setLoading(true)

      try {
        const res = await qGetComments(post._id, pagination)
        smdbSet(post._id, 'topComments', [...smdbGet(post._id, 'topComments', post.topComments), ...res.items])
        paginationRef.current.page = res.page
        paginationRef.current.page = res.limit
      } catch (er) {
        enqueueSnackbar(er)
      }

      setLoading(false)
    },
    [post, smdbGet, smdbSet]
  )

  return (
    <button
      {...props}
      onClick={e => {
        getComments({ page: paginationRef.current.page + 1, limit: paginationRef.current.limit })
        props.onClick?.(e)
      }}
      disabled={loading ?? props.disabled}
    >
      {props.children || 'See more comments'}
    </button>
  )
}
