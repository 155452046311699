import { useCallback, useEffect, useState } from 'react'
import { deletePost as qDeletePost } from '../../lib/queries'
import { enqueueSnackbar } from 'notistack'
import IconTrash from '../../assets/icons/trash'
import { useBodyScroll } from '../../contexts/body-scroll'
import { useSMDb } from '../../contexts/smdb-ctx'
import ConfirmPopup from '../overlays/confirm-popup'

export default function DeleteBtn({ post, keepPostActsDdOpen, ...props }) {
  const { enable: enableBodyScroll, disable: disableBodyScroll } = useBodyScroll()
  const { set: smdbSet } = useSMDb()

  const [confirmPopupOpen, setConfirmPopupOpen] = useState(false)
  const [deleteLoading, setDeleteLoading] = useState(false)

  const deletePost = useCallback(async () => {
    setDeleteLoading(true)

    try {
      await qDeletePost(post._id)
      smdbSet(post, 'deleted', true)
    } catch (err) {
      enqueueSnackbar(err)
    }

    setDeleteLoading(false)
  }, [post, smdbSet])

  useEffect(() => {
    keepPostActsDdOpen(confirmPopupOpen)
    confirmPopupOpen ? disableBodyScroll() : enableBodyScroll()
    return enableBodyScroll
  }, [confirmPopupOpen, keepPostActsDdOpen, enableBodyScroll, disableBodyScroll])

  return (
    <>
      <button
        {...props}
        onClick={e => {
          setConfirmPopupOpen(true)
          props.onClick?.(e)
        }}
      >
        {props.children || (
          <>
            <IconTrash /> Delete
          </>
        )}
      </button>

      {confirmPopupOpen && (
        <ConfirmPopup
          title="Confirm Delete"
          text="Are you sure you want to delete this post?"
          onConfirm={deletePost}
          disabled={deleteLoading}
          close={() => setConfirmPopupOpen(false)}
        />
      )}
    </>
  )
}
