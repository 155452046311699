import { createContext, useCallback, useContext, useEffect, useState } from 'react'

const VisiblePostCtx = createContext()

export const VisiblePostProvider = ({ children }) => {
  const [visiblePostId, setVisiblePostId] = useState(null)
  const [visiblePostIds, setVisiblePostIds] = useState(new Set())

  const onPostVisible = useCallback(id => {
    setVisiblePostIds(prev => new Set(prev).add(id))
  }, [])

  const onPostNotVisible = useCallback(id => {
    setVisiblePostIds(prev => {
      const newSet = new Set(prev)
      newSet.delete(id)
      return newSet
    })
  }, [])

  useEffect(() => {
    if (visiblePostIds.size === 0) {
      setVisiblePostId(null)
    } else {
      setVisiblePostId(visiblePostIds.values().next().value)
    }
  }, [visiblePostIds])

  return (
    <VisiblePostCtx.Provider value={{ visiblePostId, onPostVisible, onPostNotVisible }}>{children}</VisiblePostCtx.Provider>
  )
}

export const usePostVisible = () => useContext(VisiblePostCtx)
