import { viewArticles } from '../../../../helpers/boatHelper'
import * as Icons from '../../assets/icons'
import SharePopover from './share-popover'
import styles from './blog.module.scss'

export default function Blog({ blog }) {
  return (
    <>
      <div className={styles.blog}>
        <div className={styles.blogCoverWrp}>
          <img src={blog.files?.[0]?.largeUrl} alt={blog.title} className={styles.blogCover} />
        </div>
        <div className={styles.blogTextWrp}>
          <a target="_blank" href={viewArticles(blog._id, blog.title, true)} className={styles.blogLinkOverlay}>
            <h3 className={styles.blogTitle}>{blog.title}</h3>
          </a>
          <div className={styles.blogDetsWrp}>
            <div className={styles.blogDets}>
              <div className={styles.blogViews}>{blog.viewsCount} views</div>
              <div className={styles.blogLikes}>
                <Icons.Heart /> {blog.likesCount}
              </div>
            </div>
            <div className={styles.blogShareCont}>
              <button className={styles.blogShareBtn}>
                <Icons.Share />
              </button>
              <div className={styles.blogSharePopover}>
                <SharePopover blog={blog} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
