import { useCallback, useState } from 'react'
import { enqueueSnackbar } from 'notistack'
import IconSave from '../../assets/icons/save'
import IconSaveSolid from '../../assets/icons/save-solid'
import { savePost as qSavePost } from '../../lib/queries'
import { useSMDb } from '../../contexts/smdb-ctx'
import { useAuth } from '../../contexts/auth-ctx'
import LoginModal from '../overlays/login-modal'

export default function SaveBtn({ postId, ...props }) {
  const { get: smdbGet, set: smdbSet } = useSMDb()
  const auth = useAuth()

  const [isLoginModalOpen, setIsLoginModalOpen] = useState(false)
  const [isSaveLoading, setIsSaveLoading] = useState(false)

  const savePost = useCallback(async () => {
    setIsSaveLoading(true)

    try {
      const res = await qSavePost(postId)
      smdbSet(postId, 'saved', res.saved)
    } catch (er) {
      enqueueSnackbar(er)
    }

    setIsSaveLoading(false)
  }, [postId, smdbSet])

  return (
    <>
      <button
        {...props}
        onClick={async e => {
          props.onClick?.(e)
          auth.isLoggedIn ? savePost() : setIsLoginModalOpen(true)
        }}
        disabled={props.disabled || isSaveLoading}
      >
        {props.children || (smdbGet(postId, 'saved') ? <IconSaveSolid /> : <IconSave />)}
      </button>

      {isLoginModalOpen && <LoginModal close={() => setIsLoginModalOpen(false)} />}
    </>
  )
}
