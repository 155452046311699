import { useMemo, useState } from 'react'
import { FacebookShareButton, LinkedinShareButton, TwitterShareButton } from 'react-share'
import { envConfig } from '../../../../config'
import { viewArticles } from '../../../../helpers/boatHelper'
import { useAuth } from '../../contexts/auth-ctx'
import LoginModal from '../overlays/login-modal'
import ASShareModal from './as-share-modal'
import styles from './share-popover.module.scss'

export default function SharePopover({ blog }) {
  const url = useMemo(() => viewArticles(blog._id, blog.title, true), [blog])

  const auth = useAuth()

  const [isLoginModalOpen, setIsLoginModalOpen] = useState(false)
  const [isASShareModalOpen, setIsASShareModalOpen] = useState(false)

  return (
    <>
      <div className={styles.popover}>
        <button
          className={styles.shareBtn}
          onClick={() => (auth.isLoggedIn ? setIsASShareModalOpen(true) : setIsLoginModalOpen(true))}
        >
          <img src={require('../../../../assets/images/adamshare-grey.png')} alt="AS Share" className={styles.shareBtnIcon} />
        </button>

        <FacebookShareButton url={url} className={styles.shareBtn}>
          <img src={require('../../../../assets/images/facebook-grey.png')} alt="Facebook" className={styles.shareBtnIcon} />
        </FacebookShareButton>

        <TwitterShareButton url={url} className={styles.shareBtn}>
          <img src={require('../../../../assets/images/twitter-grey.png')} alt="Twitter" className={styles.shareBtnIcon} />
        </TwitterShareButton>

        <LinkedinShareButton url={url} className={styles.shareBtn}>
          <img src={require('../../../../assets/images/linkedin-grey.png')} alt="LinkedIn" className={styles.shareBtnIcon} />
        </LinkedinShareButton>

        <button
          className={styles.shareBtn}
          onClick={() => {
            window.open(
              `https://www.facebook.com/dialog/send?app_id=${envConfig.RAZZLE_FACEBOOK_DEV_APPID}&link=${url}&redirect_uri=${url}`,
              'Messenger',
              'popup'
            )
          }}
        >
          <img src={require('../../../../assets/images/messenger-grey.png')} alt="Messenger" className={styles.shareBtnIcon} />
        </button>
      </div>

      <ASShareModal blog={blog} isOpen={isASShareModalOpen} close={() => setIsASShareModalOpen(false)} />
      {isLoginModalOpen && <LoginModal close={() => setIsLoginModalOpen(false)} />}
    </>
  )
}
