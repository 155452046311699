import { createContext, useCallback, useContext, useEffect, useState } from 'react'

const BodyScrollCtx = createContext()

export const BodyScrollProvider = ({ children }) => {
  const [disabled, setDisabled] = useState(0)

  const enable = useCallback(() => setDisabled(prev => Math.max(prev - 1, 0)), [])
  const disable = useCallback(() => setDisabled(prev => prev + 1), [])

  useEffect(() => {
    document.body.style.overflow = disabled ? 'hidden' : 'auto'
  }, [disabled])

  return <BodyScrollCtx.Provider value={{ enable, disable }}>{children}</BodyScrollCtx.Provider>
}

export const useBodyScroll = () => useContext(BodyScrollCtx)
