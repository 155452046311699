import { useEffect, useState } from 'react'
import IconReport from '../../assets/icons/report'
import { useBodyScroll } from '../../contexts/body-scroll'
import { useAuth } from '../../contexts/auth-ctx'
import ReportPopup from './report-popup'
import LoginModal from '../overlays/login-modal'

export default function ReportBtn({ post, keepPostActsDdOpen, ...props }) {
  const { enable: enableBodyScroll, disable: disableBodyScroll } = useBodyScroll()
  const auth = useAuth()

  const [reportPopupOpen, setReportPopupOpen] = useState(false)
  const [loginModalOpen, setLoginModalOpen] = useState(false)

  useEffect(() => {
    keepPostActsDdOpen(reportPopupOpen)
    reportPopupOpen ? disableBodyScroll() : enableBodyScroll()
    return enableBodyScroll
  }, [reportPopupOpen, keepPostActsDdOpen, enableBodyScroll, disableBodyScroll])

  return (
    <>
      <button
        {...props}
        onClick={e => {
          auth.isLoggedIn ? setReportPopupOpen(true) : setLoginModalOpen(true)
          props.onClick?.(e)
        }}
      >
        {props.children || (
          <>
            <IconReport /> Report
          </>
        )}
      </button>

      {reportPopupOpen && <ReportPopup post={post} close={() => setReportPopupOpen(false)} />}
      {loginModalOpen && <LoginModal close={() => setLoginModalOpen(false)} />}
    </>
  )
}
