import React, { useState, useEffect } from 'react'
import ImageUploader from 'react-images-upload'
import CloseIcon from '@material-ui/icons/Close'
import { Card, Spinner } from 'react-bootstrap'
import { Button, Box, FormControlLabel, Checkbox, FormControl, RadioGroup, FormLabel, Radio, Switch } from '@material-ui/core'
import PhoneInput from 'react-phone-input-2'
import Datetime from 'react-datetime'
import CalendarTodayIcon from '@material-ui/icons/CalendarToday'
import moment from 'moment-timezone'
import ReactCrop from 'react-image-crop'
import 'react-image-crop/dist/ReactCrop.css'

import {
  handleSingleFileUpload,
  handleSingleFileDelete,
  uploadFile,
  getCroppedImg,
  getUniqueFiles,
} from '../../helpers/s3FileUpload'

import 'react-phone-input-2/lib/style.css'
import {
  imgExtension,
  dateFormat,
  timeFormat,
  MEDIA_TYPE,
  META_TYPE,
  docExtension,
  videoExtension,
  MEDIA_NAME,
} from '../../util/enums/enums'
import SearchComplete from '../map/SearchComplete'
import { isValidUrl, renderSelectOptions } from '../../helpers/string'
import { Loader } from '../loader/loader'
import { getVerifiedCheck, downloadIcon, checkFileType, getVideoType } from '../../helpers/jsxHelper'
import { HelpIconComponent } from '../helper/helpIconComponent'
import { getLocalStorageItem } from '../../helpers/storageHelper'
import { mobileNumberFormat } from '../../helpers/validationHelper'

import './DocumentUploader.scss'
import { ButtonComponent } from '../form/Button'
import { CommonModal } from '../modal/commonModal'
import { CommonTooltip } from '../CommonTooltip'
import SingleSelect from '../helper/singleSelect'
import { confirmSubmitHandler } from '../../helpers/confirmationPopup'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import classNames from 'classnames'

export const Field = props => {
  const {
    required,
    radioNotRequired,
    bold,
    id,
    label,
    noVerifyRejected,
    verify,
    rejected,
    type,
    labelContent,
    placeholder,
    name,
    onChangeText,
    setIsUSCountry,
    disabled,
    restrictCountry,
    buttonText,
    justifyLeft,
    isNotCircle,
    options,
    flex,
    labelClass,
    values,
    isDateFormatFalse,
    isTimeFormatFalse,
    isUpdate,
    onChange,
    value,
    isValidDate,
    bigSize,
    helpText,
    isUtc,
    numberClassName,
    isCustomRadio,
    rows,
    onlyImageEnabled,
    cropBeforeUpload,
    setFieldValue,
    deleteIds,
    initValue,
    labelColor,
    className,
    labelIcon,
    addMedia,
    mediaName,
    getDocumentUrl,
    errors,
    documentTypes,
    isNotLookup,
    disableButton,
    getDocumentType,
    otherDoc,
    checked,
    switchEnabledText,
    switchDisabledText,
    limit,
    isLoading,
    noDateFormat,
    onKeyDown,
    onKeyUp,
    onBlur,
    profileDocument,
    styles,
    highlightDates,
    noFormControl = false,
    maxImageSize,
    timezoneName
  } = props

  const initialAttachmentDocument =
    type === 'single-document' && value?.[0]?.documentType
      ? { label: value[0].documentType?.alias, value: value[0].documentType?.id }
      : null

  const [loading, setLoading] = useState(false)
  const [documentDataUpdate, setDocumentDataUpdateValue] = useState(false)
  const [videoTypeError, setVideoTypeError] = useState(false)
  const [videoName, setVideoName] = useState('')
  const [attachmentDocument, setAttachmentDocument] = useState(initialAttachmentDocument)

  const count = attachmentDocument?.label === 'Official driving licence' ? META_TYPE : [attachmentDocument?.label]

  const onlyImageUploadInDocumentFields = ['governmentId']
  const hideDocumentUploadButton = [
    'governmentId',
    'commercialLicence',
    'boatOwnershipId',
    'boatVerifications',
    'otherDocument',
    'report',
    'coverLetter',
    'cvUrl',
  ]

  const renderHelpIcon = () => helpText && <HelpIconComponent helpText={helpText} containerClass="single-select--help" />

  // Multiple Image Upload & Delete
  const [imageFiles, setImageFiles] = useState({})

  const handleMultipleFileUpload = async (
    file,
    name,
    value = [],
    setFieldValue,
    mediaType = MEDIA_TYPE.IMAGE,
    metaType = null
  ) => {
    setLoading(true)

    try {
      const { uniqueFiles, fileToUpload } = getUniqueFiles(file, imageFiles[name])

      imageFiles[name] = uniqueFiles

      if (fileToUpload && fileToUpload.length) {
        const res = await uploadFile(fileToUpload, name, mediaType, true, { maxImageSize })
        if (res) {
          const meta = META_TYPE.includes(metaType) ? { type: metaType } : null

          res?.length && setFieldValue([name], [...value, ...res.map(({ url, name, key }) => ({ url, name, key, meta }))])
          res?.length &&
            setFieldValue(`addMedia.${name}`, [
              ...addMedia[name],
              ...res.map(item => ({
                ...item,
                mediaName,
                mediaType,
                documentType: onlyImageUploadInDocumentFields.includes(name) ? attachmentDocument?.value : null,
                meta,
              })),
            ])

          setImageFiles(imageFiles)
        }
      }
    } catch (err) {
      console.log(err)
    }

    setLoading(false)
  }

  const handleMultipleFileDelete = (name, img, setFieldValue, values) => {
    setLoading(true)

    try {
      img.id && setFieldValue('removeMedia', [...deleteIds, img.id])

      setFieldValue([name], img?.id ? values.filter(item => item.id !== img.id) : values.filter(item => item.name !== img.name))

      setFieldValue(
        `addMedia.${name}`,
        addMedia[name]?.filter(item => item.url !== img.url)
      )

      imageFiles[name] = imageFiles[name]?.filter(name => name !== img.name) || []
      setImageFiles(imageFiles)
    } catch (err) {
      console.log(err)
    }

    setLoading(false)
  }

  const deleteAfterConfirmation = ({ name, singleDocument, onChangeText, value }) => {
    !documentDataUpdate && setDocumentDataUpdateValue(true)
    handleMultipleFileDelete(name, singleDocument, onChangeText, value)
  }

  // Imag Cropping
  const [imageToCrop, setImageToCrop] = useState(null)
  const [imageCropPopup, setImageCropPopup] = useState(false)
  const [croppedImageUrl, setCroppedImageUrl] = useState(null)
  const [croppedImageUrlObj, setCroppedImageUrlObject] = useState(null)
  const [imageRef, setImageRef] = useState(null)
  const [crop, setCrop] = useState({
    unit: '%',
    width: 120,
    aspect: 16 / 16,
  })

  const clearCrop = () => {
    setImageToCrop(null)
    setCroppedImageUrl(null)
    setImageRef(null)
    setCroppedImageUrlObject(null)
    setImageCropPopup(false)
  }

  const onSelectFile = files => {
    if (files && files.length > 0) {
      const reader = new FileReader()
      reader.addEventListener('load', () => {
        setImageToCrop(reader.result)
        setImageCropPopup(true)
      })
      reader.readAsDataURL(files[files.length - 1])
    }
  }

  const onCropComplete = async crop => {
    if (imageRef && crop.width && crop.height) {
      const croppedImageUrl = await getCroppedImg(imageRef, crop, 'newFile.jpeg', setCroppedImageUrlObject)
      setCroppedImageUrl(croppedImageUrl)
    }
  }

  const errorMessageMethod = (file, name) => {
    if (file && file.length === 0 && name) {
      const documentElement = document.querySelector(`.invalid--formate--error-${name}`)
      const errorElement = documentElement.querySelector('.errorMessage')
      if (errorElement) {
        const errorElementMsg = documentElement.querySelector(`.error-${name}`)
        errorElementMsg.innerHTML = errorElement.innerHTML
      }
    } else {
      if (name) {
        const errorElementMsgNone = document.querySelector(`.error-${name}`)
        errorElementMsgNone.innerHTML = ''
      }
    }
  }

  useEffect(() => {
    imageRef && onCropComplete(crop)
  }, [imageRef])

  const onDragEnd = result => {
    const { draggableId, source, destination } = result

    if (!destination) {
      return
    }

    if (source.droppableId === destination.droppableId && source.index == destination.index) {
      return
    }

    const [removed] = values.splice(source.index, 1)
    values.splice(destination.index, 0, removed)
  }

  const getExtension = () => {
    let fileExt = value.name.split('.') || ['']

    switch (`.${fileExt[fileExt.length - 1]}`) {
      case '.docx':
        return require('../../assets/images/salesEngine/docx.svg')

      case '.doc':
        return require('../../assets/images/salesEngine/doc.svg')

      case '.pdf':
        return require('../../assets/images/salesEngine/pdf.svg')

      case '.txt':
        return require('../../assets/images/salesEngine/txt.svg')

      case '.xlsx':
        return require('../../assets/images/salesEngine/xlsx.svg')

      default:
        return
    }
  }

  const renderField = () => {
    switch (type) {
      case 'text':
        return (
          <>
            {label && (
              <label className={required && `required ${bold && 'bold-label'} ${verify && 'value--verify'}`} htmlFor={id}>
                <span className="help--icon--position--lb-tool">
                  {label}
                  {renderHelpIcon()}
                </span>
              </label>
            )}
            <input
              type="text"
              className="form-control"
              placeholder={placeholder}
              id={id}
              name={name}
              onChange={onChangeText}
              value={value}
              disabled={disabled}
              onKeyDown={onKeyDown}
              onKeyUp={onKeyUp}
              onBlur={onBlur}
              {...(limit && { maxLength: limit })}
              style={styles}
            ></input>
          </>
        )

      case 'password':
        return (
          <>
            {label && (
              <label className={required && `required ${bold && 'bold-label'} ${verify && 'value--verify'}`} htmlFor={id}>
                <span className="help--icon--position--lb-tool">
                  {label}
                  {renderHelpIcon()}
                </span>
              </label>
            )}
            <input
              type="password"
              className="form-control"
              placeholder={placeholder}
              id={id}
              name={name}
              onChange={onChangeText}
              value={value}
              disabled={disabled}
              autocomplete="current-password"
            ></input>
          </>
        )

      case 'autocomplete':
        return (
          <div>
            {label && (
              <label className={required && 'required'} htmlFor={id}>
                {label}
              </label>
            )}
            <SearchComplete
              className="form-control font-14"
              id={id}
              name={name}
              placeHolder={value}
              getPlaceName={onChangeText}
              restrictCountry={restrictCountry ? { country: restrictCountry } : {}}
              types={'(cities)'}
              onBlur={onBlur}
            />
          </div>
        )

      case 'textarea':
        return (
          <div style={styles}>
            {label && (
              <label className={required && 'required'} htmlFor={id}>
                <span className="help--icon--position--lb-tool">
                  {label}
                  {renderHelpIcon()}
                </span>
              </label>
            )}

            <textarea
              className="form-control"
              placeholder={placeholder}
              id={id}
              name={name}
              onChange={onChangeText}
              value={value}
              disabled={disabled}
              rows={rows}
              onBlur={onBlur}
              onKeyDown={onKeyDown}
            />
          </div>
        )

      case 'number':
        return (
          <>
            {label && (
              <label className={required && `mr-1 required number--field-lb ${labelClass} ${bold && 'mr-1 bold-label '}`}>
                <span className="help--icon--position--lb-tool">
                  {label}
                  {renderHelpIcon()}
                </span>
              </label>
            )}

            <input
              type="number"
              className={`form-control ${numberClassName}`}
              placeholder={placeholder}
              id={id}
              name={name}
              onChange={onChangeText}
              value={value}
              disabled={disabled}
              onKeyDown={e => (e.key === 'e' && e.preventDefault()) || (e.key === 'E' && e.preventDefault())}
            ></input>
          </>
        )

      case 'mobile-number':
        return (
          <div className="phone--padding">
            {label && (
              <div className="d-flex mb-2 align-items-center">
                <div className="mr-2 verfied--icon--spacing">
                  <label className={`${required ? 'required' : ''} ${verify ? 'value--verify' : ''}`} htmlFor={id}>
                    <span className="help--icon--position--lb-tool">
                      {label}
                      {renderHelpIcon()}
                    </span>{' '}
                  </label>
                </div>
                {verify && <div className={`verified--img ${helpText ? 'ml-3' : ''}`}>{getVerifiedCheck()}</div>}
                {labelContent}
              </div>
            )}
            <PhoneInput
              className={noFormControl ? 'w-100' : 'form-control w-100'}
              inputClass="w-100"
              placeholder={placeholder}
              id={id}
              name={name}
              onMount={(_, data) => {
                setIsUSCountry && setIsUSCountry(data.countryCode === 'us')
              }}
              onChange={(value, data) => {
                onChangeText(mobileNumberFormat(value))
                setIsUSCountry && setIsUSCountry(data.countryCode === 'us')
              }}
              value={value}
              disabled={disabled}
              disableDropdown
              alwaysDefaultMask
              country={getLocalStorageItem('countryCode') ? getLocalStorageItem('countryCode').toLowerCase() : ''}
              inputProps={{
                name: name,
              }}
            />
          </div>
        )

      case 'url':
        return (
          <div>
            {label && (
              <label className={required && 'required'} htmlFor={id}>
                {label}
              </label>
            )}
            <input
              type="url"
              className="form-control"
              placeholder={placeholder}
              id={id}
              name={name}
              onChange={onChangeText}
              value={value}
              disabled={disabled}
              onBlur={onBlur}
            ></input>
          </div>
        )

      case 'single-document':
        return (
          <div className="single--document--content">
            {label && (
              <Box fontSize={16} fontWeight={700} className="text-center mb-3 font-sm-label w-10">
                <span className={`help--icon--position--lb-tool ${required && 'required'}`}>
                  {label}
                  {verify && !otherDoc && <div className="verified--img">{!documentDataUpdate && getVerifiedCheck()}</div>}
                  {renderHelpIcon()}
                </span>
              </Box>
            )}
            {!noVerifyRejected && !verify && !rejected && value?.length > 0 && <div className="wait-for-approvel alert-info">Wait for approval</div>}
            {!noVerifyRejected && !!rejected && value?.length > 0 && <div className="wait-for-approvel alert-danger">Provide valid {mediaName === MEDIA_NAME.USERCOMMERCIALLICENCE ? 'License' : 'ID'}</div>}

            {onlyImageUploadInDocumentFields.includes(name) && !value.length && (
              <SingleSelect
                id={'attachmentDocument'}
                name={'attachmentDocument'}
                placeholder="I'm attaching"
                selectedOption={attachmentDocument}
                options={renderSelectOptions(documentTypes, 'alias', 'id')}
                onChange={value => {
                  setAttachmentDocument(value)
                  getDocumentType(value?.label === 'Official driving licence')
                }}
                disabled={value?.length}
              />
            )}

            {(!onlyImageUploadInDocumentFields.includes(name) || attachmentDocument?.value) &&
              (!hideDocumentUploadButton.includes(name) ||
                attachmentDocument?.label === 'Official driving licence' ||
                !value?.length) && (
                <div className={`single--document--${name}`}>
                  {count.map(
                    item =>
                      (attachmentDocument?.label !== 'Official driving licence' ||
                        !value
                          .map(({ meta }) => meta?.type)
                          .filter(Boolean)
                          .includes(item)) && (
                        <div
                          className={`single--document--uploader dashboard--form--document--uploader  invalid--formate--error-${name}`}
                        >
                          <img loading="lazy" src={require('./Images/placeholder.png')} alt={label} className="square-img" />
                          {!loading && (
                            <ImageUploader
                              name={name}
                              withIcon={false}
                              withLabel={false}
                              buttonText=""
                              fileTypeError="Please select valid format for document"
                              singleImage
                              // errorStyle={{ display: 'none' }}
                              accept={onlyImageEnabled ? imgExtension : [...docExtension, ...imgExtension]}
                              imgExtension={onlyImageEnabled ? imgExtension : [...docExtension, ...imgExtension]}
                              buttonStyles={{
                                'background-image': `url(${require('./Images/document.png')})`,
                              }}
                              onChange={files => {
                                const getImage = imgExtension.includes(
                                  `.${files?.length > 0 && files[0].name.split('.').pop()}`
                                )
                                errorMessageMethod(files, name)
                                if (
                                  (count.length === 2 &&
                                    value.length < count.length &&
                                    !value
                                      .map(({ meta }) => meta?.type)
                                      .filter(Boolean)
                                      .includes(item)) ||
                                  count.length === 1
                                ) {
                                  setDocumentDataUpdateValue(true)
                                  handleMultipleFileUpload(
                                    files,
                                    name,
                                    value,
                                    onChangeText,
                                    getImage ? MEDIA_TYPE.IMAGE : MEDIA_TYPE.DOCUMENT,
                                    item
                                  )
                                }
                              }}
                              maxFileSize={5242880}
                            />
                          )}

                          <span className="text--transfer">{item}</span>

                          <span className={`${name}--loading default--loading document--default--loading`}>
                            <Loader isSmall />
                          </span>
                          <span className={`error-text-upload error-${name}`}></span>
                        </div>
                      )
                  )}
                </div>
              )}
            <div className="single--document--value-list">
              {value &&
                value.map((singleDocument, i) => (
                  <>
                    <div
                      className={`${
                        profileDocument ? 'profile--single--document--value' : ''
                      } single--document--value d-flex align-items-center justify-content-center mt-2`}
                      key={singleDocument.url}
                    >
                      {singleDocument.id ? (
                        profileDocument ? (
                          <div className="ml-1 single--document-commercial d-flex align-items-center document--uploaded--div">
                            <div className="document--default--image">
                              {imgExtension.includes(`.${singleDocument?.name?.split('.').pop()}`) ? (
                                <img loading="lazy" src={require('../../assets/images/profile/image.svg')} alt='Profile'/>
                              ) : (
                                <img loading="lazy" src={require('../../assets/images/profile/docs.svg')} alt='Profile'/>
                              )}
                            </div>
                            {imgExtension.includes(`.${singleDocument?.name?.split('.').pop()}`) ? (
                              <CommonTooltip component={ctProps => (
                                <a data-tooltip-id={ctProps.id} data-tooltip-content="Open Image" href={singleDocument.url} target="_blank" className="profile--document--image--link">
                                  <p>{`${singleDocument.name ? singleDocument.name : buttonText}`}</p>
                                </a>
                              )} />
                            ) : (
                              <div className="other--document--div">
                                <p>{`${singleDocument.name ? singleDocument.name : buttonText}`}</p>
                              </div>
                            )}
                            {imgExtension.includes(`.${singleDocument?.name?.split('.').pop()}`) ? (
                              <></>
                            ) : (
                              downloadIcon(singleDocument.id, getDocumentUrl, 'document--download--icon')
                            )}
                          </div>
                        ) : (
                          <div className="ml-1 single--document-commercial">
                            {`${singleDocument.documentType ? singleDocument.documentType.alias : buttonText}`}
                            {`(${singleDocument.meta ? singleDocument.meta.type : i + 1})`}
                            {downloadIcon(singleDocument.id, getDocumentUrl)}
                          </div>
                        )
                      ) : (
                        <span className="single--uploaded--document--name">
                          {imgExtension.includes(`.${singleDocument.name.split('.').pop()}`) ? (
                            <img loading="lazy" src={singleDocument.url} alt={label} className={isNotCircle && 'square-img'} />
                          ) : (
                            singleDocument.name
                          )}
                        </span>
                      )}
                      <CloseIcon
                        className={`${
                          profileDocument ? 'single--document--close--icon' : ''
                        } remove-doc cursor-pointer close--icon--remove--img`}
                        onClick={() => {
                          confirmSubmitHandler(
                            deleteAfterConfirmation,
                            { name, singleDocument, onChangeText, value },
                            'Delete Document Alert'
                          )
                        }}
                      />
                    </div>
                  </>
                ))}
            </div>
            {errors && <span className="error-message">{errors[name]}</span>}
          </div>
        )

      case 'single-image':
        return (
          <>
            <div className={`single--image--div invalid--formate--error-${name}`}>
              {label && (
                <div className="d-flex mb-2 align-items-center">
                  <div className="mr-2 verfied--icon--spacing">
                    <label className={`${required ? 'required' : ''} ${verify ? 'value--verify mb-0' : 'mb-0'}`} htmlFor={id}>
                      <span className="help--icon--position--lb-tool">
                        {label}
                        {renderHelpIcon()}
                      </span>
                    </label>
                  </div>
                  {verify && <div className="verified--img">{value && value === initValue && getVerifiedCheck()}</div>}
                </div>
              )}
              <div
                className={`${
                  justifyLeft ? 'justify-content-left' : 'justify-content-center'
                }   d-flex flex-wrap single--upload--img single-upload-image-profile`}
              >
                {value?.url ? (
                  <div
                    className={`${
                      bigSize ? 'big--size--img' : 'small--size--img'
                    } addBoat-container mr-2 mb-2 upload-img-circle dashboard--form--img--uploader`}
                  >
                    <img loading="lazy" src={value.url} alt={label} className={isNotCircle && 'square-img'} />
                    <span onClick={() => handleSingleFileDelete(value, name, onChangeText, addMedia, deleteIds)}>x</span>
                  </div>
                ) : (
                  <>
                    <div className={`addBoatShow-imgUploader upload-img-circle single-image dashboard--form--img--uploader `}>
                      {croppedImageUrl ? (
                        <img loading="lazy" alt="Crop" style={{ maxWidth: '100%' }} src={croppedImageUrl} />
                      ) : (
                        <img loading="lazy" src={require('./Images/placeholder.png')} alt={label} className={isNotCircle && 'square-img'} />
                      )}
                      <ImageUploader
                        name={name}
                        withIcon={false}
                        withLabel={false}
                        accept={imgExtension}
                        buttonText={''}
                        fileTypeError="Please select valid format for image"
                        imgExtension={imgExtension}
                        buttonStyles={{
                          'background-image': `url(${require('./Images/camera.png')})`,
                        }}
                        style={{ overflow: 'hidden' }}
                        onChange={file => {
                          errorMessageMethod(file, name)
                          cropBeforeUpload
                            ? onSelectFile(file)
                            : handleSingleFileUpload(file, name, onChangeText, addMedia, mediaName, undefined, undefined, { maxImageSize })
                        }}
                        maxFileSize={5242880}
                      />
                    </div>
                  </>
                )}
                <span className={`${name}--loading default--loading`}>
                  <Loader />
                </span>
              </div>
              <span className={`error-text-upload error-${name}`}></span>
            </div>
            <CommonModal className="crop--image--modal" open={imageCropPopup} close={clearCrop}>
              <CommonTooltip component={ctProps => (
                <img
                  data-tooltip-id={ctProps.id}
                  data-tooltip-content="Close"
                  src={require('../../assets/images/boatInner/close.svg')}
                  alt="Close"
                  onClick={clearCrop}
                  className="crop--image--modal-close"
                />
              )} />
              {imageToCrop && (
                <>
                  <ReactCrop
                    src={imageToCrop}
                    crop={crop}
                    circularCrop
                    ruleOfThirds
                    onImageLoaded={image => setImageRef(image)}
                    onComplete={onCropComplete}
                    onChange={crop => setCrop(crop)}
                  />
                  <div className="crop--img-footer">
                    <ButtonComponent
                      disabled={!croppedImageUrlObj}
                      onClick={async () => {
                        handleSingleFileUpload([croppedImageUrlObj], name, onChangeText, addMedia, mediaName, undefined, undefined, { maxImageSize })
                        clearCrop()
                      }}
                    >
                      {`Change ${name === 'image' ? 'Profile' : name === 'companyLogo' ? 'Logo' : 'Picture'}`}
                    </ButtonComponent>
                    <ButtonComponent color="cancel" onClick={clearCrop}>
                      Cancel
                    </ButtonComponent>
                  </div>
                </>
              )}
            </CommonModal>
          </>
        )

      case 'shipment-document':
        return (
          <>
            {label && (
              <label className={required && 'required'} htmlFor={id}>
                {label}
              </label>
            )}

            <div className="d-flex flex-wrap justify-content-center single-upload-image-profile">
              {value?.id ? (
                <Box>
                  {label}
                  <CloseIcon
                    className="remove-doc cursor-pointer"
                    onClick={() => handleSingleFileDelete(value, name, onChangeText, addMedia, deleteIds)}
                  />
                  {downloadIcon(value.id, getDocumentUrl)}
                </Box>
              ) : value?.url ? (
                <span>
                  {imgExtension.some(word => value?.name.includes(word)) ? (
                    <img loading="lazy" style={{ height: 50, width: 50 }} src={value.url} alt='Close'/>
                  ) : (
                    value.name
                  )}
                  <CloseIcon
                    className="remove-doc cursor-pointer"
                    onClick={() => handleSingleFileDelete(value, name, onChangeText, addMedia, deleteIds)}
                  />
                </span>
              ) : (
                <div className={`invalid--formate-shipment-doc invalid--formate--error-${name}`}>
                  <div
                    className={`addBoatShow-imgUploader upload-img-circle single-image dashboard--form--document--uploader shipment--document--uploader`}
                  >
                    <img loading="lazy" src={require('./Images/placeholder.png')} alt={label} className="shipment-document-uploader" />
                    <ImageUploader
                      name={name}
                      withIcon={false}
                      withLabel={false}
                      buttonClassName="file-upload-btn"
                      buttonText={''}
                      buttonStyles={{
                        'background-image': `url(${require('./Images/file-upload.png')})`,
                        height: '23px',
                        width: '17px',
                        'border-radius': '0',
                      }}
                      accept={[...docExtension, ...imgExtension]}
                      imgExtension={[...docExtension, ...imgExtension]}
                      onChange={file => {
                        errorMessageMethod(file, name)
                        setLoading(true)
                        handleSingleFileUpload(file, name, onChangeText, addMedia, mediaName, MEDIA_TYPE.DOCUMENT, setLoading, { maxImageSize })
                      }}
                      maxFileSize={5242880}
                    />
                  </div>
                  {loading && (
                    <div>
                      <Loader isSmall />
                    </div>
                  )}
                  <span className={`error-text-upload error-${name}`}></span>
                </div>
              )}
            </div>
          </>
        )

      case 'email':
        return (
          <div>
            {label && (
              <div className="d-flex mb-2 align-items-center">
                <div className="mr-2 verfied--icon--spacing">
                  <label className={`${required ? 'required' : ''} ${verify ? 'value--verify' : ''}`} htmlFor={id}>
                    {label}
                  </label>
                </div>
                {verify && <div className="verified--img">{getVerifiedCheck()}</div>}
                {labelContent}
              </div>
            )}
            <input
              type="email"
              className="form-control"
              placeholder={placeholder}
              id={id}
              name={name}
              onChange={onChangeText}
              value={value}
              disabled={disabled}
            ></input>
          </div>
        )

      case 'video':
        return (
          <div className="video-upload-content">
            {label && (
              <label className={required && 'required'} htmlFor={id}>
                {label}
              </label>
            )}
            <div className="video-upload-main-wrapper">
              {value ? (
                <>
                  <Box className="position-relative d-flex align-items-center">
                    <div className="d-flex align-items-center flex-column">
                      {videoName || 'Uploaded Video'}
                      {downloadIcon(value.id, getDocumentUrl)}
                    </div>
                    <CloseIcon
                      className="remove-doc cursor-pointer close--icon--remove--img"
                      onClick={() => {
                        setLoading(false)
                        handleSingleFileDelete(value, name, onChangeText, addMedia, deleteIds)
                      }}
                    />
                  </Box>
                </>
              ) : (
                <>
                  <Button variant="contained" className="video-loader-field" component="label">
                    {loading ? <Spinner animation="border" /> : 'Upload File'}
                    <input
                      id={id}
                      name={name}
                      value={value}
                      type="file"
                      accept="video/*"
                      onChange={async e => {
                        const video = e.target.files[0]
                        const type = getVideoType(video.type)
                        if (videoExtension.includes(type)) {
                          setLoading(true)
                          disableButton()
                          setVideoName(video.name)
                          await handleSingleFileUpload(
                            e.target.files,
                            name,
                            onChangeText,
                            addMedia,
                            mediaName,
                            MEDIA_TYPE.VIDEO,
                            setLoading,
                            { maxImageSize }
                          )
                          disableButton()
                        } else {
                          setVideoTypeError(true)
                        }
                      }}
                      style={{ display: 'none' }}
                    />
                  </Button>
                  {videoTypeError && 'Invalid URL'}
                </>
              )}
              {errors && <span className="error-message mr-2">{errors[name]}</span>}
            </div>
          </div>
        )

      case 'radio':
        return (
          <div className="radio--label-fix">
            {label && (
              <label className={required && `required ${bold && 'bold-label'} ${verify && 'value--verify'}`} htmlFor={id}>
                <span className="help--icon--position--lb-tool">
                  {label}
                  {renderHelpIcon()}
                </span>
              </label>
            )}
            <div>
              <Card
                className={flex ? `d-flex flex-row justify-content-evenly profile-radio-container profile-title-div` : 'pl-8'}
                style={styles}
              >
                {options &&
                  options.length > 0 &&
                  options.map(item => (
                    <div className="form-check" key={item.id}>
                      {item.image && <img loading="lazy" src={item.image} style={{ height: 50, width: 50 }} alt="Form"></img>}
                      <input
                        checked={value === item.id}
                        className="form-check-input"
                        type="radio"
                        name={name}
                        id={item.id}
                        value={item.id}
                        onChange={onChangeText}
                      />
                      {isCustomRadio && <span class="check--mark"></span>}
                      <label className="form-check-label" htmlFor={item.id}>
                        {item.value}
                      </label>
                    </div>
                  ))}
              </Card>
            </div>
          </div>
        )

      case 'select':
        return (
          <div>
            {label && (
              <label className={`${required && 'required'} ${labelClass && labelClass}`} htmlFor={id}>
                {label}
              </label>
            )}
            <select className="custom-select custom-select-sm" value={value} id={id} name={name} onChange={onChangeText}>
              {placeholder && (
                <option value="" selected>
                  {placeholder}
                </option>
              )}
              {options &&
                options.length > 0 &&
                options.map(item => (
                  <option key={item.id} value={item.id} selected={id === item.id}>
                    {item.value}
                  </option>
                ))}
            </select>
          </div>
        )

      case 'multi-upload-image':
        return (
          <div className="multi-list-image-contain">
            {label && (
              <label className={required && 'required mb-0'} htmlFor={id}>
                {label}
              </label>
            )}

            <div
              className={`d-flex flex-wrap justify-content-center with-multi-upload single-upload-image-profile flex-column invalid--formate--error-${name}`}
            >
              <div className={`multi-image-upload-input `}>
                <div className="addBoatShow-imgUploader upload-img-circle single-image dashboard--form--img--uploader">
                  <img loading="lazy" src={require('./Images/placeholder.png')} alt={label} className={isNotCircle && 'square-img'} />
                  {!loading && (
                    <ImageUploader
                      name={name}
                      withIcon={false}
                      withLabel={false}
                      buttonText=""
                      fileTypeError="Please select valid format for image"
                      accept="image/*"
                      imgExtension={imgExtension}
                      errorClass="error--multi-img-upload"
                      onChange={file => {
                        errorMessageMethod(file, name)
                        handleMultipleFileUpload(file, name, values, setFieldValue)
                      }}
                      buttonStyles={{
                        'background-image': `url(${require('./Images/camera.png')})`,
                      }}
                      maxFileSize={5242880}
                    />
                  )}
                </div>
              </div>
              <span className={`error-text-upload error-${name}`}></span>
              <div className={values && values.length ? 'multi-image-upload-list' : 'multi-image-upload-list m-0'}>
                <DragDropContext onDragEnd={onDragEnd}>
                  <Droppable droppableId={id}>
                    {provided => (
                      <div {...provided.droppableProps} ref={provided.innerRef}>
                        {values &&
                          values.length > 0 &&
                          values.map((img, index) => (
                            <Draggable key={img.url} draggableId={img.url} index={index}>
                              {provided => (
                                <div
                                  className="addBoat-container mr-2 mb-2"
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                >
                                  <img loading="lazy" src={img.url} alt="uploaded layout" height="100px" width="100px" />
                                  <span onClick={() => handleMultipleFileDelete(name, img, setFieldValue, values)}>x</span>
                                </div>
                              )}
                            </Draggable>
                          ))}

                        {provided.placeholder}
                      </div>
                    )}
                  </Droppable>
                </DragDropContext>

                <span className={`${name}--loading default--loading`}>
                  <Loader isSmall />
                </span>
              </div>
            </div>
          </div>
        )

      case 'select-date-time':
        return (
          <div className={['last--time--maintenance', className].filter(Boolean).join(' ')}>
            <Datetime
              displayTimeZone={timezoneName}
              dateFormat={isDateFormatFalse ? false : noDateFormat ? 'MM/YYYY' : dateFormat}
              timeFormat={isTimeFormatFalse ? false : timeFormat}
              isValidDate={isValidDate}
              utc={timezoneName ? false : isUtc}
              value={isUpdate ? (timezoneName ? moment(value).tz(timezoneName) : moment(value)) : null}
              inputProps={{ name: name, autoComplete: 'off' }}
              renderInput={(props, openCalendar) => (
                <div>
                  <input {...props} placeholder={label} />
                  <CalendarTodayIcon className="last--icon" onClick={openCalendar} />
                </div>
              )}
              closeOnSelect
              onChange={onChange}
            />
          </div>
        )

      case 'select-date':
        return (
          <div className={['last--time--maintenance', className].filter(Boolean).join(' ')}>
            <Datetime
              displayTimeZone={timezoneName}
              dateFormat={isDateFormatFalse ? false : noDateFormat ? 'MM/YYYY' : 'MMM DD, YYYY'}
              timeFormat={isTimeFormatFalse ? false : timeFormat}
              isValidDate={isValidDate}
              utc={timezoneName ? false : isUtc}
              value={isUpdate ? (timezoneName ? moment(value).tz(timezoneName) : moment(value)) : null}
              inputProps={{ name: name, autoComplete: 'off' }}
              closeOnSelect
              onChange={onChange}
              renderInput={(props, openCalendar) => (
                <div>
                  <input {...props} placeholder={label} />
                  <CalendarTodayIcon className="last--icon" onClick={openCalendar} />
                </div>
              )}
              renderDay={(props, currentDate, selectedDate) => {
                const currDate = moment(currentDate).local()
                const selDate = moment(selectedDate).local()
                const localDate = moment().local()
                const isSel = currDate.isSame(selDate, 'day')
                const isAvailable =
                  currDate.isSameOrAfter(localDate, 'day') && highlightDates?.some(date => currDate.isSame(date, 'day'))

                return (
                  <td
                    {...props}
                    style={{
                      ...props?.style,
                      opacity: isAvailable ? 1 : 0.5,
                      boxShadow: isAvailable ? 'inset 0 0 0 2px #46c8df' : '',
                      color: isAvailable ? (isSel ? '#fff' : '#46c8df') : 'black',
                      fontWeight: isAvailable ? 500 : 400,
                    }}
                  >
                    {currentDate.date()}
                  </td>
                )
              }}
            />
          </div>
        )

      case 'checkbox':
        return (
          <FormControlLabel
            control={
              <Checkbox
                checked={value}
                onChange={onChangeText}
                value={name}
                color={labelColor}
                {...(labelIcon && { checkedIcon: labelIcon })}
              />
            }
            label={label}
          />
        )

      case 'switch':
        return (
          <CommonTooltip component={ctProps => (
            <Switch
              data-tooltip-id={ctProps.id}
              data-tooltip-content={checked ? (switchEnabledText || 'Enabled') : (switchDisabledText || 'Disabled')}
              checked={checked}
              onChange={onChange}
              value={value}
              inputProps={{ 'aria-label': 'secondary checkbox' }}
              disabled={disabled}
              className="black-switch"
            />
          )} />
        )

      case 'form-radio':
        return (
          <FormControl component="fieldset">
            <label className={classNames(!radioNotRequired && "required")} htmlFor={'isTaxEnabled'}>
              <span className={`help--icon--position--lb-tool ${bold && 'bold-label'}`}>
                {label}
                <HelpIconComponent helpText={helpText} containerClass="single-select--help" />
              </span>
            </label>
            <RadioGroup
              row
              aria-label="gender"
              value={value}
              className="radioButton"
              onChange={e => onChangeText(e)}
              name={name}
            >
              {!isNotLookup && (
                <div className="radio-button-type-font">
                  {options &&
                    options.map(item => {
                      return (
                        <FormControlLabel
                          key={item.lookUp.id}
                          control={<Radio value={item.lookUp.id} />}
                          label={item.lookUp.alias}
                        />
                      )
                    })}
                </div>
              )}
              {isNotLookup && (
                <div className="radio-button-type-font">
                  {options &&
                    options.map(item => {
                      return <FormControlLabel control={<Radio value={item.value} />} label={item.label} />
                    })}
                </div>
              )}
            </RadioGroup>
          </FormControl>
        )
      case 'shipper-document-tag':
        return value ? (
          <>
            <div className="shipment--document--with--icon">
              {value?.url ? (
                <>
                  <span className="shipment--document--main--label">{label}:</span>
                  {imgExtension.some(word => value?.name.includes(word)) ? (
                    <img loading="lazy" style={{ height: 50, width: 50, marginLeft: 10 }} src={value.url} className="uploaded--image" alt='docs'/>
                  ) : (
                    <>
                      <img
                        style={{ height: 50, width: 50, marginLeft: 10 }}
                        src={getExtension()}
                        className="uploaded--document"
                        alt='Upload document'
                      />
                    </>
                  )}
                </>
              ) : value?.id ? (
                <span className="shipment--document--list">
                  {label} {downloadIcon(value.id, getDocumentUrl)}{' '}
                </span>
              ) : (
                <p className="shipment--document--main--label">{value.name}</p>
              )}
              <CloseIcon
                className="remove-doc cursor-pointer close--icon--remove--img"
                onClick={() => {
                  setLoading(false)
                  handleSingleFileDelete(value, name, onChangeText, addMedia, deleteIds)
                }}
              />
            </div>
          </>
        ) : (
          <ImageUploader
            name={name}
            withIcon={false}
            withLabel={false}
            buttonClassName="shipment--document--list"
            accept={[...docExtension, ...imgExtension]}
            imgExtension={[...docExtension, ...imgExtension]}
            singleImage={true}
            buttonText={label}
            buttonStyles={{
              // "background-image": `url(${require("./Images/camera.png")})`
              // "background-image": `url(${require("./Images/doc1.svg")})`,
              height: '24px',
              width: '24px',
              'border-radius': '0',
            }}
            onChange={file => {
              handleSingleFileUpload(file, name, onChangeText, addMedia, mediaName, MEDIA_TYPE.DOCUMENT, undefined, { maxImageSize })
            }}
            maxFileSize={5242880}
          />
        )
      default:
        break
    }
  }

  return <>{renderField()}</>
}

Field.defaultProps = {
  id: '',
  name: '',
  bold: '',
  required: false,
  isUtc: true,
  verify: '',
  bold: '',
  rows: 3,
  isCustomRadio: false,
  cropBeforeUpload: false,
  labelColor: 'primary',
  labelIcon: null,
  initValue: '',
  addMedia: [],
  deleteIds: [],
  getDocumentUrl: () => null,
  documentTypes: [],
  onlyImageEnabled: false,
  getDocumentType: () => null,
  onKeyDown: () => null,
  onKeyUp: () => null,
  onBlur: () => null,
  checked: false,
  limit: null,
}
