import ReactDOM from 'react-dom'
import cn from 'classnames'
import IconClose from '../../assets/icons/close'
import compStyles from '../../styles/components.module.scss'
import styles from './confirm-popup.module.scss'

export default function ConfirmPopup({ title, text, onConfirm, disabled, close }) {
  return ReactDOM.createPortal(
    <div className={compStyles.overlay}>
      <div className={cn(compStyles.modal, styles.modal)}>
        <div className={cn(compStyles.modalHead, styles.modalHead)}>
          {title ? <h2 className={compStyles.modalTitle}>{title}</h2> : <span />}
          <button className={compStyles.modalCloseBtn} onClick={close} disabled={disabled}>
            <IconClose className={compStyles.modalCloseBtnIcon} />
          </button>
        </div>

        {text && <div className={styles.modalBody}>{text}</div>}

        <div className={cn(compStyles.modalFooter, styles.modalFooter)}>
          <button className={styles.confirmBtn} onClick={onConfirm} disabled={disabled}>
            Confirm
          </button>
        </div>
      </div>
    </div>,
    document.querySelector('.__as_sm')
  )
}
