import { useEffect } from 'react'
import { Link } from 'react-router-dom'
import cn from 'classnames'
import { userRoles } from '../../../../util/enums/enums'
import { getAddress } from '../../../../helpers/jsxHelper'
import * as Icons from '../../assets/icons'
import { useAuth } from '../../contexts/auth-ctx'
import { useOnline } from '../../contexts/online-ctx'
import ChatBtn from '../user/chat-btn'
import FollowBtn from '../post/follow-btn'
import styles from './profile-box.module.scss'
import moment from 'moment'

export default function ProfileBox({ user, showPrim, showSec }) {
  const auth = useAuth()
  const { getOnline, isOnline } = useOnline()

  const userAddr = getAddress(user.address)?.commonAddress

  useEffect(() => {
    getOnline(user._id)
  }, [user, getOnline])

  return (
    <div className={styles.profileBox}>
      {showPrim && (
        <div className={styles.primDetsWrp}>
          <div className={styles.avatarNameActsWrp}>
            <div className={styles.avatarNameActsInnWrp}>
              <div className={styles.avatarNameWrp}>
                <div className={styles.avatarMainWrp}>
                  <Link to={`/user-profile/${user._id}/${user.firstName}-${user.lastName}`} className={styles.avatarWrp}>
                    <img
                      src={user.image?.url || require('../../assets/avatar-sample.png')}
                      alt={user.firstName}
                      className={styles.avatar}
                    />
                    <span className={cn(styles.onlineStatus, isOnline(user._id) && styles.onlineStatusActive)} />
                  </Link>
                </div>

                <div className={styles.userNameWrp}>
                  <div className={styles.userNameInnWrp}>
                    <Link to={`/user-profile/${user._id}/${user.firstName}-${user.lastName}`} className={styles.userName}>
                      {user.firstName} {user.lastName}
                    </Link>

                    {user.isVerified && (
                      <div className={styles.verifLabel}>
                        <Icons.Shield className={styles.icon} /> Verified
                      </div>
                    )}
                  </div>

                  <div className={styles.userCountry}>
                    <Icons.Location className={styles.countIcon} /> {userAddr?.country}
                  </div>
                </div>
              </div>

              {!auth.isLoading && auth.user?._id !== user._id && (
                <div className={styles.actBtnsWrp}>
                  <ChatBtn user={user} className={cn(styles.actBtn, styles.actChatBtn)} />
                  <FollowBtn userId={user._id} className={cn(styles.actBtn, styles.actFollowBtn)} />
                </div>
              )}
            </div>
          </div>

          <div className={styles.countsWrp}>
            <div className={styles.countWrp}>
              <div className={styles.count}>&nbsp;</div>
              <div className={styles.countLabel}>Since <b>{moment(user.createdAt).format('MMM, YYYY')}</b></div>
            </div>
            <div className={styles.countWrp}>
              <div className={styles.count}>{user.followingsCount}</div>
              <div className={styles.countLabel}>Followings</div>
            </div>
            <div className={styles.countWrp}>
              <div className={styles.count}>{user.followersCount}</div>
              <div className={styles.countLabel}>Followers</div>
            </div>
          </div>

          <div className={styles.userDesc}>{String(user.shortDescription).substring(0, 150)}</div>
        </div>
      )}

      {showSec && (
        <div className={styles.body}>
          {user.companyName && (
            <>
              <div className={styles.companyWrp}>
                <Link to={`/user-profile/${user._id}/${user.firstName}-${user.lastName}`} className={styles.companyLogo}>
                  <img src={user.companyLogo?.url} alt={user.companyName} />
                </Link>
                <div className={styles.companyDets}>
                  <Link to={`/user-profile/${user._id}/${user.firstName}-${user.lastName}`} className={styles.companyName}>
                    {user.companyName}
                  </Link>
                  <div className={styles.userType}>{user.role?.role}</div>
                </div>
              </div>
            </>
          )}

          <div className={styles.countsWrp}>
            {[
              userRoles.BOAT_OWNER,
              userRoles.BROKER_AND_DEALER,
              userRoles.BOAT_MANUFACTURER,
              userRoles.RENT_AND_CHARTER,
              userRoles.MARINA_AND_STORAGE,
            ].includes(user.role?.aliasName) && (
              <>
                <div className={styles.countItemWrp}>
                  <div className={styles.countWrp}>
                    <Icons.Boat className={styles.countIcon} />
                    <span className={styles.count}>{user.adsCount}</span>
                  </div>
                  <div className={styles.countLabel}>{user.adsCountLabel}</div>
                </div>

                <div className={styles.sep} />
              </>
            )}

            {![userRoles.MEMBER, userRoles.BOAT_OWNER].includes(user.role?.aliasName) && (
              <>
                <div className={styles.countItemWrp}>
                  <div className={styles.countWrp}>
                    <Icons.Review className={styles.countIcon} />
                    <span className={styles.count}>{user.reviewsCount}</span>
                  </div>
                  <div className={styles.countLabel}>Reviews</div>
                </div>

                <div className={styles.sep} />
              </>
            )}

            <div className={styles.countItemWrp}>
              <div className={styles.countWrp}>
                <Icons.BoatShow className={styles.countIcon} />
                <span className={styles.count}>{user.boatShowsCount}</span>
              </div>
              <div className={styles.countLabel}>Boat Shows</div>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}
