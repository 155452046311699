import { createContext, useCallback, useContext, useState } from 'react'
import _ from 'lodash'
import { getId } from '../../../util/utilFunctions'

const SMDbCtx = createContext()

export const SMDbProvider = ({ children }) => {
  const [db, setDb] = useState({})

  const has = useCallback(
    (id, ...keys) => {
      id = _.isString(id) ? id : getId(id)
      return _.has(db, [id, ...keys].join('.'))
    },
    [db]
  )

  const get = useCallback(
    (id, key = null, def = null) => {
      id = _.isString(id) ? id : getId(id)
      return key ? (has(id, key) ? _.get(db[id], key) : def) : db[id]
    },
    [db, has]
  )

  const set = useCallback((id, key, val) => {
    id = _.isString(id) ? id : getId(id)
    setDb(db => {
      const newDb = { ...db }
      newDb[id] = { ...newDb[id] }
      _.set(newDb[id], key, val)
      return newDb
    })
  }, [])

  const merge = useCallback((id, val) => {
    id = _.isString(id) ? id : getId(id)
    setDb(db => {
      const newDb = { ...db }
      newDb[id] = { ...newDb[id] }
      _.merge(newDb[id], val)
      return newDb
    })
  }, [])

  return <SMDbCtx.Provider value={{ has, get, set, merge }}>{children}</SMDbCtx.Provider>
}

export const useSMDb = () => useContext(SMDbCtx)
