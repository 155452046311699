import { useCallback, useState } from 'react'
import { enqueueSnackbar } from 'notistack'
import IconHeartSolid from '../../assets/icons/heart-solid'
import IconHeart from '../../assets/icons/heart'
import { likePost as qLikePost } from '../../lib/queries'
import { useSMDb } from '../../contexts/smdb-ctx'
import { useAuth } from '../../contexts/auth-ctx'
import LoginModal from '../overlays/login-modal'

export default function LikeBtn({ postId, ...props }) {
  const { get: smdbGet, set: smdbSet } = useSMDb()
  const auth = useAuth()

  const [isLoginModalOpen, setIsLoginModalOpen] = useState(false)
  const [isLikeLoading, setIsLikeLoading] = useState(false)

  const likePost = useCallback(async () => {
    setIsLikeLoading(true)

    try {
      const res = await qLikePost(postId)
      smdbSet(postId, 'likesCount', res.likesCount)
      smdbSet(postId, 'liked', res.liked)
      smdbSet(postId, 'topLikes', res.topLikes)
    } catch (er) {
      enqueueSnackbar(er)
    }

    setIsLikeLoading(false)
  }, [postId, smdbSet])

  return (
    <>
      <button
        {...props}
        onClick={async e => {
          props.onClick?.(e)
          auth.isLoggedIn ? likePost() : setIsLoginModalOpen(true)
        }}
        disabled={props.disabled || isLikeLoading}
      >
        {props.children || (
          <>
            {smdbGet(postId, 'liked') ? <IconHeartSolid /> : <IconHeart />} {smdbGet(postId, 'likesCount')}
          </>
        )}
      </button>

      {isLoginModalOpen && <LoginModal close={() => setIsLoginModalOpen(false)} />}
    </>
  )
}
