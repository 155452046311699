import { useCallback, useState } from 'react'
import { enqueueSnackbar } from 'notistack'
import { followUser as qFollowUser } from '../../lib/queries'
import { useSMDb } from '../../contexts/smdb-ctx'
import { useAuth } from '../../contexts/auth-ctx'
import LoginModal from '../overlays/login-modal'

export default function FollowBtn({ userId, ...props }) {
  const { get: smdbGet, set: smdbSet } = useSMDb()
  const auth = useAuth()

  const [isLoginModalOpen, setIsLoginModalOpen] = useState(false)
  const [isFollowLoading, setIsFollowLoading] = useState(false)

  const followUser = useCallback(async () => {
    setIsFollowLoading(true)

    try {
      const res = await qFollowUser(userId, auth.user._id)
      smdbSet(userId, 'isFollowing', res)
    } catch (er) {
      enqueueSnackbar(er)
    }

    setIsFollowLoading(false)
  }, [userId, auth, smdbSet])

  return (
    <>
      <button
        {...props}
        onClick={async e => {
          props.onClick?.(e)
          auth.isLoggedIn ? followUser() : setIsLoginModalOpen(true)
        }}
        disabled={isFollowLoading ?? props.disabled}
      >
        {props.children || (smdbGet(userId, 'isFollowing') ? 'Following' : 'Follow')}
      </button>

      {isLoginModalOpen && <LoginModal close={() => setIsLoginModalOpen(false)} />}
    </>
  )
}
