import React, { useEffect, useState } from 'react'
import { Container, InputBase } from '@material-ui/core'
import { Layout } from '../../components'
import { ButtonComponent } from '../../components/form/Button'
import moment from 'moment'
import './notification.scss'
import notificationsSlice from '../../redux/slices/notificationsSlice'
import { connect } from 'react-redux'
import NotificationTemplates, { NOTIFICATION_CATG } from './templates'
import { FaSpinner, FaTrashAlt } from 'react-icons/fa'
import { get } from 'lodash'
import { SuccessNotify } from '../../helpers/notification'
import { redirectToUserProfile } from '../../helpers/jsxHelper'
import classNames from 'classnames'
import SingleSelect from '../../components/helper/singleSelect'

const Notification = ({
  history,
  currentUser,
  notifications_read,
  readNotifications,
  readNotifications_clear,
  notifications_markAsRead,
  markNotificationsAsRead,
  markNotificationsAsRead_clear,
  notifications_remove,
  removeNotifications,
  removeNotifications_clear,
}) => {
  const [refreshToken, setRefreshToken] = useState(0)
  const [page, setPage] = useState(1)
  const [reqId, setReqId] = useState(-1)
  const [notifications, setNotifications] = useState([])
  const [isLastPage, setIsLastPage] = useState(false)
  const [searchTerm, setSearchTerm] = useState('')
  // const [searchTermTimer, setSearchTermTimer] = useState(null)
  const [category, setCategory] = useState({ label: 'All', value: '' })

  useEffect(() => {
    window.removeEventListener('scroll', onPageScroll)
    window.addEventListener('scroll', onPageScroll)
  }, [notifications])

  useEffect(() => {
    const newReqId = reqId + 1
    readNotifications({
      cache: { page, notifications, reqId: newReqId },
      input: { page, ...(searchTerm ? { searchTerm } : {}), ...(category?.value ? { category: category.value } : {}) },
    })
    setReqId(newReqId)
  }, [page, refreshToken])

  useEffect(() => {
    if (notifications_read.loading !== null) {
      page > 1 ? setPage(1) : setRefreshToken(t => t + 1)
    }
  }, [category])

  useEffect(() => {
    if (notifications_read.success || notifications_read.failure) {
      readNotifications_clear()

      if (notifications_read.success && notifications_read.data.cache.reqId === reqId) {
        setIsLastPage(notifications_read.data.cache.page === Math.ceil(notifications_read.data.result.total / 10))
        setNotifications(
          notifications_read.data.cache.page === 1
            ? notifications_read.data.result.items
            : [...notifications_read.data.cache.notifications, ...notifications_read.data.result.items]
        )

        const unreadIds = notifications_read.data.result.items.filter(i => !i.isRead).map(i => i._id)
        unreadIds.length && markNotificationsAsRead({ input: { _id: unreadIds } })
      }
    }
  }, [notifications_read])

  useEffect(() => {
    if (notifications_markAsRead.success || notifications_markAsRead.failure) {
      markNotificationsAsRead_clear()

      if (notifications_markAsRead.success && notifications_markAsRead.data.cache?.markAllNotificationsAsRead) {
        SuccessNotify('All Notifications successfully marked as read!')
        setRefreshToken(v => v + 1)
      }
    }
  }, [notifications_markAsRead])

  useEffect(() => {
    if (notifications_remove.success || notifications_remove.failure) {
      SuccessNotify('Notification deleted successfully!')
      removeNotifications_clear()
      setRefreshToken(refreshToken => refreshToken + 1)
    }
  }, [notifications_remove])

  const onPageScroll = () => {
    if (notifications.length && !notifications_read.loading && !isLastPage) {
      const el = document.querySelector('.page-scroll-div')

      if (el?.getBoundingClientRect().bottom <= window.innerHeight + 150) {
        window.removeEventListener('scroll', onPageScroll)
        setPage(page + 1)
      }
    }
  }

  const markAllNotificationsAsRead = () => {
    markNotificationsAsRead({ cache: { markAllNotificationsAsRead: true } })
  }

  const removeNotification = notification => {
    removeNotifications({ input: { _id: [notification._id] } })
  }

  const disableAll = notifications_read.loading || notifications_markAsRead.loading || notifications_remove.loading

  return (
    <Layout>
      <Container maxWidth="md" className="notification--main--container">
        {/* <div className="d-flex justify-content-end notification--to--home--div">
          <p className="notification--to--home">Back To Home</p>
        </div> */}
        <div className="notification-mobile-menu">
          <div className="notification--main--title--div">
            <p className="notification--main--title mb-0">Notifications</p>
            {!!notifications.length && (
              <div className="all--read--btn--div">
                <ButtonComponent
                  className="as--btn--view"
                  onClick={() => {
                    setSearchTerm('')
                    setCategory({ label: 'All', value: '' })
                    setTimeout(() => (page > 1 ? setPage(1) : setRefreshToken(t => t + 1)), 500)
                  }}
                  disabled={disableAll}
                >
                  <i className="fas fa-sync-alt" style={{ color: 'inherit' }}></i>
                </ButtonComponent>
                <ButtonComponent className="all--read--btn" onClick={() => markAllNotificationsAsRead()} disabled={disableAll}>
                  Mark all as read
                </ButtonComponent>
              </div>
            )}
          </div>
          <div className="notification--search--input--cont">
            <div className="notification--search--input" style={{ flex: 3 }}>
              <InputBase
                placeholder="Search Notifications"
                className="search-input"
                value={searchTerm}
                onChange={e => {
                  setSearchTerm(e.target.value)
                  // clearInterval(searchTermTimer)
                  // setSearchTermTimer(setTimeout(() => (page > 1 ? setPage(1) : setRefreshToken(t => t + 1)), 500))
                }}
                disabled={disableAll}
              />
              <ButtonComponent
                color="none"
                className="notification--search--right--icon"
                disabled={disableAll}
                onClick={() => {
                  page === 1 ? setRefreshToken(refreshToken => refreshToken + 1) : setPage(1)
                }}
              >
                <i class="fas fa-search" />
              </ButtonComponent>
            </div>
            <div className="notification--search--input singleSelect" style={{ flex: 2 }}>
              <SingleSelect
                cx="search-input h-36"
                name="category"
                selectedOption={category}
                options={[
                  { label: 'All', value: '' },
                  ...Object.keys(NOTIFICATION_CATG).map(value => ({ label: NOTIFICATION_CATG[value], value })),
                ]}
                styles={{
                  control: (base) => ({
                    ...base,
                    background: 'transparent'
                  })
                }}
                onChange={setCategory}
                disabled={disableAll}
              />

              <ButtonComponent
                color="none"
                className="notification--search--right--icon"
                disabled={disableAll}
                onClick={() => {
                  page === 1 ? setRefreshToken(refreshToken => refreshToken + 1) : setPage(1)
                }}
              >
                <i class="fas fa-filter" />
              </ButtonComponent>
            </div>
          </div>
        </div>
        <div className="notification--main--sec page-scroll-div">
          {notifications.map(notification => (
            <div
              className={classNames('notification--main--div', {
                'notif-unread': !notification.isRead,
              })}
              key={notification._id}
            >
              <div className="notification--user--desc--date--div">
                <div className="notification--user--image--sec">
                  <div
                    className="notification--user--image--div cursor-pointer"
                    onClick={() => redirectToUserProfile(get(notification, 'sender', ''), history)}
                  >
                    <img loading="lazy" src={get(notification, 'sender.image.largeUrl', '')} alt="Notification" className="notification--user--image" />
                  </div>
                </div>
                <div className="d-flex flex-column notification--desc--date--div">
                  <div className="notification--module-type">{NOTIFICATION_CATG[notification.category]}</div>
                  <div className="notification--desc--div">
                    <p className="notification--desc mb-0">
                      {NotificationTemplates[notification.type](notification, history, currentUser)}
                    </p>
                  </div>
                  <div className="notification--date--div">
                    {/* <p className="notification--date mb-0">04 Jan 2021 at 06:00 PM</p> */}
                    <p className="notification--date mb-0">{moment(notification.createdAt).format('D MMM ddd hh:mm A')}</p>
                  </div>
                </div>
              </div>
              <div className="notification--btn--sec">
                {/* <div className="notification--open--btn">
                  <ButtonComponent className="">Open</ButtonComponent>
                </div> */}
                {/* <div className="notification--read--btn">
                  <ButtonComponent className="">Mark As Read</ButtonComponent>
                </div> */}
                <div className="notification--close--btn">
                  <button
                    style={{ background: 'transparent' }}
                    onClick={() => removeNotification(notification)}
                    disabled={disableAll}
                    className="hoverable-img-wrp"
                  >
                    <img loading="lazy" src={require('../../assets/images/delete-icon.png')} alt='Delete' className="img-on-nohover" />
                    <img loading="lazy" src={require('../../assets/images/delete-icon.gif')} alt='Delete' className="img-on-hover" />
                  </button>
                </div>
              </div>
            </div>
          ))}

          {notifications_read.loading ? (
            !notifications.length && <div className="pt-3 text-center">
              <FaSpinner className="anim-spin mr-2" />
              {notifications.length ? 'Loading More' : 'Loading'}...
            </div>
          ) : (
            !notifications.length && <div className="text-center">You have no notifications!</div>
          )}
        </div>
      </Container>
    </Layout>
  )
}

const mapStateToProps = ({ loginReducer, notificationsReducer }) => ({
  currentUser: loginReducer.currentUser,
  notifications_read: notificationsReducer.read,
  notifications_markAsRead: notificationsReducer.markAsRead,
  notifications_remove: notificationsReducer.remove,
})

const mapDispatchToProps = dispatch => ({
  readNotifications: data => dispatch(notificationsSlice.actions.read.main(data)),
  readNotifications_clear: data => dispatch(notificationsSlice.actions.read.clear(data)),
  markNotificationsAsRead: data => dispatch(notificationsSlice.actions.markAsRead.main(data)),
  markNotificationsAsRead_clear: data => dispatch(notificationsSlice.actions.markAsRead.clear(data)),
  removeNotifications: data => dispatch(notificationsSlice.actions.remove.main(data)),
  removeNotifications_clear: data => dispatch(notificationsSlice.actions.remove.clear(data)),
})

export default connect(mapStateToProps, mapDispatchToProps)(Notification)
