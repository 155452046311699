import { useCallback, useEffect, useRef, useState } from 'react'
import ReactDOM from 'react-dom'
import OutsideClickHandler from 'react-outside-click-handler'
import { enqueueSnackbar } from 'notistack'
import { getComments as qGetComments } from '../../lib/queries'
import { useBodyScroll } from '../../contexts/body-scroll'
import SpinnerWithText from '../ui/spinner-with-text'
import compStyles from '../../styles/components.module.scss'
import styles from './comments-modal.module.scss'
import Comments from '../post/comments'

export default function CommentsModal({ post, close }) {
  const { enable: enableBodyScroll, disable: disableBodyScroll } = useBodyScroll()

  const [commentsLoading, setCommentsLoading] = useState(false)
  const [comments, setComments] = useState([])

  const paginationRef = useRef({})

  const getComments = useCallback(
    async pagination => {
      setCommentsLoading(true)

      try {
        const res = await qGetComments(post._id, pagination)
        setComments(res.items)
        paginationRef.current = { page: res.page }
      } catch (err) {
        enqueueSnackbar(err)
      }

      setCommentsLoading(false)
    },
    [post]
  )

  useEffect(() => {
    setComments([])
    getComments({})
  }, [getComments])

  useEffect(() => {
    disableBodyScroll()
    return enableBodyScroll
  }, [enableBodyScroll, disableBodyScroll])

  return ReactDOM.createPortal(
    <div className={compStyles.overlay}>
      <OutsideClickHandler onOutsideClick={close}>
        <div className={styles.modal}>
          <div className={styles.modalHead}>
            <div className={styles.modalTitle}>Comments</div>
            <button className={styles.modalCloseBtn} onClick={close}>
              &times;
            </button>
          </div>

          <div className={styles.modalBody}>
            <div className={styles.modalContent}>
              <Comments post={post} comments={comments} />
              {commentsLoading && <SpinnerWithText>Loading...</SpinnerWithText>}
            </div>
          </div>
        </div>
      </OutsideClickHandler>
    </div>,
    document.querySelector('.__as_sm')
  )
}
