import { useState } from 'react'
import IconChat from '../../assets/icons/chat'
import { useAuth } from '../../contexts/auth-ctx'
import LoginModal from '../overlays/login-modal'
import ChatModal from '../overlays/chat-modal'

export default function ChatBtn({ user, ...props }) {
  const auth = useAuth()

  const [isLoginModalOpen, setIsLoginModalOpen] = useState(false)
  const [isChatModalOpen, setIsChatModalOpen] = useState(false)

  return (
    <>
      <button
        {...props}
        onClick={async e => {
          props.onClick?.(e)
          auth.isLoggedIn ? setIsChatModalOpen(true) : setIsLoginModalOpen(true)
        }}
      >
        {props.children || <IconChat />}
      </button>

      {isChatModalOpen && <ChatModal user={user} close={() => setIsChatModalOpen(false)} />}
      {isLoginModalOpen && <LoginModal close={() => setIsLoginModalOpen(false)} />}
    </>
  )
}
