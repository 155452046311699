import { useCallback, useEffect, useState } from 'react'
import ReactDOM from 'react-dom'
import { enqueueSnackbar } from 'notistack'
import cn from 'classnames'
import IconClose from '../../assets/icons/close'
import { PostReportReasons } from '../../lib/consts'
import { submitPostReport as qSubmitPostReport } from '../../lib/queries'
import compStyles from '../../styles/components.module.scss'
import styles from './report-popup.module.scss'

export default function ReportPopup({ post, close }) {
  const postId = post._id

  const [reason, setReason] = useState(null)
  const [otherReason, setOtherReason] = useState('')
  const [reasonErr, setReasonErr] = useState(null)
  const [submitting, setSubmitting] = useState(false)

  const submitPostReport = useCallback(async () => {
    if (!reason) {
      setReasonErr('Please select a reason')
      return
    } else if (reason === PostReportReasons.Other && !otherReason) {
      setReasonErr('Please enter a reason')
      return
    } else {
      setReasonErr(null)
    }

    setSubmitting(true)

    try {
      await qSubmitPostReport({ postId, reason, otherReason })
      enqueueSnackbar('Report submitted successfully')
      close?.()
    } catch (err) {
      enqueueSnackbar("Can't submit report")
    }

    setSubmitting(false)
  }, [postId, reason, otherReason])

  useEffect(() => {
    if (reason !== PostReportReasons) {
      setOtherReason('')
    }
  }, [reason])

  useEffect(() => {
    setReasonErr(null)
  }, [reason, otherReason])

  return ReactDOM.createPortal(
    <div className={compStyles.overlay}>
      <div className={cn(compStyles.modal, styles.modal)}>
        <div className={compStyles.modalHead}>
          <h2 className={compStyles.modalTitle}>Report Post</h2>
          <button className={compStyles.modalCloseBtn} onClick={close}>
            <IconClose className={compStyles.modalCloseBtnIcon} />
          </button>
        </div>

        <div className={styles.modalBody}>
          <h3 className={styles.quesTitle}>Why do you want to report this post?</h3>

          <div className={styles.reasonsList}>
            {Object.keys(PostReportReasons).map(k => (
              <div key={k} className={styles.reasonWrp}>
                <label className={styles.reasonLabel}>
                  <span className={styles.reasonText}>{PostReportReasons[k]}</span>
                  <input
                    type="radio"
                    name={`post-${post._id}-report`}
                    className={styles.reasonRadioInp}
                    onChange={e => e.target.checked && setReason(k)}
                    disabled={submitting}
                  />
                </label>
              </div>
            ))}

            <div className={styles.reasonWrp}>
              <textarea
                className={styles.reasonTextInp}
                placeholder="Enter reason"
                value={otherReason}
                onChange={e => setOtherReason(e.target.value)}
                disabled={submitting}
              />
            </div>
          </div>

          {reasonErr && <p className={styles.reasonErr}>{reasonErr}</p>}
        </div>

        <div className={cn(compStyles.modalFooter, styles.modalFooter)}>
          <button className={styles.reportBtn} onClick={submitPostReport} disabled={submitting}>
            Report
          </button>
        </div>
      </div>
    </div>,
    document.querySelector('.__as_sm')
  )
}
