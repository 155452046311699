import { useState } from 'react'
import { Link } from 'react-router-dom'
import OutsideClickHandler from 'react-outside-click-handler'
import cn from 'classnames'
import * as Icons from '../../assets/icons'
import { Routes } from '../../lib/consts'
import { useAuth } from '../../contexts/auth-ctx'
import LoginModal from '../overlays/login-modal'
import ChatModal from '../overlays/chat-modal'
import CreatePostModal from '../overlays/create-post-modal'
import styles from './sidebar.module.scss'

export default function Sidebar({ className }) {
  const auth = useAuth()

  const [isLoginModalOpen, setIsLoginModalOpen] = useState(false)
  const [isChatModalOpen, setIsChatModalOpen] = useState(false)
  const [isCreatePostModalOpen, setIsCreatePostModalOpen] = useState(false)
  const [isProfileDdOpen, setIsProfileDdOpen] = useState(false)

  return (
    <>
      <aside className={cn(styles.sidebar, className)}>
        <div className={styles.sidebarContent}>
          <div className={styles.logoWrp}>
            <img src={require('../../../../assets/images/logo/home.png')} alt="SM Logo" className={styles.logo} />
          </div>

          <nav className={styles.nav}>
            <ul className={styles.navList}>
              <li className={styles.navItem}>
                <Link to={Routes.Home} className={styles.navLink}>
                  <span className={styles.navLinkIconWrp}>
                    <Icons.Home className={styles.navLinkIcon} />
                  </span>{' '}
                  Home
                </Link>
              </li>

              <li className={styles.navItem}>
                <Link to={Routes.Search} className={styles.navLink}>
                  <span className={styles.navLinkIconWrp}>
                    <Icons.Search className={styles.navLinkIcon} />
                  </span>{' '}
                  Search
                </Link>
              </li>

              <li className={styles.navItem}>
                {auth.isLoggedIn ? (
                  <Link to={Routes.Saved} className={styles.navLink}>
                    <span className={styles.navLinkIconWrp}>
                      <Icons.Heart className={styles.navLinkIcon} />
                    </span>{' '}
                    Saved
                  </Link>
                ) : (
                  <button className={styles.navLink} onClick={() => setIsLoginModalOpen(true)}>
                    <span className={styles.navLinkIconWrp}>
                      <Icons.Heart className={styles.navLinkIcon} />
                    </span>{' '}
                    Saved
                  </button>
                )}
              </li>

              <li className={styles.navItem}>
                <button
                  className={styles.navLink}
                  onClick={() => (auth.isLoggedIn ? setIsChatModalOpen(true) : setIsLoginModalOpen(true))}
                >
                  <span className={styles.navLinkIconWrp}>
                    <Icons.Message className={styles.navLinkIcon} />
                  </span>{' '}
                  Messages
                </button>
              </li>

              <li className={styles.navItem}>
                <Link to={Routes.Notifications} className={styles.navLink}>
                  <span className={styles.navLinkIconWrp}>
                    <Icons.Notification className={styles.navLinkIcon} />
                  </span>{' '}
                  Notifications
                </Link>
              </li>

              <li className={styles.navItem}>
                <button
                  className={styles.navLink}
                  onClick={() => (auth.isLoggedIn ? setIsCreatePostModalOpen(true) : setIsLoginModalOpen(true))}
                >
                  <span className={styles.navLinkIconWrp}>
                    <Icons.Plus className={styles.navLinkIcon} />
                  </span>{' '}
                  Create Post
                </button>
              </li>

              {!auth.isLoggedIn ? (
                <li className={styles.navItem}>
                  <button className={styles.navLink} onClick={() => setIsLoginModalOpen(true)}>
                    <span className={styles.navLinkIconWrp}>
                      <Icons.User className={styles.navLinkIcon} />
                    </span>{' '}
                    Login
                  </button>
                </li>
              ) : (
                <li className={cn(styles.navItem, styles.navProfileItem)}>
                  <div className={styles.navProfileLinkBtnWrp}>
                    <Link to={Routes.User(auth.user._id)} className={styles.navLink}>
                      <span className={styles.navLinkIconWrp}>
                        <img
                          src={auth.user.image?.url || require('../../assets/avatar-sample.png')}
                          alt={auth.user.firstName}
                          className={styles.navLinkAvatar}
                        />
                      </span>{' '}
                      Profile
                    </Link>

                    <button className={styles.navProfileDdTogBtn} onClick={() => setIsProfileDdOpen(open => !open)}>
                      <Icons.MenuHBars className={styles.navProfileDdTogBtnIcon} />
                    </button>
                  </div>

                  {isProfileDdOpen && (
                    <OutsideClickHandler onOutsideClick={() => setIsProfileDdOpen(false)}>
                      <div className={styles.navProfileDd}>
                        <Link to={Routes.Dashboard} className={styles.navProfileDdItem}>
                          <img src={require('../../../dashboard/image/Dashboard.svg')} alt="Dashboard" /> Dashboard
                        </Link>
                        <Link to={Routes.ChangePassword} className={styles.navProfileDdItem}>
                          <img src={require('../../../dashboard/image/Change Password.svg')} alt="Dashboard" /> Change Password
                        </Link>
                      </div>
                    </OutsideClickHandler>
                  )}
                </li>
              )}

              <li className={styles.navItem}>
                <Link to={Routes.Market} className={styles.navLink}>
                  <span className={styles.navLinkIconWrp}>
                    <Icons.Shop className={styles.navLinkIcon} />
                  </span>{' '}
                  <strong style={{ fontWeight: 700 }}>AdamSea</strong>
                </Link>
              </li>
            </ul>
          </nav>
        </div>
      </aside>

      {isLoginModalOpen && <LoginModal close={() => setIsLoginModalOpen(false)} />}
      {isChatModalOpen && <ChatModal close={() => setIsChatModalOpen(false)} />}
      {isCreatePostModalOpen && <CreatePostModal close={() => setIsCreatePostModalOpen(false)} />}
    </>
  )
}
