import { useEffect, useRef, useState } from 'react'
import { Link } from 'react-router-dom'
import OutsideClickHandler from 'react-outside-click-handler'
import moment from 'moment'
import cn from 'classnames'
import * as Icons from '../../assets/icons'
import { Routes } from '../../lib/consts'
import { useSMDb } from '../../contexts/smdb-ctx'
import { useAuth } from '../../contexts/auth-ctx'
import { useOnline } from '../../contexts/online-ctx'
import ReadMore from '../common/read-more'
import FollowBtn from './follow-btn'
import SaveBtn from './save-btn'
import LikeBtn from './like-btn'
import UserPreview from './user-preview'
import Media from './media'
import DeleteBtn from './delete-btn'
import ReportBtn from './report-btn'
import AddComment from './add-comment'
import LikesModal from '../overlays/likes-modal'
import CommentsModal from '../overlays/comments-modal'
import Comments from './comments'
import compStyles from '../../styles/components.module.scss'
import styles from './post.module.scss'
import CreatePostModal from '../overlays/create-post-modal'
import SeeMoreCommentsBtn from './see-more-comments-btn'

export default function Post({ post }) {
  const { get: smdbGet } = useSMDb()
  const auth = useAuth()
  const { getOnline, isOnline } = useOnline()

  const [isPostActionsDdOpen, setIsPostActionsDdOpen] = useState(false)
  const [keepPostActsDdOpen, setKeepPostActsDdOpen] = useState(false)
  const [isUserPreviewOpen, setIsUserPreviewOpen] = useState(false)
  const [editPostModalOpen, setEditPostModalOpen] = useState(false)
  const [likesModalOpen, setLikesModalOpen] = useState(false)
  const [commentsModalOpen, setCommentsModalOpen] = useState(false)
  const [parentComment, setParentComment] = useState(null)

  const userPreviewTimer = useRef()

  useEffect(() => {
    getOnline(post.user._id)
  }, [post, getOnline])

  const topLikesNOthers = (() => {
    const arr = [...smdbGet(post, 'topLikes', post.topLikes)]
    const restCount = smdbGet(post, 'likesCount', post.likesCount) - 4

    if (restCount > 0) {
      arr.push(restCount === 1 ? '1 other' : restCount + ' others')
    }

    return arr
  })()

  return (
    <>
      <div className={cn(styles.post, post.blocked && styles.postBlocked)}>
        <div className={styles.postHead}>
          <div className={styles.postUserDets}>
            <div
              className={styles.postUserAvatarNamePreviewWrp}
              onMouseOver={() => {
                if (auth.user?._id === post.user._id) return
                clearTimeout(userPreviewTimer.current)
                userPreviewTimer.current = setTimeout(() => setIsUserPreviewOpen(true), 300)
              }}
              onMouseOut={e => {
                if (e.currentTarget.contains(e.relatedTarget)) return
                clearTimeout(userPreviewTimer.current)
                userPreviewTimer.current = setTimeout(() => setIsUserPreviewOpen(false), 100)
              }}
            >
              <Link to={Routes.User(post.user._id)} className={styles.postUserAvatarNameWrp}>
                <span className={styles.postUserAvatarWrp}>
                  <img
                    src={post.user.image?.url || require('../../assets/avatar-sample.png')}
                    alt={post.user.firstName}
                    className={styles.postUserAvatar}
                  />
                  <span className={cn(styles.postUserOnlineStatus, isOnline(post.user._id) && styles.onlineStatusActive)} />
                </span>
                <span className={styles.postUserName}>{post.user.firstName}</span>
              </Link>

              {isUserPreviewOpen && (
                <div
                  className={styles.postUserPreviewWrp}
                  onMouseOver={() => {
                    clearTimeout(userPreviewTimer.current)
                    setIsUserPreviewOpen(true)
                  }}
                  onMouseOut={e => {
                    if (!e.currentTarget.contains(e.relatedTarget)) {
                      clearTimeout(userPreviewTimer.current)
                      userPreviewTimer.current = setTimeout(() => setIsUserPreviewOpen(false), 100)
                    }
                  }}
                >
                  <UserPreview post={post} />
                </div>
              )}
            </div>

            {!!post.user.isVerified && (
              <div className={styles.postUserVerifIconWrp}>
                <Icons.ShieldSolid className={styles.postUserVerifIcon} />
              </div>
            )}

            <div className={cn(compStyles.dot, styles.dot)} />
            <div className={styles.postTime}>{moment(post.createdAt).fromNow()}</div>
            <div className={cn(compStyles.dot, styles.dot)} />

            {post.blocked && (
              <div className={styles.postBlockedText}>
                <Icons.Ban /> Post is prohibited
              </div>
            )}
          </div>

          <div className={styles.postActionsWrp}>
            {auth.user?._id !== post.user._id && <FollowBtn userId={post.user._id} className={styles.followBtn} />}

            <div className={styles.postActionsDdWrp}>
              <OutsideClickHandler onOutsideClick={() => keepPostActsDdOpen || setIsPostActionsDdOpen(false)}>
                <button className={styles.postActionsDdTogBtn} onClick={() => setIsPostActionsDdOpen(open => !open)}>
                  <Icons.MenuVDots className={styles.postActionsDdTogBtnIcon} />
                </button>

                {isPostActionsDdOpen && (
                  <div className={styles.postActionsDd}>
                    <ul className={styles.postActionsDdList}>
                      {auth.user?._id === post.user._id ? (
                        <>
                          <li className={styles.postActionsDdListItem}>
                            <button
                              className={styles.postActionsDdListItemBtn}
                              onClick={() => setEditPostModalOpen(true)}
                              disabled={post.blocked}
                            >
                              <Icons.Edit2 /> Edit
                            </button>
                          </li>

                          <li className={styles.postActionsDdListItem}>
                            <DeleteBtn
                              post={post}
                              keepPostActsDdOpen={setKeepPostActsDdOpen}
                              className={styles.postActionsDdListItemBtn}
                            />
                          </li>
                        </>
                      ) : (
                        <>
                          <li className={styles.postActionsDdListItem}>
                            <ReportBtn
                              post={post}
                              keepPostActsDdOpen={setKeepPostActsDdOpen}
                              className={cn(styles.postActionsDdListItemBtn, styles.reportBtn)}
                            />
                          </li>
                        </>
                      )}
                    </ul>
                  </div>
                )}
              </OutsideClickHandler>
            </div>
          </div>
        </div>

        <div className={styles.postBody}>
          <div className={styles.postContent}>
            <ReadMore text={post.content} maxLength={100} className={styles.readmore} />
          </div>

          {post.media?.length > 0 && <Media post={post} />}
        </div>

        <div className={styles.postFoot}>
          {smdbGet(post._id, 'topLikes', post.topLikes)?.length > 0 && (
            <div className={styles.postTopLikesWrp}>
              <button className={styles.postTopLikesWrpBtn} onClick={() => setLikesModalOpen(true)}>
                <span className={styles.postTopLikeUserAvatarsWrp}>
                  {smdbGet(post._id, 'topLikes', post.topLikes).map(l => (
                    <span className={styles.postTopLikeUserAvatarWrp}>
                      <img
                        src={l.user.image?.url || require('../../assets/avatar-sample.png')}
                        alt={l.user.firstName}
                        className={styles.postTopLikeUserAvatar}
                      />
                    </span>
                  ))}
                </span>

                <span className={styles.postTopLikesUserNames}>
                  {topLikesNOthers.map((l, i) => (
                    <>
                      {i > 0 && i === topLikesNOthers.length - 1 && ' and '}
                      <b>{typeof l === 'string' ? l : l.user.firstName}</b>
                      {i < topLikesNOthers.length - 2 && ', '}
                    </>
                  ))}{' '}
                  liked this post
                </span>
              </button>
            </div>
          )}

          <div className={styles.postPrimActionsWrp}>
            <div className={styles.postPrimActionBtnWrp}>
              <LikeBtn
                postId={post._id}
                className={cn(styles.postPrimActionBtn, smdbGet(post._id, 'liked') && styles.__state_postLikeBtnActive)}
                disabled={post.blocked}
              />
            </div>

            <div className={styles.postPrimActionBtnWrp}>
              <button
                className={styles.postPrimActionBtn}
                onClick={() => smdbGet(post._id, 'commentsCount', post.commentsCount) && setCommentsModalOpen(true)}
              >
                <Icons.Comment /> {smdbGet(post._id, 'commentsCount', post.commentsCount)}
              </button>
            </div>

            <div className={styles.postPrimActionBtnWrp}>
              <button className={styles.postPrimActionBtn} disabled={post.blocked}>
                <Icons.Share />
              </button>
            </div>

            <div className={styles.postPrimActionBtnWrp}>
              <SaveBtn postId={post._id} className={styles.postPrimActionBtn} disabled={post.blocked} />
            </div>
          </div>

          {smdbGet(post, 'topComments', post.topComments).length > 0 && (
            <div className={styles.commentsWrp}>
              <Comments
                post={post}
                comments={smdbGet(post, 'topComments', post.topComments)}
                handleReply={post.blocked ? null : setParentComment}
                disabled={post.blocked}
              />
              {smdbGet(post, 'topComments', post.topComments)?.length < smdbGet(post, 'commentsCount', post.commentsCount) && (
                <SeeMoreCommentsBtn post={post} className={styles.seeMoreCommentsBtn} />
              )}
            </div>
          )}

          {post.blocked || (
            <div className={styles.addCommentWrp}>
              <AddComment postId={post._id} parentComment={parentComment} setParentComment={setParentComment} />
            </div>
          )}
        </div>
      </div>

      {likesModalOpen && <LikesModal postId={post._id} close={() => setLikesModalOpen(false)} />}
      {commentsModalOpen && <CommentsModal post={post} close={() => setCommentsModalOpen(false)} />}
      {editPostModalOpen && <CreatePostModal editMode data={post} close={() => setEditPostModalOpen(false)} />}
    </>
  )
}
