import { createContext, useCallback, useContext, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getOnlineUsers } from '../../../util/utilFunctions'
import { SET_ONLINE_USERS } from '../../../redux/actionTypes'

const OnlineCtx = createContext()

export const OnlineProvider = ({ children }) => {
  const dispatch = useDispatch()

  const onlineUsers = useSelector(state => state.chatReducer.onlineUsers)

  const getOnline = useCallback(userId => {
    getOnlineUsers([userId], users => dispatch({ type: SET_ONLINE_USERS, payload: users }))
  }, [])

  const isOnline = useCallback(userId => !!onlineUsers[userId], [onlineUsers])

  return <OnlineCtx.Provider value={{ getOnline, isOnline }}>{children}</OnlineCtx.Provider>
}

export const useOnline = () => useContext(OnlineCtx)
