import { useEffect, useState } from 'react'
import { useSMDb } from '../../contexts/smdb-ctx'
import SpinnerWithText from '../ui/spinner-with-text'
import Post from '../post'
import styles from './posts.module.scss'

export default function Posts({ items, loading }) {
  const { get: smdbGet } = useSMDb()

  const [finalPosts, setFinalPosts] = useState([])

  useEffect(() => {
    setFinalPosts(items.filter(i => !smdbGet(i._id, 'deleted')))
  }, [items, smdbGet])

  return (
    <div className={styles.postsWrp}>
      {finalPosts.map(data => (
        <>
          <Post key={data._id} post={data} />
          <div className={styles.postSep} />
        </>
      ))}

      {loading ? <SpinnerWithText /> : !finalPosts.length && <div style={{ textAlign: 'center' }}>No Posts Yet</div>}
    </div>
  )
}
