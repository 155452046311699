import { createContext, useCallback, useContext, useState } from 'react'

const MutedCtx = createContext()

export const MutedProvider = ({ children }) => {
  const [muted, setMuted] = useState(true)

  const mute = useCallback(() => setMuted(true), [])
  const unmute = useCallback(() => setMuted(false), [])
  const toggle = useCallback(() => setMuted(prev => !prev), [])

  return <MutedCtx.Provider value={{ muted, mute, unmute, toggle }}>{children}</MutedCtx.Provider>
}

export const useMuted = () => useContext(MutedCtx)
