import { useCallback, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { enqueueSnackbar } from 'notistack'
import { getPost as qGetPost } from '../lib/queries'
import { useSMDb } from '../contexts/smdb-ctx'
import Posts from '../components/common/posts'
import styles from './post.module.scss'

export default function Post() {
  const params = useParams()

  const { set: smdbSet } = useSMDb()

  const [loading, setLoading] = useState(false)
  const [post, setPost] = useState(null)

  const getPost = useCallback(async () => {
    setLoading(true)

    try {
      const res = await qGetPost(params.postId)
      setPost(res)
    } catch (err) {
      enqueueSnackbar(err)
    }

    setLoading(false)
  }, [params])

  useEffect(() => {
    setPost(null)
    getPost({})
  }, [getPost])

  useEffect(() => {
    if (post) {
      smdbSet(post._id, 'likesCount', post.likesCount)
      smdbSet(post._id, 'liked', post.liked)
      smdbSet(post._id, 'topLikes', post.topLikes)
      smdbSet(post._id, 'commentsCount', post.commentsCount)
      smdbSet(post._id, 'topComments', post.topComments)
      smdbSet(post._id, 'saved', post.saved)

      smdbSet(post.user._id, 'followersCount', post.user.followersCount)
      smdbSet(post.user._id, 'followingsCount', post.user.followingsCount)
      smdbSet(post.user._id, 'isFollowing', post.user.isFollowing)
      smdbSet(post.user._id, 'postsCount', post.user.postsCount)
    }
  }, [post, smdbSet])

  return (
    <div className={styles.postCont}>
      <div className={styles.postSec}>
        <div className={styles.postWrp}>
          <Posts items={[post].filter(Boolean)} loading={loading} />
        </div>
      </div>

      <div className={styles.rightSec}></div>
    </div>
  )
}
