import { useCallback, useEffect, useRef, useState } from 'react'
import { enqueueSnackbar } from 'notistack'
import { getPosts as qGetPosts } from '../lib/queries'
import { useSMDb } from '../contexts/smdb-ctx'
import Posts from '../components/common/posts'
import styles from './search.module.scss'

export default function SearchPage() {
  const { merge: smdbMerge } = useSMDb()

  const [searchVal, setSearchVal] = useState('')
  const [searchTerm, setSearchTerm] = useState('')
  const [isPostsLoading, setIsPostsLoading] = useState(false)
  const [posts, setPosts] = useState([])

  const searchTimer = useRef()
  const currentReqId = useRef()
  const paginationRef = useRef({})

  const getPosts = useCallback(
    async (reqId, pagination) => {
      setIsPostsLoading(true)

      try {
        const res = await qGetPosts(pagination, { search: searchTerm })

        if (reqId === currentReqId.current) {
          setPosts(res.items)
          paginationRef.current = { page: res.page, token: res.token }
        }
      } catch (err) {
        enqueueSnackbar(err)
      }

      setIsPostsLoading(false)
    },
    [searchTerm]
  )

  useEffect(() => {
    clearInterval(searchTimer.current)

    searchTimer.current = setTimeout(() => {
      setPosts([])
      setSearchTerm(searchVal)
    }, 300)
  }, [searchVal])

  useEffect(() => {
    if (searchTerm) {
      currentReqId.current = Date.now()
      getPosts(currentReqId.current, {})
    }
  }, [searchTerm, getPosts])

  useEffect(() => {
    for (const post of posts) {
      const { likesCount, liked, topLikes, commentsCount, topComments, saved } = post
      smdbMerge(post._id, { likesCount, liked, topLikes, commentsCount, topComments, saved })

      const { followersCount, followingsCount, isFollowing, postsCount } = post.user
      smdbMerge(post.user._id, { followersCount, followingsCount, isFollowing, postsCount })
    }
  }, [posts, smdbMerge])

  return (
    <div className={styles.searchPage}>
      <div className={styles.searchSec}>
        <div className={styles.searchInpWrp}>
          <input
            type="text"
            placeholder="Search posts, users..."
            className={styles.searchInp}
            value={searchVal}
            onChange={e => setSearchVal(e.target.value)}
          />
        </div>

        <div className={styles.postsWrp}>
          {searchTerm ? (
            <Posts items={posts} loading={isPostsLoading} />
          ) : (
            <div style={{ textAlign: 'center' }}>🔍 Search to get started</div>
          )}
        </div>
      </div>

      <div className={styles.rightSec}></div>
    </div>
  )
}
