import { useCallback, useEffect } from 'react'
import ReactDOM from 'react-dom'
import { useDispatch, useSelector } from 'react-redux'
import OutsideClickHandler from 'react-outside-click-handler'
import { v4 as uuid } from 'uuid'
import { socket } from '../../../../socket'
import { getId } from '../../../../util/utilFunctions'
import { socketEvent } from '../../../../util/enums/socketEnums'
import { chatType } from '../../../../util/chat'
import { chatToggle, createChatById } from '../../../../redux/actions/chatAction'
import { getIsGalleryOpen, getSelectUser } from '../../../../redux/selector/chat'
import AdamseaChat from '../../../chat/adamseaChat'
import { useBodyScroll } from '../../contexts/body-scroll'
import compStyles from '../../styles/components.module.scss'
import styles from './chat-modal.module.scss'

export default function ChatModal({ user, close }) {
  const { enable: enableBodyScroll, disable: disableBodyScroll } = useBodyScroll()

  const dispatch = useDispatch()

  const isGalleryOpen = useSelector(getIsGalleryOpen)
  const selectUser = useSelector(getSelectUser)

  const closeModal = useCallback(() => {
    if (!isGalleryOpen) {
      socket.emit(socketEvent.LeaveChannel, selectUser?.chatId)
      dispatch(chatToggle(false))
      close?.()
    }
  }, [isGalleryOpen, selectUser, close])

  useEffect(() => {
    dispatch(chatToggle(true))
  }, [])

  useEffect(() => {
    if (selectUser?.chatId) {
      socket.emit(socketEvent.LeaveChannel, selectUser?.chatId)
    }
  }, [selectUser])

  useEffect(() => {
    if (user) {
      dispatch(
        createChatById({
          members: [getId(user)],
          imageUrl: user.image?.url ?? '',
          description: '',
          channelUrl: uuid(),
          chatType: chatType.normalchat,
        })
      )
    }
  }, [user])

  useEffect(() => {
    disableBodyScroll()
    return enableBodyScroll
  }, [enableBodyScroll, disableBodyScroll])

  return ReactDOM.createPortal(
    <div className={compStyles.overlay}>
      <OutsideClickHandler display="contents" onOutsideClick={closeModal}>
        <AdamseaChat height={100} className={styles.chat} />
      </OutsideClickHandler>
    </div>,
    document.querySelector('.__as_sm')
  )
}
