import { useCallback, useEffect, useRef, useState } from 'react'
import ReactDOM from 'react-dom'
import { enqueueSnackbar } from 'notistack'
import cn from 'classnames'
import { getId } from '../../../../util/utilFunctions'
import IconClose from '../../assets/icons/close'
import { getUsersList as qGetUsersList, shareBlogWithUsers as qShareBlogWithUsers } from '../../lib/queries'
import compStyles from '../../styles/components.module.scss'
import styles from './as-share-modal.module.scss'
import { wishlistModuleEnum } from '../../../../util/enums/enums'

export default function ASShareModal({ blog, isOpen, close }) {
  const [searchTerm, setSearchTerm] = useState('')
  const [users, setUsers] = useState([])
  const [emailInpValue, setEmailInpValue] = useState('')
  const [addedEmails, setAddedEmails] = useState([])
  const [selectedUsers, setSelectedUsers] = useState([])
  const [sharing, setSharing] = useState(false)

  const searchInpTimer = useRef(null)

  const getUsersList = useCallback(
    async pagination => {
      try {
        const res = await qGetUsersList(pagination, { searchTerm })
        setUsers(res.items)
      } catch (err) {
        enqueueSnackbar(err)
      }
    },
    [searchTerm]
  )

  const addEmail = useCallback(() => {
    if (!emailInpValue || addedEmails.includes(emailInpValue)) return
    setAddedEmails(emails => [...emails, emailInpValue])
  }, [emailInpValue, addedEmails])

  const removeEmail = useCallback(email => {
    setAddedEmails(emails => {
      const emailsCopy = [...emails]
      emailsCopy.splice(emailsCopy.indexOf(email), 1)
      return emailsCopy
    })
  }, [])

  const toggleUser = useCallback(userId => {
    setSelectedUsers(users => {
      if (!users.includes(userId)) {
        return [...users, userId]
      } else {
        const usersCopy = [...users]
        usersCopy.splice(usersCopy.indexOf(userId), 1)
        return usersCopy
      }
    })
  }, [])

  const share = useCallback(async () => {
    setSharing(true)

    try {
      const res = await qShareBlogWithUsers({
        module: wishlistModuleEnum.ARTICLE,
        moduleId: blog._id,
        receiverIds: selectedUsers,
        manualIds: addedEmails,
      })
      enqueueSnackbar(res)
    } catch (err) {
      enqueueSnackbar(err)
    }

    setSharing(false)
  }, [blog, addedEmails, selectedUsers])

  useEffect(() => {
    if (isOpen) {
      getUsersList({})
    }
  }, [isOpen, getUsersList])

  useEffect(() => {
    clearTimeout(searchInpTimer.current)

    searchInpTimer.current = setTimeout(() => {
      setSearchTerm(emailInpValue)
    }, 300)
  }, [emailInpValue])

  if (!isOpen) return null

  return ReactDOM.createPortal(
    <div className={compStyles.overlay}>
      <div className={cn(compStyles.modal, styles.modal)}>
        <div className={compStyles.modalHead}>
          <div className={compStyles.modalTitle}>Share this article with your contacts</div>
          <button className={compStyles.modalCloseBtn} onClick={close}>
            <IconClose className={compStyles.modalCloseBtnIcon} />
          </button>
        </div>

        <div className={cn(compStyles.modalBody, styles.modalBody)}>
          <div className={styles.emailsSec}>
            <div className={styles.emailForm}>
              <input
                className={styles.emailInp}
                placeholder="Search user or enter an email"
                value={emailInpValue}
                onChange={e => setEmailInpValue(e.target.value)}
              />
              <button className={styles.emailAddBtn} onClick={addEmail}>
                Add Email
              </button>
            </div>

            {!!addedEmails.length && (
              <div className={styles.addedEmailsWrp}>
                {addedEmails.map(email => (
                  <div className={styles.addedEmail}>
                    {email}
                    <button className={styles.addedEmailRemoveBtn} onClick={() => removeEmail(email)}>
                      <IconClose />
                    </button>
                  </div>
                ))}
              </div>
            )}
          </div>

          <div className={styles.usersListSec}>
            {users.map(u => (
              <button
                key={getId(u)}
                className={cn(styles.user, selectedUsers.includes(getId(u)) && styles.userSelected)}
                onClick={() => toggleUser(getId(u))}
              >
                <div className={styles.userAvatarWrp}>
                  <img src={u.image?.url} alt={u.firstName} />
                </div>
                <div className={styles.userDetsWrp}>
                  <div className={styles.userName}>
                    {u.firstName} {u.lastName}
                  </div>
                  <div className={styles.userRole}>{u.role?.role}</div>
                  <div className={styles.userCompanyName}>
                    Working {u.companyName ? ` in ${u.companyName}` : `${u.profession?.alias}`}
                  </div>
                </div>
              </button>
            ))}
          </div>
        </div>

        <div className={cn(compStyles.modalFooter, styles.modalFooter)}>
          <button className={styles.shareBtn} onClick={share} disabled={sharing}>
            Share
          </button>
        </div>
      </div>
    </div>,
    document.querySelector('.__as_sm')
  )
}
