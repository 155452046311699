import { Route, Switch } from 'react-router-dom'
import { Routes } from '../lib/consts'
import CtxProvider from '../contexts/provider'
import Layout from '../components/layout'
import Feeds from './feeds'
import SearchPage from './search'
import SavedPosts from './saved-posts'
import Post from './post'
import Profile from './profile'

export default function SMRoutes() {
  return (
    <CtxProvider>
      <Layout>
        <Switch>
          <Route path={Routes.Home} exact component={Feeds} />
          <Route path={Routes.Search} exact component={SearchPage} />
          <Route path={Routes.Saved} exact component={SavedPosts} />
          <Route path={Routes.Post()} exact component={Post} />
          <Route path={Routes.User()} exact component={Profile} />
        </Switch>
      </Layout>
    </CtxProvider>
  )
}
