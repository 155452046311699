import React, { useEffect, useState } from 'react'
import { Tooltip } from 'react-tooltip'
import { uniqueId } from 'lodash'
import classNames from 'classnames'

export const CommonTooltip = ({ focus, content, component: Component, className = '', ...tooltipProps }) => {
  const [id] = useState(uniqueId('commonTooltip'))

  useEffect(() => {
    setTimeout(() => {
      document.getElementById(id)?.scrollIntoView({ block: 'center' })
    }, 200)
  }, [id, focus])

  return (
    <>
      <Component id={id} />
      <Tooltip {...tooltipProps} id={id} className={classNames('common-tooltip', className)} place="bottom">
        {content}
      </Tooltip>
    </>
  )
}
