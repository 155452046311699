import cn from 'classnames'
import compStyles from '../../styles/components.module.scss'

export default function SpinnerWithText({ children, spinnerProps, ...props }) {
  return (
    <div {...props} className={cn(compStyles.spinnerWrp, props.className)}>
      <span {...spinnerProps} className={cn(compStyles.spinner, spinnerProps?.className)} /> {children}
    </div>
  )
}
