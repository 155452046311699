import React, { useEffect, useState } from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import { Formik, Form, ErrorMessage } from 'formik'
import * as Yup from 'yup'
import HelpIcon from '@material-ui/icons/Help'

import { Field } from '../ws/Field'
import {
  requireMessage,
  getIds,
  renderSelectOptions,
  validationMessages,
  positiveNumberHelper,
  requiredWords,
  validateWords,
  validateMaxWords,
  requiredMaxWords,
  TitleCase,
} from '../../helpers/string'
import ErrorFocus from '../ws/ErrorFocus'
import {
  stateRequiredError,
  languageOptions,
  userRoles,
  AddressInputTypeEnum,
  companySectionAccessibleRole,
  comLicenceAccessibleRole,
  MEDIA_NAME,
  officeLocationAccessibleRole,
  helpTextEnum,
  paymentMethodAccessibleRoles,
} from '../../util/enums/enums'
import MultiSelect from '../helper/multiSelect'
import SingleSelect from '../helper/singleSelect'
import GoogleMap from '../../components/map/map'
import { getLatLngFromGeometric, checkRentUserType } from '../../helpers/jsxHelper'
import ConfirmMobileNumber from '../modal/confirmMobileNumber'
import { ButtonComponent } from '../form/Button'
import { isValidMobileNumber } from '../../helpers/validationHelper'
import { getAddMedia } from '../../helpers/s3FileUpload'
import { HelpIconComponent } from '../helper/helpIconComponent'
import { trimmedValue, maximumWordCount } from '../../helpers/string'
import { nameValidation, stringValidation, lengthValidation, alphabetsOnly, alphabetsNumberOnly } from '../../helpers/yupHelper'
import { toast } from 'react-toastify'
import ChangeEmailModal from './ChangeEmailModal'
import { useDispatch, useSelector } from 'react-redux'
import { getId } from '../../util/utilFunctions'
import { emailVerificationCheck, getUserById } from '../../redux/actions'
import { graphqlClient } from '../../helpers/graphqlClient'
import { gql } from 'apollo-boost'

const { MEMBER, RENT_AND_CHARTER, SURVEYOR } = userRoles

export const ProfileComponent = ({
  initValue,
  updateUser,
  cancelHandler,
  userType,
  countryCode,
  preferenceTypes,
  professionTypes,
  documentTypes,
  documentVerification,
  getDocumentUrl,
  paymentTypes,
  setVerifDialogOpen,
}) => {
  const dispatch = useDispatch()

  const currentUser = useSelector(state => state.loginReducer.currentUser)

  const [verifyMobile, setVerifyMobile] = useState(false)
  const handleVerifyMobile = () => setVerifyMobile(!verifyMobile)

  //Document Type validation (for licence)
  const [requiredGovtId, setRequiredGovtId] = useState(1)

  const [isCountryUS, setIsUSCountry] = useState(false)

  const {
    emailVerified,
    mobileVerified,
    governmentIdVerified,
    governmentIdRejected,
    commercialLicenceVerified,
    commercialLicenceRejected,
  } = documentVerification

  const [newGovId, setNewGovId] = useState(false)
  const [newComId, setNewComId] = useState(false)

  const [changeEmailOpen, setChangeEmailOpen] = useState(false)
  const [changeEmailLoading, setChangeEmailLoading] = useState(false)

  const [refreshVerificationLoading, setRefreshVerificationLoading] = useState(false)

  const refreshVerification = async () => {
    setRefreshVerificationLoading(true)

    try {
      const res = await graphqlClient.query({
        fetchPolicy: 'no-cache',
        query: gql`
          query refreshVerification {
            refreshVerification
          }
        `,
      })

      if (res.data.refreshVerification) {
        dispatch(getUserById(getId(currentUser)))
        toast.success('Email verified successfully')
      } else {
        setVerifDialogOpen()
      }
    } catch (err) {
      toast.error('Some error occured')
    }

    setRefreshVerificationLoading(false)
  }

  const renderContactInfo = (values, setFieldValue, aliasName) => {
    return (
      <Row className="adam-dash-profile">
        <Col xs={5} className="mb-2 adam-dash-pro-left">
          <Row>
            <Col xs={12} className="profile---picture--upload">
              <div className="field dashboard-profile-label d-flex flex-column align-items-center">
                <Field
                  label="Upload Profile Picture"
                  id="image"
                  name="image"
                  type="single-image"
                  deleteIds={values.removeMedia}
                  addMedia={values.addMedia}
                  mediaName={MEDIA_NAME.USERIMAGE}
                  value={values.image}
                  initValue={initValue.image}
                  onChangeText={setFieldValue}
                  cropBeforeUpload
                  required
                  maxImageSize={0}
                />
                <ErrorMessage component="div" name="image" className="error-message err-msg-image" />
              </div>
            </Col>
          </Row>
        </Col>
        <Col xs={7} className="mb-2 adam-dash-pro-right">
          <Row>
            <Col xs={12}>
              <label className="user--title">Contact information</label>
              <Row>
                {/* <Col xs={12}>
                  <div className="field dashboard-profile-label profile--radio--label">
                    <Field
                      label="Title"
                      id={'title'}
                      name={'title'}
                      type="radio"
                      value={values.title}
                      isCustomRadio
                      onChangeText={e => {
                        setFieldValue('title', e.target.value)
                      }}
                      options={[
                        { id: 'Mr', value: 'Mr' },
                        { id: 'Miss', value: 'Miss' },
                        { id: 'Mrs', value: 'Mrs' },
                      ]}
                      flex
                    />
                    <ErrorMessage component="div" name="title" className="error-message err-msg-title" />
                  </div>
                </Col> */}

                <Col xs={6}>
                  <div className="field dashboard-profile-label">
                    <Field
                      label="First Name"
                      id={'firstName'}
                      name={'firstName'}
                      type="text"
                      value={values.firstName}
                      onKeyDown={alphabetsOnly}
                      onChangeText={e => {
                        setFieldValue('firstName', e.target.value)
                      }}
                      onBlur={e => setFieldValue('firstName', trimmedValue(e.target.value))}
                      required
                    />
                    <ErrorMessage component="div" name="firstName" className="error-message err-msg-firstName" />
                  </div>
                </Col>
                <Col xs={6}>
                  <div className="field dashboard-profile-label">
                    <Field
                      label="Last Name"
                      id={'lastName'}
                      name={'lastName'}
                      value={values.lastName}
                      type="text"
                      onKeyDown={alphabetsOnly}
                      onChangeText={e => {
                        setFieldValue('lastName', e.target.value)
                      }}
                      onBlur={e => setFieldValue('lastName', trimmedValue(e.target.value))}
                      required
                    />
                    <ErrorMessage component="div" name="lastName" className="error-message err-msg-lastName" />
                  </div>
                </Col>

                <Col xs={6}>
                  <div className="field dashboard-profile-label profile--multi--select--options">
                    <label className="required" htmlFor="language">
                      {'Language(s) known'}
                    </label>
                    <MultiSelect
                      id={'language'}
                      name={'language'}
                      placeholder="Select Language"
                      selectedOption={values.language}
                      onChangeValue={item => setFieldValue('language', [...item])}
                      options={renderSelectOptions(languageOptions(), 'value', 'id')}
                      maxOptions={4}
                    />
                    <ErrorMessage component="div" name="language" className="error-message err-msg-language" />
                  </div>
                </Col>
                <Col xs={6}>
                  <div className="field dashboard-profile-label">
                    <SingleSelect
                      label="Profession"
                      name="profession"
                      placeholder="Select Profession"
                      selectedOption={values.profession}
                      options={renderSelectOptions(professionTypes, 'alias', 'id')}
                      onChange={value => setFieldValue('profession', value)}
                      required
                    />
                    <ErrorMessage component="div" name="profession" className="error-message err-msg-profession" />
                  </div>
                </Col>
                {aliasName && aliasName === RENT_AND_CHARTER && (
                  <Col xs={12}>
                    <div className="field dashboard-profile-label">
                      <Field
                        label="Account type (Individual/Organization)"
                        id={'provider'}
                        name={'provider'}
                        type="text"
                        value={values.provider}
                        onChangeText={e => {
                          setFieldValue('provider', e.target.value)
                        }}
                        disabled
                      />
                    </div>
                  </Col>
                )}
                <Col xs={12}>
                  <div className="field dashboard-profile-label">
                    <Field
                      label="Bio"
                      id={'shortDescription'}
                      name={'shortDescription'}
                      type="textarea"
                      rows={4}
                      value={values.shortDescription}
                      onChangeText={e => {
                        setFieldValue('shortDescription', e.target.value)
                      }}
                      onBlur={e => setFieldValue('shortDescription', trimmedValue(e.target.value))}
                      onKeyDown={maximumWordCount}
                      required
                    />
                    <div className="d-flex justify-content-between">
                      <div>
                        <ErrorMessage
                          component="div"
                          name="shortDescription"
                          className="error-message err-msg-shortDescription"
                        />
                      </div>
                      <div>{values.shortDescription ? String(values.shortDescription).length : 0} / 150</div>
                    </div>
                  </div>
                </Col>

                {aliasName && paymentMethodAccessibleRoles.includes(aliasName) && (
                  <Col xs={12}>
                    <div className="field dashboard-profile-label profile--multi--select--options">
                      <label className="required" htmlFor="paymentMethodTypes">
                        {'Please select Payment Types'}
                      </label>
                      <MultiSelect
                        id={'paymentMethodTypes'}
                        name={'paymentMethodTypes'}
                        selectedOption={values.paymentMethodTypes}
                        onChangeValue={item => setFieldValue('paymentMethodTypes', [...item])}
                        options={renderSelectOptions(paymentTypes, 'name', 'id')}
                      />
                      <ErrorMessage
                        component="div"
                        name="paymentMethodTypes"
                        className="error-message err-msg-paymentMethodTypes"
                      />
                    </div>
                  </Col>
                )}

                {/* {aliasName && aliasName === MEMBER && (
                  <Col xs={12}>
                    <div className="field dashboard-profile-label profile--multi--select--options">
                      <label className="required" htmlFor="preference">
                        {'How Would You Like Us To Contact You'}
                      </label>
                      <MultiSelect
                        id={'preference'}
                        name={'preference'}
                        selectedOption={values.preference}
                        onChangeValue={item => setFieldValue('preference', [...item])}
                        options={renderSelectOptions(preferenceTypes, 'alias', 'id')}
                      />
                      <ErrorMessage component="div" name="preference" className="error-message err-msg-preference" />
                    </div>
                  </Col>
                )} */}
              </Row>
            </Col>

            {renderAddress(values, setFieldValue)}
          </Row>
        </Col>
      </Row>
    )
  }

  const renderAddress = (values, setFieldValue) => {
    return (
      <Col xs={12} className="adam-dash-pro-add">
        <div className="address--information--border" />
        <label className="user--title address--information-title">
          Address <HelpIconComponent helpText={helpTextEnum.userProfile.addressHelpText} containerClass="single-select--help" />
        </label>
        <Row>
          <Col xs={6}>
            <div className="field dashboard-profile-label">
              <Field
                label="Country"
                id={'country'}
                name={'country'}
                type="text"
                value={values.country}
                onChangeText={e => {
                  setFieldValue('country', e.target.value)
                }}
                disabled
              />
            </div>
          </Col>
          <Col xs={6}>
            <div className="field dashboard-profile-label">
              <Field
                label="City or Town"
                id={'city'}
                name={'city'}
                type="autocomplete"
                value={values.city}
                placeholder={values.city}
                onChangeText={place => {
                  setFieldValue('geometricLocation', [place.longitude, place.latitude])
                  setFieldValue('city', place.city)
                  setFieldValue('state', place.state)
                  setFieldValue('zip', place.zip || '')
                  setFieldValue('country', place.country)
                }}
                restrictCountry={countryCode}
                required
              />
              <ErrorMessage component="div" name="city" className="error-message err-msg-city" />
            </div>
          </Col>

          <Col xs={6}>
            <div className="field dashboard-profile-label">
              <Field
                label="Street"
                id={'street'}
                name={'street'}
                type="text"
                value={values.street}
                onKeyDown={alphabetsNumberOnly}
                onChangeText={e => {
                  setFieldValue('street', e.target.value)
                }}
                onBlur={e => setFieldValue('street', trimmedValue(e.target.value))}
                required
              />
              <ErrorMessage component="div" name="street" className="error-message err-msg-street" />
            </div>
          </Col>
          <Col xs={6}>
            <div className="field dashboard-profile-label">
              <Field
                label="Address 1"
                id={'address1'}
                name={'address1'}
                type="text"
                value={values.address1}
                onKeyDown={alphabetsNumberOnly}
                onChangeText={e => {
                  setFieldValue('address1', e.target.value)
                }}
                onBlur={e => setFieldValue('address1', trimmedValue(e.target.value))}
                required
              />
              <ErrorMessage component="div" name="address1" className="error-message err-msg-address1" />
            </div>
          </Col>

          <Col xs={6}>
            <div className="field dashboard-profile-label">
              <Field
                label="State"
                id={'state'}
                name={'state'}
                type="text"
                value={values.state}
                onChangeText={e => {
                  setFieldValue('state', e.target.value)
                }}
                // required
                disabled
              />
              <ErrorMessage component="div" name="state" className="error-message err-msg-state" />
            </div>
          </Col>
          <Col xs={6}>
            <div className="field dashboard-profile-label">
              <Field
                label="Zip or Postal Code"
                id={'zip'}
                name={'zip'}
                type="text"
                value={values.zip}
                onChangeText={e => {
                  setFieldValue('zip', e.target.value)
                }}
                onBlur={e => setFieldValue('zip', trimmedValue(e.target.value))}
              />
              <ErrorMessage component="div" name="zip" className="error-message err-msg-zip" />
            </div>
          </Col>
        </Row>
      </Col>
    )
  }

  const renderCompanyInfo = (values, setFieldValue, aliasName) => {
    return (
      <Row className="adam-dash-com-info">
        <Col xs={12}>
          <div className="render--company--info"></div>
          <label className="user--title">Company information</label>
          <Row>
            <Col xs={5}>
              <Col xs={12}>
                <div className="field dashboard-profile-label company--logo-img">
                  <Field
                    label="Upload Company Logo"
                    id={'companyLogo'}
                    name={'companyLogo'}
                    type="single-image"
                    deleteIds={values.removeMedia}
                    addMedia={values.addMedia}
                    mediaName={MEDIA_NAME.USERCOMPANYLOGO}
                    value={values.companyLogo}
                    onChangeText={setFieldValue}
                    justifyLeft
                    // cropBeforeUpload
                    required
                    maxImageSize={0}
                  />
                  <ErrorMessage component="div" name="companyLogo" className="error-message err-msg-companyLogo" />
                </div>
              </Col>
            </Col>
            <Col xs={7}>
              <Row>
                <Col xs={6}>
                  <div className="field dashboard-profile-label form-label-margin">
                    <Field
                      label="Company name"
                      id={'companyName'}
                      name={'companyName'}
                      limit={20}
                      type="text"
                      value={values.companyName}
                      onChangeText={e => {
                        setFieldValue('companyName', e.target.value)
                      }}
                      onBlur={e => setFieldValue('companyName', trimmedValue(e.target.value))}
                      required
                    />
                    <ErrorMessage component="div" name="companyName" className="error-message err-msg-companyName" />
                  </div>
                </Col>
                <Col xs={6}>
                  <div className="field dashboard-profile-label">
                    <Field
                      label="Website"
                      id={'companyWebsite'}
                      name={'companyWebsite'}
                      type="url"
                      value={values.companyWebsite}
                      onChangeText={e => {
                        setFieldValue('companyWebsite', e.target.value)
                      }}
                      onBlur={e => setFieldValue('companyWebsite', trimmedValue(e.target.value))}
                      required
                    />
                    <ErrorMessage component="div" name="companyWebsite" className="error-message err-msg-companyWebsite" />
                  </div>
                </Col>
                {aliasName === userRoles.SURVEYOR && (
                  <Col xs={6}>
                    <div className="field dashboard-profile-label">
                      <Field
                        label="Price per ft. in USD"
                        helpText={helpTextEnum.priceInput}
                        id={'pricePerFt'}
                        name={'pricePerFt'}
                        type="number"
                        value={values.pricePerFt}
                        onChangeText={e => setFieldValue('pricePerFt', e.target.value && +e.target.value)}
                        required
                      />
                      <ErrorMessage component="div" name="pricePerFt" className="error-message err-msg-pricePerFt" />
                    </div>
                  </Col>
                )}
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
    )
  }

  const renderMapSection = (values, setFieldValue, errors, touched) => {
    let latLng

    if (values.officeAddress.length) {
      const [oldAddress] = values.officeAddress
      latLng = getLatLngFromGeometric(oldAddress.geometricLocation)
    }

    return (
      <Row className="adam-dash-map-sec">
        <Col xs={12}>
          <div className="render--company--info"></div>
          <label className="user--title required">Permanent office Location</label>
          <div className="surveyor--map">
            <div className="add-boat-map map-div-form h-100">
              <GoogleMap
                className="googleMap-position"
                latLng={latLng || {}}
                fetch={result => {
                  setFieldValue('officeAddress', [{ ...result, type: AddressInputTypeEnum.OFFICE_ADDRESS }])
                }}
                height={42}
                width={100}
                placeHolder="Office Location"
                columnName={'officeAddress'}
                isError={errors.officeAddress}
                isUpdate={values.hasOwnProperty('officeAddress') && values.officeAddress}
                isFullGrid
              />
            </div>
          </div>
        </Col>
      </Row>
    )
  }

  const renderVerificationSection = (values, setFieldValue, aliasName, errors = {}) => {
    return (
      <Row className="adam-dash-ver-acc">
        <Col xs={12}>
          <div className="render--company--info"></div>
          <div className="d-flex flex-column">
            <label className="user--title">Verify your account</label>
            <label className="user--title address--information-title color-black font-18 font-weight-light ml-20">
              <HelpIconComponent
                helpText={
                  'We prioritize your privacy by not disclosing sensitive details like ID, license, or complete address information. Our robust measures ensure a safe online experience. We protect our community by screening users, preventing scams, and maintaining a secure environment for everyone.'
                }
                containerClass="single-select--help"
              />
              <span className="ml-10">Privacy Protection</span>
            </label>
          </div>
          <div style={{ marginTop: 30, padding: '16px 24px', borderRadius: 8, background: '#ece5db' }}>
            <div className="d-flex">
              <div style={{ flexShrink: 0 }}>
                <img src={require('../../assets/images/bells_9083538.png')} alt="bell" height={28} />
              </div>
              <div style={{ margin: 0, marginLeft: 20, fontSize: 16 }}>
                {userType.aliasName !== userRoles.MEMBER ? (
                  <>
                    <p>
                      To ensure your listing is visible on AdamSea, account verification is mandatory. Please upload the
                      necessary documents for review. Once approved, your listing will be activated on our platform.
                    </p>
                    <p className="m-0">
                      Verification of your email address and phone number is essential for the visibility of your profile and
                      listings on AdamSea.
                    </p>
                  </>
                ) : (
                  <p>
                    IMPORTANT Verifying your email address and phone number is essential for ensuring your profile is visible on
                    AdamSea, allowing other users to easily find you when they search for your name
                  </p>
                )}
              </div>
            </div>
          </div>
          <Row style={{ marginTop: 80 }}>
            <Col xs={5} className="d-flex">
              <Col xs={6}>
                <div className="field dashboard-profile-label profile--radio--label government--id">
                  <Field
                    label="Identity"
                    id="governmentId"
                    name="governmentId"
                    type="single-document"
                    buttonText="Identity"
                    value={values.governmentId}
                    noVerifyRejected={newGovId}
                    verify={governmentIdVerified}
                    rejected={governmentIdRejected}
                    onChangeText={(...args) => {
                      setNewGovId(true)
                      setFieldValue(...args)
                    }}
                    deleteIds={values.removeMedia}
                    addMedia={values.addMedia}
                    mediaName={MEDIA_NAME.USERGOVERNMENTID}
                    getDocumentUrl={getDocumentUrl}
                    documentTypes={documentTypes}
                    errors={errors}
                    getDocumentType={type => {
                      const value = type ? 2 : 1
                      setRequiredGovtId(value)
                    }}
                    required={[userRoles.YACHT_SHIPPER].includes(aliasName)}
                    profileDocument
                    maxImageSize={0}
                  />
                </div>
              </Col>
              {aliasName && (comLicenceAccessibleRole.includes(aliasName) || checkRentUserType(aliasName, values.provider)) && (
                <Col xs={6}>
                  <div className="field dashboard-profile-label single--document-commercial-profile">
                    <Field
                      label={aliasName === userRoles.SURVEYOR ? 'Surveyor License' : 'Commercial license'}
                      id="commercialLicence"
                      name="commercialLicence"
                      type="single-document"
                      buttonText={aliasName === userRoles.SURVEYOR ? 'Surveyor License' : 'Commercial license'}
                      value={values.commercialLicence}
                      noVerifyRejected={newComId}
                      verify={commercialLicenceVerified}
                      rejected={commercialLicenceRejected}
                      onChangeText={(...args) => {
                        setNewComId(true)
                        setFieldValue(...args)
                      }}
                      deleteIds={values.removeMedia}
                      addMedia={values.addMedia}
                      mediaName={MEDIA_NAME.USERCOMMERCIALLICENCE}
                      getDocumentUrl={getDocumentUrl}
                      errors={errors}
                      required={
                        [
                          userRoles.BOAT_MANUFACTURER,
                          userRoles.BROKER_AND_DEALER,
                          userRoles.SURVEYOR,
                          userRoles.YACHT_SHIPPER,
                        ].includes(aliasName) ||
                        (aliasName === userRoles.RENT_AND_CHARTER ? values.provider === 'Organization' : false)
                      }
                      profileDocument
                      maxImageSize={0}
                      helpText={
                        aliasName === userRoles.SURVEYOR
                          ? 'In Canada and the USA, surveyors are required to attach a SAMS certificate or its equivalent.'
                          : null
                      }
                    />
                  </div>
                </Col>
              )}
            </Col>
            <Col xs={7}>
              <Row>
                <Col xs={6}>
                  <div className="field dashboard-profile-label mobile--number--profile mb-2 profile--mobile--no--section pr-3">
                    <Field
                      label="Mobile Number"
                      type="mobile-number"
                      labelContent={(
                        <ButtonComponent
                          onClick={handleVerifyMobile}
                          capitalize={false}
                          className="h-auto as--success--outline btn--padding btn--font mt-4 profile--change--mobile--btn rounded-full"
                        >
                          {mobileVerified ? 'Change' : 'Verify'}
                        </ButtonComponent>
                      )}
                      placeholder="Mobile Number"
                      id="mobileNumber"
                      name="mobileNumber"
                      value={mobileVerified ? initValue.mobileNumber : values.mobileNumber}
                      verify={mobileVerified}
                      onChangeText={value => setFieldValue('mobileNumber', value)}
                      required
                      helpText={
                        userType.aliasName === userRoles.RENT_AND_CHARTER &&
                        'To maintain a quicker connection with your customers, we will share your mobile number with guests who have booked one of your trips.'
                      }
                      disabled={mobileVerified}
                      noFormControl
                    />

                    <ErrorMessage component="div" name="mobileNumber" className="error-message err-msg-mobileNumber" />
                  </div>
                </Col>
                <Col xs={6}>
                  <div className="field profile-email dashboard-profile-label mb-2">
                    <Field
                      label="Email Address"
                      labelContent={(
                        <div style={{ marginLeft: 'auto' }}>
                          {currentUser?.documentVerification?.emailVerified && (
                            <ButtonComponent
                              capitalize={false}
                              style={{ marginTop: '5px' }}
                              className="h-auto as--success--outline btn--padding btn--font mt-4 profile--change--mobile--btn rounded-full"
                              onClick={() => setChangeEmailOpen(true)}
                              disabled={changeEmailLoading}
                            >
                              Change email
                            </ButtonComponent>
                          )}
                        </div>
                      )}
                      id={'email'}
                      name={'email'}
                      type="email"
                      value={values.email}
                      verify={emailVerified}
                      required
                      disabled
                    />
                    <ErrorMessage component="div" name="email" className="error-message err-msg-email" />
                  </div>

                  {!currentUser?.documentVerification?.emailVerified && (
                    <div style={{ clear: 'both' }} className="d-flex">
                      <div style={{ flexShrink: 0 }} className="mr-2">
                        <img src={require('../../assets/images/bells_9083538.png')} alt="bell" height={28} />
                      </div>
                      <p className="m-0">
                        Please verify your email using the link we sent you. If you didn't receive it, click
                      </p>
                    </div>
                  )}

                  {!currentUser?.documentVerification?.emailVerified && (
                    <div className="d-flex justify-content-center">
                      <ButtonComponent
                        capitalize={false}
                        style={{ marginTop: '5px' }}
                        className="h-auto as--success--outline btn--padding btn--font profile--change--mobile--btn rounded-full btn-email-resend"
                        onClick={refreshVerification}
                        disabled={refreshVerificationLoading}
                      >
                        Resend Email
                      </ButtonComponent>
                    </div>
                  )}

                  {changeEmailOpen && (
                    <ChangeEmailModal
                      open={changeEmailOpen}
                      close={() => setChangeEmailOpen(false)}
                      changeEmailLoading={changeEmailLoading}
                      setChangeEmailLoading={setChangeEmailLoading}
                    />
                  )}
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
    )
  }

  const { aliasName } = userType

  const roleWiseSchema = aliasName => {
    return aliasName && (companySectionAccessibleRole.includes(aliasName) || checkRentUserType(aliasName, initValue.provider))
      ? {
          companyName: stringValidation.required(requireMessage('Company name')),
          companyWebsite: Yup.string().required(requireMessage('Website')),
          companyLogo: Yup.string().required(requireMessage('Logo')),
        }
      : {}
  }

  const memberSchema = aliasName => {
    return aliasName && aliasName === MEMBER
      ? {
          // preference: Yup.array().min(1, 'Minimum 1 Preference is required.').ensure(),
        }
      : {}
  }

  const surveyorSchema = aliasName => {
    return aliasName && aliasName === SURVEYOR
      ? {
          pricePerFt: Yup.number()
            .required(requireMessage('Price Per Ft'))
            .positive(positiveNumberHelper('Price Per Ft'))
            .min(1, 'Price Per Ft should be larger than 0'),
        }
      : {}
  }

  const officeLocationSchema = aliasName => {
    return aliasName && aliasName && officeLocationAccessibleRole.includes(aliasName)
      ? {
          officeAddress: Yup.array(
            Yup.object({
              city: Yup.string(),
            })
          )
            .compact(v => !v.city || !v.state || !v.country)
            .required(requireMessage('Office location'))
            .min(1, requireMessage('Office location'))
            .ensure(),
        }
      : {}
  }

  const documentSchema = aliasName => {
    return {
      ...(aliasName && aliasName === userRoles.YACHT_SHIPPER
        ? { governmentId: Yup.array().min(requiredGovtId, requireMessage('ID')).ensure() }
        : {}),
      ...([
        userRoles.BOAT_MANUFACTURER,
        userRoles.BROKER_AND_DEALER,
        userRoles.SURVEYOR,
        userRoles.YACHT_SHIPPER,
        userRoles.RENT_AND_CHARTER,
      ].includes(aliasName)
        ? {
            commercialLicence: Yup.array().when('provider', {
              is: val => aliasName === userRoles.RENT_AND_CHARTER && val === 'Organization',
              then: Yup.array().min(1, requireMessage('Commercial license')).ensure(),
            }),
          }
        : {}),
    }
  }

  const paymentTypeSchema = aliasName => {
    return paymentMethodAccessibleRoles.includes(aliasName)
      ? {
          paymentMethodTypes: Yup.array().required(requireMessage('Payment Types')).ensure(),
        }
      : {}
  }

  return (
    <>
      <Formik
        initialValues={{
          ...initValue,
        }}
        validationSchema={Yup.object().shape({
          image: Yup.string().required(requireMessage('Profile Photo')),
          firstName: nameValidation('First Name').max(12, 'Cannot be longer than 12 characters'),
          lastName: nameValidation('Last Name').max(20, 'Cannot be longer than 20 characters'),
          language: Yup.array().required(requireMessage('Language')).max(4, 'Maximum 4 language allowed.').ensure(),
          profession: Yup.string().required(requireMessage('Profession')),
          shortDescription: stringValidation
            .required(requireMessage('Bio'))
            .ensure()
            .min(10, `Write bio of at least 10 characters`)
            .max(150, `Max 150 characters are allowed`)
            // .test('match', requiredWords('Bio', 10), val => validateWords(val, 10))
            // .test('match', requiredMaxWords(150), val => validateMaxWords(val, 150))
            .test(
              'match',
              `Bio can't contain email`,
              val =>
                !/(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))/i.test(
                  val
                )
            ),

          ...memberSchema(aliasName),

          address1: stringValidation
            .required(requireMessage('Address'))
            .ensure()
            .test('match', requiredMaxWords(10), val => validateMaxWords(val, 10)),
          street: stringValidation
            .required(requireMessage('Street'))
            .ensure()
            .test('match', requiredMaxWords(), val => validateMaxWords(val)),
          city: stringValidation.required(requireMessage('City or Town')),
          // state: stringValidation.required(stateRequiredError),

          ...officeLocationSchema(aliasName),

          ...surveyorSchema(aliasName),

          ...roleWiseSchema(aliasName),

          mobileNumber: Yup.string()
            .test('match', validationMessages.validMobileNumber, val => isValidMobileNumber(val))
            .required(requireMessage('Mobile Number'))
            .ensure(),
          email: Yup.string().required(requireMessage('Email')).email('Email is invalid'),

          ...documentSchema(aliasName),
          ...paymentTypeSchema(aliasName),
        })}
        onSubmit={values => {
          values.userType = aliasName
          values.firstName = TitleCase(values.firstName)
          values.lastName = TitleCase(values.lastName)
          const {
            provider,
            image,
            companyLogo,
            governmentId,
            commercialLicence,
            address1,
            address2,
            city,
            state,
            country,
            zip,
            street,
            geometricLocation,
            officeAddress,
            mobileNumber,
            ...updatedValues
          } = values

          const location = { type: 'Point', coordinates: values.geometricLocation }

          updatedValues.address = [
            {
              type: AddressInputTypeEnum.COMMON_ADDRESS,
              address1,
              address2,
              city,
              state,
              country,
              zip,
              street,
              geometricLocation: location,
            },
          ]

          if (officeAddress.length) {
            let [{ __typename, ...updatedOfficeAddress }] = officeAddress
            if (updatedOfficeAddress.__typename) delete updatedOfficeAddress.__typename
            if (updatedOfficeAddress.geometricLocation.__typename) delete updatedOfficeAddress.geometricLocation.__typename
            updatedValues.address.push({ ...updatedOfficeAddress, type: AddressInputTypeEnum.OFFICE_ADDRESS })
          }

          updateUser({
            ...updatedValues,
            mobileNumber: isValidMobileNumber(mobileNumber, false),
            profession: values.profession.value,
            language: getIds(values.language),
            preference: getIds(values.preference),
            addMedia: getAddMedia(values.addMedia),
            paymentMethodTypes: getIds(values.paymentMethodTypes),
            pricePerFt: values.pricePerFt || null,
          })
        }}
        render={({ values, errors, touched, setFieldValue, handleSubmit }) => (
          <Container className="user--profile--view adam-dash-pro-main" fluid>
            <ConfirmMobileNumber
              isCountryUS={isCountryUS}
              setIsUSCountry={setIsUSCountry}
              open={verifyMobile}
              close={handleVerifyMobile}
              value={mobileVerified ? initValue.mobileNumber : values.mobileNumber}
            />

            <Form>
              <div className="profile--title--user">Profile</div>

              {aliasName === userRoles.SURVEYOR && (
                <p className="surveyor--branch--update--status">
                  Create and update the branch profile from the same current surveyor profile account.
                </p>
              )}

              <ErrorFocus />
              {/* CONTACT & ADDRESS SECTION */}
              {renderContactInfo(values, setFieldValue, aliasName)}

              {/* COMPANY SECTION */}
              {aliasName &&
                (companySectionAccessibleRole.includes(aliasName) || checkRentUserType(aliasName, values.provider)) &&
                renderCompanyInfo(values, setFieldValue, aliasName)}

              {/* MAP SECTION */}
              {aliasName &&
                officeLocationAccessibleRole.includes(aliasName) &&
                renderMapSection(values, setFieldValue, errors, touched)}

              {/* VERIFICATION SECTION */}
              {renderVerificationSection(values, setFieldValue, aliasName, errors)}

              <div className="d-flex justify-content-center main--profile--btn mt-50 pt-2">
                <ButtonComponent
                  variant="outline"
                  color="profile"
                  className=" h-auto btn--padding btn--font mr-3 rounded-full profile-btn-lg"
                  onClick={handleSubmit}
                >
                  {'Save My Profile'}
                </ButtonComponent>

                <ButtonComponent
                  variant="outline"
                  color="cancel"
                  className=" h-auto btn--padding btn--font rounded-full profile-btn-lg"
                  onClick={cancelHandler}
                >
                  {' '}
                  {'Cancel'}
                </ButtonComponent>
              </div>
            </Form>
          </Container>
        )}
      />
    </>
  )
}
